import React, { useEffect } from 'react'
import { auth } from 'lib'
import { useHistory } from 'react-router'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

const Logout = (props) => {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      auth.logOut()
      history.push('/unauth/home')
    }, 2000)
  })

  return (
    <Container>
      <Row>
        <Col lg='12'>
          <h1 className="text-center">Logging You Out...</h1>
        </Col>
      </Row>
    </Container>
  )
}

export default Logout