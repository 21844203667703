import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { useHistory } from 'react-router'
import MultiLevelSelect from 'react-multi-level-selector';

const CreateGcpProject = (props) => {
  const history = useHistory()
  const initialFormData = {
    project_name: "",
    folder_id: "",
    cost_center_tag: "",
    owner_tag: "",
    project_name_tag: "",
  }
  const [folderOptions, setFolderOptions] = useState()
  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => {
    api.get('/gcp-folders')
      .then(resp => {
  
        let test = JSON.parse(JSON.stringify(resp.data.data))
        let tmp = []
        for (let i = 0; i < test.length; i += 1) {
          tmp.push({
            value: test[i].label,
            label: test[i].label,
            options: test[i].options
          })
        }
        setFolderOptions(tmp)
      })
      .catch(err => console.error("API Error: ", err))
  }, [])

  const formItems = [
    {
      name: "GCP Project Name",
      id: "project_name",
      type: "text",
      placeholder: "IGT-GCP-DEPARTMENT-ENVIRONMENT"
    },
    {
      name: "Environment -> Department",
      id: "folder_id",
      type: "select",
    },
    {
      name: "Cost Center Tag",
      id: "cost_center_tag",
      type: "text",
      placeholder: "default-cost-center - still havent tested wbs..."
    },
    {
      name: "Owner Tag",
      id: "owner_tag",
      type: "text",
      placeholder: "example-user"
    },
    {
      name: "Project Name Tag",
      id: "project_name_tag",
      type: "text",
      placeholder: "default-project-name"
    }
  ]

  const onChange = (e) => {
    let currentFormData = formData

    let new_value = e.target.value.replace(/\s/g, "-")
    currentFormData[e.target.id] = new_value
    setFormData(currentFormData)
  }

  const onFolderChange = (e) => {
    let currentFormData = formData
    if (!Array.isArray(e) || !e.length) {
      // array does not exist, is not an array, or is empty
      currentFormData['folder_id'] = ''
    } else {
      let temp_id = e[0].options[0].value

      currentFormData['folder_id'] = temp_id
    }
    setFormData(currentFormData)
  }

  const onSubmit = () => {
    console.log(formData)
    api.post("/gcp-project/create", formData)
      .then(resp => {
        alert("Your request has been submitted!")
        history.push('/auth/create-gcp-project')
      })
      .catch(err => console.error("API Error: ", err))
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <MultiLevelSelect
                        options={folderOptions}
                        onChange={onFolderChange}
                      />
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CreateGcpProject