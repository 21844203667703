import React from "react"
import {Sorter} from "lib"
export const columnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Active At",
    dataIndex: "active_at",
    sorter: Sorter.DATE,
    width: "8%",
    editable: true
  },
  {
    title: "Expired At",
    dataIndex: "expired_at",
    width: "8%",
    sorter: Sorter.DATE,
    editable: true
  },
  {
    title: "Created At",
    editable: false,
    dataIndex: "created_at",
    width: "6%",
  },
  {
    title: "Replacement Network",
    dataIndex: "new_network['id']",
    sorter: Sorter.TEXT,
    width: "6.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_network"]}</>
    },
    isSearchable: true
  },
  {
    title: "Cost Center",
    dataIndex: "cost_centers['id']",
    width: "7%",
    editable: true,
    render: (text, record) => {
      return <>{record["cost_center_identifier"]}</>
    }
  },
];

export const addColumnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
  },
  {
    title: "Active At",
    dataIndex: "active_at",
    width: "8%",
    editable: true
  },
  {
    title: "Expired At",
    dataIndex: "expired_at",
    width: "8%",
    editable: true
  },
  {
    title: "Created At",
    editable: false,
    dataIndex: "created_at",
    width: "6%",
  },
  {
    title: "Replacement Network",
    dataIndex: "new_network['id']",
    width: "6.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_network"]}</>
    }
  },
  {
    title: "Cost Center",
    dataIndex: "cost_centers['id']",
    width: "7%",
    editable: true,
    render: (text, record) => {
      return <>{record["cost_center_identifier"]}</>
    }
  },
];
