import React from 'react'
import { IconContext } from 'react-icons'
import { FaAws } from 'react-icons/fa'

const AwsIcon = () => {
    return (
      <IconContext.Provider value={{ color: "#FF9900", size: "3em" }}>
        <div>
          <FaAws />
        </div>
      </IconContext.Provider>
    )
}

export default AwsIcon