import React from 'react'
import {
  Input,
  FormGroup
} from 'reactstrap'

const CostCenterFilters = ({ onChangeFilter }) => {
  return (
    <tr>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Identifier'
            id='by_identifier'
            placeholder='Identifier'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
    
    </tr>
  )
}

export default CostCenterFilters