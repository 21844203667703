import React from 'react'
import {
  AwsIcon,
  AzureIcon,
  GcpIcon
} from 'Atoms'

const CloudCertificationsListItem = ({ modelListItem, index }) => {
  const { member_name, issue_date, expiration_date, name,business_unit, provider } = modelListItem
  const icon = () => {
    if (provider === "aws") {
      return (
        <AwsIcon />
      )
    } else if (provider === "azure") {
      return (
        <AzureIcon />
      )
    } else {
      return (
        <GcpIcon />
      )
    }
  }
  return (
    <tr key={index}>
      <td>{member_name}</td>
      <td>{name}</td>
      <td>{business_unit}</td>
      <td>{icon()}</td>
      <td>{issue_date}</td>
      <td>{expiration_date}</td>
    </tr>
  )
}

export default CloudCertificationsListItem