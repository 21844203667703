import React, {useState} from 'react'
import {
  Button, Col, FormGroup, Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink, Row,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from 'classnames';
import moment from "moment";
import { api } from 'lib'

const CloudBillModal = ({modal, toggleModal}) => {

  const now = new Date();
  const startDate = moment(new Date(now.getFullYear(), now.getMonth(), 1));
  const endDate = moment(startDate).add(1, "month").subtract(1, "seconds");
  const [activeTab, setActiveTab] = useState('1');
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const [formData, setFormData] = useState({
    identifier: "",
    start_date: startDate,
    end_date: endDate,
    contact: ""
  })

  const onChange = (e) => {
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onExportSingle = () => {
    var cloud_bill = {
      cloud_bill: {
        identifier: formData['identifier'],
        start_date: moment(startDate).format('yyyy-MM-DD'),
        end_date: moment(endDate).format('yyyy-MM-DD'),
        email: formData['contact']
      }
    }
    api.post("/cloud_bills/send_single", cloud_bill)
      .then(resp => {
        alert("Your request has been submitted!");
      })
      .catch(err => {
        alert("There was a problem submitting exporting this request. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const onExportAll = () => {
    var cloud_bill = {
      cloud_bill: {
        start_date: moment(startDate).format('yyyy-MM-DD'),
        end_date: moment(endDate).format('yyyy-MM-DD'),
      }
    }
    api.post("/cloud_bills/send_all", cloud_bill)
      .then(resp => {
        alert("Your request has been submitted!");
      })
      .catch(err => {
        alert("There was a problem submitting exporting this request. Please try again.")
        console.error("API Error: ", err)
      })
  }

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Confirm Data Export</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === '1'})}
              onClick={() => {
                toggleTab('1');
              }}
            >
              Export Single
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === '2'})}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Export All
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <br/><h4>Email Single Excel Report</h4>
                <FormGroup>
                  Current data in table below
                  <Label for="contact">Who would you like to email this report to?</Label>
                  <Input
                    type="contact"
                    name="contact"
                    id="contact"
                    placeholder={"Enter email"}
                    onChange={onChange}
                  />
                </FormGroup>
                <Button color="primary" onClick={onExportSingle}>Export</Button>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <br/><h4>Email All Cost Center Owners</h4>
                <FormGroup>
                  <p>All cost centers that have billing within the dates below will receive an email of their
                    report.</p><br/>
                </FormGroup>
                <Button color="primary" onClick={onExportAll}>Send Mass Mail</Button>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default CloudBillModal