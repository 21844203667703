import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'

const CompaniesCreateForm = ({ isLoading }) => {
  const history = useHistory()

  const initialFormData = {
    code: "",
    counntry: "",
    aws_gl: "",
    gcp_gl: "",
    azure_gl: "",
    gl_type: "",
    riOrReno: ""
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  const onChange = (e) => {
    let currentFormData = {...formData}
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  function getGlsInfoByCompanyCode(companyCode) {
    if (companyCode === "1000" || companyCode === "3100" || companyCode === "3500" || companyCode === "5200" || companyCode === "3800" || companyCode === "3750") {
      return {
        aws: "784031",
        gcp: "784033",
        azure: "784032",
        riOrReno: "Reno"
      }
    } else {
      return {
        aws: "520084",
        gcp: "520110",
        azure: "520109",
        riOrReno: "RI"
      }
    }
  }

  const onCompanyCodeChange = (e) => {
    const companyCode = e.target.value
    const gls = getGlsInfoByCompanyCode(companyCode)

    let newFormData = {...formData}
    newFormData["code"] = companyCode
    newFormData["aws_gl"] = gls["aws"]
    newFormData["gcp_gl"] = gls["gcp"]
    newFormData["azure_gl"] = gls["azure"]
    newFormData["riOrReno"] = gls["riOrReno"]
    setFormData(newFormData)
  }

  const onSubmit = () => {
    console.log(formData)

    api.post("/companies", formData)
      .then(resp => {
        alert("Your new company has been added!")
        console.log("Submitted")
        history.push('/auth/view/companies')
        window.location.reload();
      })
      .catch(err => {
        alert("There was a problem submitting this company. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Company Code",
      id: "code",
      type: "text",
      placeholder: "Enter a company code",
    },
    {
      name: "Location",
      id: "country",
      type: "text",
      placeholder: "Enter a location name",
    },
    {
      name: "AWS GL",
      id: "aws_gl",
      type: "text",
      placeholder: "Enter a AWS GL"
    },
    {
      name: "GCP GL",
      id: "gcp_gl",
      type: "text",
      placeholder: "Enter a GCP GL"
    },
    {
      name: "Azure GL",
      id: "azure_gl",
      type: "text",
      placeholder: "Enter a Azure GL"
    },
    {
      name: "GL Type",
      id: "gl_type",
      type: "text",
      placeholder: "Enter a GL Type (Cloud Services/Hardware/Other)"
    },
    {
      name: "Ri Or Reno",
      id: "riOrReno",
      type: "text",
      placeholder: "RI or Reno"
    }
  ]

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Add New company</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/companies">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                    if (obj.id === "code") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={formData[obj.id]}
                            onChange={onCompanyCodeChange}
                          />
                        </FormGroup>
                      )
                    }
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={formData[obj.id]}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, onSubmit)}
              </Form>
          }
        </Col>
      </Row>
    </Container>
  )
}

function renderModal(modal, toggle, formData, onSubmit) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm New Company</ModalHeader>
    <ModalBody>
      Company Code: {formData["code"]} <br />
      Location: {formData["country"]}  <br />
      AWS GL: {formData["aws_gl"]} <br />
      GCP GL: {formData["gcp_gl"]} <br />
      Azure GL: {formData["azure_gl"]} <br />
      GL Type: {formData["gl_type"]} <br/>
      RI or Reno: {formData["riOrReno"]} <br />

    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default CompaniesCreateForm