import React from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Button
} from 'reactstrap'
import { Filters, ListItem } from '.'
import { IgtLoader } from 'Atoms'

const MembersList = ({ modelList, filterDepartmentSelect, filterBusinessUnitSelect, onChangeFilter, isLoading }) => {
  console.log(modelList);
  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <h1 className="text-center">Members Table</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/create/dcoe_members">Add New Member</Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
            <IgtLoader isLoading={isLoading}/>
            :
            <Table hover>
              <thead className='sticky-header'>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Business Unit</th>
                  <th>Team</th>
                  <th>Teams Member</th>
                  <th>DL Member</th>
                  <th>Blacklist</th>

                </tr>
              </thead>
              <tbody>
                <Filters
                  filterDepartmentSelect={filterDepartmentSelect}
                  filterBusinessUnitSelect={filterBusinessUnitSelect}
                  onChangeFilter={onChangeFilter}
                />
                {
                  modelList && modelList.map((member, i) => {
                    return (
                      <ListItem
                        modelListItem={member}
                        key={i}
                        index={i}
                      />
                    )
                  })
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default MembersList