import React from "react"
import { Input, Button, Icon } from "antd";

export const getColumnSearchProps = (dataIndex, handleSearch, handleReset) => {
    let inputPlaceHolder = ""
    switch(dataIndex) {
      case "cloud_contact['id']":
          inputPlaceHolder = "owner";
          break;
      case "new_cost_center['id']":
          inputPlaceHolder = "replacement center";
          break;
      case "alt_cost_center['id']":
          inputPlaceHolder = "Back up CC";
          break;
      case "business_units['id']":
            inputPlaceHolder = "Business unit";
          break;
      case "cost_center_networks['id']":
            inputPlaceHolder = "CC Network";
          break;
      case "departments['id']":
            inputPlaceHolder = "Department";
          break;
      case "github_organization_app['id']":
            inputPlaceHolder = "Org name";
          break;
      default:
          inputPlaceHolder = dataIndex;
    }
    return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search ${inputPlaceHolder}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        filterIcon: (filtered) => (
          <Icon  type="search" style={{ fontSize: '2em', color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
            let result = null
            switch(dataIndex) {
                case "cloud_contact['id']":
                    result = record.cloud_contact_email;
                    break;
                case "new_cost_center['id']":
                    result = record.replacement_cost_center;
                    break;
                case "alt_cost_center['id']":
                    result = record.alternate_cost_center;
                    break;
                case "business_units['id']":
                    result = record.business_unit_name;
                    break
                case "cost_center_networks['id']":
                      result = record.cost_center_network_identifier;
                    break
                case "departments['id']":
                      result = record.department_name;
                    break
                case "github_organization_app['id']":
                  result = record.github_organization;
                    break;
                default:
                    result = record[dataIndex];
            }
            return result?.toString().toLowerCase().includes(value.toLowerCase());
        }
      }
}
