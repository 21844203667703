import React from 'react'
import {
  Button,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap'
import { ListItem } from '.'
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";


const CSVDataToDownloadFormat = (data) => {
  return data.map(company => {
    return {
      company_code: company.code,
      aws_gl: company.aws_gl,
      gcp_gl: company.gcp_gl,
      azure_gl: company.azure_gl,
      riOrReno: company.riOrReno,
      created_at: company.created_at
    }
  })
}

const CompaniesList = ({ modelList, isLoading }) => {

  return (
    <Container fluid>
      {console.log(modelList)}
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Companies Table</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/create/companies">Add New Company</Button>{' '}
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <>
              <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
              style={{color: "white"}}
                data={CSVDataToDownloadFormat(modelList)} 
                filename={'company_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
              <Table hover>
                <thead className='sticky-header'>
                  <tr>
                    <th>Company Code</th>
                    <th>Location</th>
                    <th>AWS GL</th>
                    <th>GCP GL</th>
                    <th>Azure GL</th>
                    <th>RI / Reno</th>
                    <th>GL Type</th>
                    <th>Created on</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  
                  {
                    modelList && modelList.sort((a,b) => a.code - b.code).map((company, i) => {
                      return (
                        <ListItem
                          modelListItem={company}
                          key={i}
                          index={i}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
              </>

          }
          
        </Col>
      </Row>
    </Container>
  )
}

export default CompaniesList