import React from 'react'

const CloudParentsListItem = ({ modelListItem, index }) => {
  const {terminated, cost_center, provider_type, identifier, name, email, cloud_contact_email, business_unit_name, department, environment} = modelListItem

  return (
    <tr key={index}>
      <td>{terminated ? "yes" : "no"}</td>
      <td>{provider_type}</td>
      <td>{identifier}</td>
      <td>{name}</td>
      <td>{email}</td>
      <td>{cloud_contact_email}</td>
      <td>{business_unit_name}</td>
      <td>{department}</td>
      <td>{environment}</td>
      <td>{cost_center}</td>
    </tr>
  )
}

export default CloudParentsListItem