import React from 'react'
import { IconContext } from 'react-icons'
import { FcGoogle } from 'react-icons/fc'

const GcpIcon = () => {
    return (
      <IconContext.Provider value={{ size: "3em" }}>
        <div>
          <FcGoogle />
        </div>
      </IconContext.Provider>
    )
}

export default GcpIcon