  // Note: we need these two methods because our library (react-hook-form) can only accept array of objects while our backend return array of strings.
  export const configDataToFormInterface = (
    configData
  ) => {
    return configData?.map(
      (configData) => {
        return {
          id: configData?.id,
          termsToIgnore: configData?.terms_to_ignore?.map((term) => {
            return {
              value: term,
            };
          }),
          printMessage: configData?.print_debug_message
        };
      }
    );
  };
  
  export const formInterfaceToConfigData = (
    configData
  ) => {
    return {
      id: configData.id,
      print_debug_message: configData.printMessage,
      terms_to_ignore:
      configData?.termsToIgnore?.map(
          (termToIgnore) => termToIgnore.value
        ) ?? []
    };
  };
  