import React, {useState} from 'react'
import AzurePre from './AzurePre'
import GooglePre from './GooglePre'
import {Container, FormGroup, Input, Row} from "reactstrap";

const PreTab = () => {
    const [filterData, setFilterData] = useState()

    const onChange = (e) => {
        setFilterData(e.target.value)
        console.log(e.target.value)
    }

    return (
        <Container fluid>
            <br/>
            <Row>
                <FormGroup>
                    <Input
                        name='Provider'
                        id='provider'
                        type="select"
                        onChange={onChange}
                    >
                        <option>--- Select an option ---</option>
                        <option value='gcp'>GCP</option>
                        <option value='azure'>Azure</option>
                        <option value='aws' disabled>AWS</option>
                    </Input>
                </FormGroup>
            </Row>
            <Row>
                {
                    filterData === 'azure' ?
                        <AzurePre/>
                        :
                        filterData === 'gcp' ?
                            <GooglePre/>
                            :
                            ""
                }
            </Row>
        </Container>
    )
}

export default PreTab