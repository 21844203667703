import React from "react"
import {Sorter} from "lib"
export const columnsData = [
  {
    title: "email",
    dataIndex: "email",
    width: "5%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "name",
    dataIndex: "name",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "team",
    dataIndex: "team",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    sorter: Sorter.TEXT,
    width: "5%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Department",
    dataIndex: "departments['id']",
    sorter: Sorter.TEXT,
    width: "6%",
    render: (text, record) => {
      return <>{record["department_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Blacklist",
    dataIndex: "blacklist",
    sorter: Sorter.TEXT,
    width: "6%",
    render: (text, record) => {
      return <>{record["blacklist"] ? "True" : "False"}</>
    },
    editable: true,
    isSearchable: true
  }
];

export const addColumnsData = [
  {
    title: "email",
    dataIndex: "email",
    width: "5%",
    editable: true,
  },
  {
    title: "name",
    dataIndex: "name",
    width: "3%",
    editable: true,
  },
  {
    title: "team",
    dataIndex: "team",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Blacklist",
    dataIndex: "blacklist",
    sorter: Sorter.TEXT,
    width: "6%",
    editable: true,
    render: (text, record) => {
      return <>{record["blacklist"] ? "True" : "False"}</>
    },
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    width: "5%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true
  },
  {
    title: "Department",
    dataIndex: "departments['id']",
    width: "6%",
    render: (text, record) => {
      return <>{record["department_name"]}</>
    },
    editable: true
  }
];
