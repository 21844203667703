import React from "react"
import {Sorter} from "lib"
export const columnsData = [
{
    title: "Member Name",
    dataIndex: "member['id']",
    width: "6%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
        return <>{record["member_name"]}</>
    },
    isSearchable: true
    },
  {
    title: "Certification Name",
    dataIndex: "name",
    width: "13%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_unit",
    width: "13%",
    editable: false,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Certified Cloud",
    dataIndex: "provider",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Issue Date",
    dataIndex: "issue_date",
    sorter: Sorter.DATE,
    width: "8%",
    editable: true
  },
  {
    title: "Expiration Date",
    dataIndex: "expiration_date",
    width: "8%",
    sorter: Sorter.DATE,
    editable: true
  }
];


export const addColumnsData = [
  {
      title: "Member Name",
      dataIndex: "member['id']",
      width: "6%",
      editable: true,
      render: (text, record) => {
          return <>{record["member_name"]}</>
      },
      },
    {
      title: "Certification Name",
      dataIndex: "name",
      width: "13%",
      editable: true
    },
    {
      title: "Certified Cloud",
      dataIndex: "provider",
      width: "7%",
      editable: true,
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      width: "8%",
      editable: true
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      width: "8%",
      editable: true
    }
  ];
  