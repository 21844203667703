import React from "react"
import { Button } from "antd";
import { EditableContext } from "../EditableFormRow";

export const getAddOperationColumn = (handleAdd, width) => {
  const primaryColor = "#1890ff"
  return {
    title: "operation",
    dataIndex: "operation",
    width,
    render: (text, record) => {
      return (
        <div>
             <EditableContext.Consumer>
                {form => (
                  <Button
                    onClick={() => handleAdd(form, record.id)}
                    style={{ marginRight: 8, color: primaryColor, background: "none", border: "none" }}
                  >
                    Add 
                  </Button>
                )}
              </EditableContext.Consumer>              
        </div>)
    }
  }
}