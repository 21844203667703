import React, { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Col,
    Container,
    Label,
    Input,
    Form,
    FormGroup,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import { api } from 'lib'
import moment from 'moment'
import { IgtLoader } from 'Atoms'
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import { Chart } from 'react-google-charts';

const CloudBillingList = ({ isLoading }) => {

    const now = new Date();
    const [startDate, setStartDate] = useState(moment(new Date(now.getFullYear(), now.getMonth(), 1)));
    const [endDate, setEndDate] = useState(moment(startDate).add(1, "month").subtract(1, "seconds"));
    const [options, setOptions] = useState([])

    //dict of all cost centers and emails
    //key = cost_center AND value = email
    const [contacts, setContacts] = useState([])

    const [datePlaceholder, setDatePlaceholder] = useState('Select a Date')
    const [chartData, setChartData] = useState(null)

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const initialFormData = {
        identifier: "",
        start_date: startDate,
        end_date: endDate,
        contact: ""
    }
    const [formData, setFormData] = useState(initialFormData)
    const ranges = {
        "Last month": [moment(startDate).subtract(1, "months"), moment(startDate)],
        "Last 3 months": [moment(startDate).subtract(2, "months"), moment(endDate)],
        "Last 6 months": [moment(startDate).subtract(5, "months"), moment(endDate)],
        "2020": [
            moment("2020-02-01 00:00:00"),
            moment("2021-01-31 23:59:59")
        ],
        "2019": [
            moment("2019-02-01 00:00:00"),
            moment("2020-01-31 23:59:59")
        ],
        "2018": [
            moment("2018-02-01 00:00:00"),
            moment("2019-01-31 23:59:59")
        ],
    };
    const formItems = [
        {
            name: "Identifier",
            id: "identifier",
            type: "select",
            placeholder: "Cost Center or WBS",
        },
        {
            name: "Date",
            id: "date",
            type: "date",
        },
    ]

    useEffect(() => {
        api.get('/cloud_bills')
            .then(resp => {
                const data = resp.data.data;
 
                if (data.length > 0) {
                    setupDropdownOptions(data, setFormData, setOptions);
                    setupChart(data, setChartData);
                }
            })
            .catch(err => console.error("API Error: ", err))
        api.get('/cost_centers')
            .then(resp => {
                let temp = resp.data
                let data = {}
                for (let i = 0; i < temp.length; i += 1) {
                    const key = temp[i].identifier;
                    const value = temp[i].cloud_contact_email;
                    data[key] = value
                }
                setContacts(data)
            })
            .catch(err => console.error("API Error: ", err))
    }, [])

    const onChange = (e) => {
        let currentFormData = formData
        currentFormData[e.target.id] = e.target.value
 
        setFormData(currentFormData)
    }

    const onSearch = () => {
        const identifier = formData['identifier'];
        const singleKey = '/cloud_bills?key=' + formData['identifier'] + "&startDate=" + moment(startDate).format('yyyy-MM-DD') + "&endDate=" + moment(endDate).format('yyyy-MM-DD');
        const allKeys = '/cloud_bills?startDate=' + moment(startDate).format('yyyy-MM-DD') + "&endDate=" + moment(endDate).format('yyyy-MM-DD');
        api.get(identifier === "all" ? allKeys : singleKey)
            .then(resp => {
                setupChart(resp.data.data, setChartData);
            })
            .catch(err => console.error("API Error: ", err))
    }

    const applyCallback = (startDate, endDate) => {
        setStartDate(startDate)
        setEndDate(endDate)
    }


    const rangeCallback = (index, value) => {
        setDatePlaceholder(value)
    }

    const onExportSingle = () => {
        var cloud_bill = {
            cloud_bill: {
                identifier: formData['identifier'],
                start_date: moment(startDate).format('yyyy-MM-DD'),
                end_date: moment(endDate).format('yyyy-MM-DD'),
                email: formData['contact']
            }
        }
        api.post("/cloud_bills/send_single", cloud_bill)
            .then(resp => {
                alert("Your request has been submitted!");
            })
            .catch(err => {
                alert("There was a problem submitting exporting this request. Please try again.")
                console.error("API Error: ", err)
            })
    }

    const onExportAll = () => {
        var cloud_bill = {
            cloud_bill: {
                start_date: moment(startDate).format('yyyy-MM-DD'),
                end_date: moment(endDate).format('yyyy-MM-DD'),
            }
        }
        api.post("/cloud_bills/send_all", cloud_bill)
            .then(resp => {
                alert("Your request has been submitted!");
            })
            .catch(err => {
                alert("There was a problem submitting exporting this request. Please try again.")
                console.error("API Error: ", err)
            })
    }

    return (
        <Container fluid>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    <h1 className="text-center">Cloud Billing</h1>
                </Col>
                <Col lg="3">
                    <Button href="/auth/create/cloud_bills">Upload</Button>
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs="3"><br />
                    {
                        isLoading ?
                            <IgtLoader isLoading={isLoading} />
                            :
                            <Form>
                                {
                                    formItems && formItems.map((obj, i) => {
                                        if (obj.type === "text") {
                                            return (
                                                <FormGroup key={i}>
                                                    <Label for={obj.id}>{obj.name}</Label>
                                                    <Input
                                                        type={obj.id}
                                                        name={obj.name}
                                                        id={obj.id}
                                                        placeholder={obj.placeholder}
                                                        onChange={onChange}
                                                    />
                                                </FormGroup>
                                            )
                                        } else if (obj.type === "select") {
                                            return (
                                                <FormGroup key={i}>
                                                    <Label for={obj.id}>{obj.name}</Label>
                                                    <Input
                                                        name={obj.name}
                                                        id={obj.id}
                                                        type={obj.type}
                                                        onChange={onChange}
                                                    >
                                                        {
                                                            options && options.map((obj, i) => {
                                                                return (
                                                                    <option value={obj.id} key={i}>{obj}</option>
                                                                )
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                            )
                                        } else if (obj.type === "date") {
                                            return (

                                                <FormGroup key={i}>
                                                    <Label for={obj.id}>{obj.name}</Label>
                                                    <DateTimeRangeContainer
                                                        ranges={ranges}
                                                        start={moment(startDate)}
                                                        end={moment(endDate)}
                                                        local={{
                                                            "format": "DD-MMM-YYYY",
                                                            "sundayFirst": false
                                                        }}
                                                        applyCallback={applyCallback}
                                                        rangeCallback={rangeCallback}
                                                        maxDate={moment(endDate).add(24, "hour")}
                                                    // rangeCallback={this.rangeCallback}
                                                    >

                                                        <Input
                                                            id="formControlsTextB"
                                                            type="text"
                                                            label="Text"
                                                            placeholder={datePlaceholder}
                                                        />
                                                    </DateTimeRangeContainer>
                                                </FormGroup>
                                            )
                                        } else {
                                            return (null)
                                        }
                                    })
                                }

                                <p>Note: The 'Date' is listed as charges for the previous month. If the date shows March, these charges were accumulated in February. </p>
                                <br />
                                <Row>
                                    <Col sm={{ size: 'auto' }}>
                                        <Button color="primary" onClick={onSearch}>Search</Button>
                                    </Col>
                                    <Col sm={{ size: 'auto', offset: 1 }}>
                                        {renderModal(modal, toggleModal, onExportSingle, onChange, formData, startDate, endDate, activeTab, setActiveTab, toggleTab, contacts, onExportAll)}
                                        <Button color="danger" onClick={toggleModal}>Export Data</Button>
                                    </Col>
                                </Row>
                            </Form>
                    }
                </Col>
                <Col xs="auto">
                    <Chart
                        width={'1000px'}
                        height={'600px'}
                        chartType="Bar"
                        loader={<IgtLoader isLoading='true' />}
                        data={chartData}
                        options={{
                            title: 'Spend per Cost Center',
                            vAxis: {
                                minValue: 0,
                                format: 'currency'
                            }
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                </Col>
            </Row><br />
            <Row>
                <Col xs="3"><br />
                </Col>
                <Col xs="auto">
                    <Alert color="info">
                        This is an alternative view — check it out!
                    </Alert>
                    <Chart
                        chartType="Table"
                        loader={<IgtLoader isLoading='true' />}
                        data={chartData}
                        options={{
                            title: 'Spend per Cost Center',
                            alternatingRowStyle: true,
                            cssClassNames: {
                                headerRow: 'bigAndBoldClass',
                                hoverTableRow: 'highlightClass'
                            },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                </Col>
            </Row>
            <br /><br /><br /><br />
        </Container>
    )

}

function renderModal(modal, toggleModal, onExport, onChange, formData, startDate, endDate, activeTab, setActiveTab, toggleTab, contacts, onExportAll) {
    var cloud_bill = {
        cloud_bill: {
            identifier: formData['identifier'],
            start_date: moment(startDate).format('yyyy-MM-DD'),
            end_date: moment(endDate).format('yyyy-MM-DD'),
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Confirm Data Export</ModalHeader>
            <ModalBody>
                {renderTabs()}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )

    function renderTabs() {
        return <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggleTab('1'); }}
                    >
                        Export Single
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggleTab('2'); }}
                    >
                        Export All
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <br /><h4>Email Single Excel Report</h4>
                            <FormGroup>
                                Identifier: {cloud_bill.cloud_bill["identifier"]} <br />
                                Start: {cloud_bill.cloud_bill["start_date"]} <br />
                                End: {cloud_bill.cloud_bill["end_date"]} <br /><br />
                                <Label for="contact">Who would you like to email this report to?</Label>
                                <Input
                                    type="contact"
                                    name="contact"
                                    id="contact"
                                    placeholder={contacts[formData['identifier']]}
                                    onChange={onChange}
                                />
                            </FormGroup>
                            <Button color="primary" onClick={onExport}>Export</Button>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col sm="12">
                            <br /><h4>Email All Cost Center Owners</h4>
                            <FormGroup>
                                <p>All cost centers that have billing within the dates below will receive an email of their report.</p><br />
                                Start: {cloud_bill.cloud_bill["start_date"]} <br />
                                End: {cloud_bill.cloud_bill["end_date"]} <br /><br />
                            </FormGroup>
                            <Button color="primary" onClick={onExportAll}>Send Mass Mail</Button>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>;
    }
}

function setupChart(data, setChartData) {
    const uniqueCostCenters = [...new Set(data.map(temp => temp.identifier))];
    const uniqueDates = [...new Set(data.map(temp => temp.date))];

    const col = ['Date'].concat(uniqueCostCenters);
    let chartInfo = [col];

    for (let i = 0; i < uniqueDates.length; i += 1) {
        let row = Array(uniqueCostCenters.length + 1).fill(0)
        row[0] = uniqueDates[i]

        chartInfo.push(row)
    }
 
    for (let i = 0; i < data.length; i += 1) {
        const att = data[i];
        const date = att.date;
        const name = att.identifier;

        // each row has an index for every column
        const uniqueCC = uniqueCostCenters.indexOf(name)
        const uniqueDate = uniqueDates.indexOf(date)

        const amount = att.amount;
 
        chartInfo[uniqueDate + 1][uniqueCC + 1] += Number(amount)
    }

    setChartData(chartInfo);
}

function setupDropdownOptions(data, setFormData, setOptions) {
    const unique = Array.from(new Set(data.map(temp => temp.identifier).sort()))
    unique.unshift("all")
    const today = new Date();
 
    let currentFormData = {
        identifier: unique[0],
        start_date: new Date(today.getFullYear(), today.getMonth() - 1, 1),
        end_date: today,
    };
    setFormData(currentFormData);
    setOptions(unique);
}

export default CloudBillingList