import React from "react"
import {  Popconfirm, Button } from "antd";
import { EditableContext } from "../EditableFormRow";

export const getOperationColumn = (editingId, isEditing, save, cancel, edit, handleDelete, width, hideEdit) => {
  const primaryColor = "#1890ff"
  return {
    title: "operation",
    dataIndex: "operation",
    width,
    render: (text, record) => {
      const editable = isEditing(record);
      return (
        <div>
          {editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button
                    onClick={() => save(form, record.id)}
                    style={{ marginRight: 8, color: primaryColor, background: "none", border: "none" }}
                  >
                    Save
                  </Button>
                )}
              </EditableContext.Consumer>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => cancel(record.id)}
              >
                <Button style={{color: primaryColor, background: "none", border: "none" }}>Cancel</Button>
              </Popconfirm>
            </span>
          ) : (
            <>
            {hideEdit ? "" :  <Button disabled={editingId !== ""} style={{color: primaryColor, background: "none", border: "none" }} onClick={() => edit(record.id)}>Edit</Button>}
       
              <div />
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.id)}
              >

                <Button  style={{ color: "red", background: "none", border: "none" }}>Delete</Button>
              </Popconfirm>
            </>
          )}
        </div>
      );
    }
  }
}
