import {Sorter} from "lib"
export const columnsData = [
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
      editable: true,
      sorter: Sorter.TEXT,
      isSearchable: true
    },
    {
      title: "Abbreviation",
      dataIndex: "abbreviation",
      width: "7%",
      editable: true,
      sorter: Sorter.TEXT,
      isSearchable: true
    }
  ];


export const addColumnsData = [
  {
    title: "Name",
    dataIndex: "name",
    width: "10%",
    editable: true,
  },
  {
    title: "Abbreviation",
    dataIndex: "abbreviation",
    width: "7%",
    editable: true
  }
];