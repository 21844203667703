import { useState, useEffect } from 'react'
import { storage, auth } from 'lib'

const useIsLoggedIn = () => {
  const currentIsLoggedIn = storage.get('isLoggedIn')
  const [isLoggedIn, setIsLoggedIn] = useState(currentIsLoggedIn)

  useEffect(() => {
    auth.isLoggedIn()
    .then(resp => {
      setIsLoggedIn(resp)
    })
    .catch(err => console.error("Auth Error: ", err))
  },[])

  storage.on('isLoggedIn_changed', (newValue) => {
    setIsLoggedIn(newValue)
  })

  return isLoggedIn
}

export default useIsLoggedIn