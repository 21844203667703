import React from 'react'
import { IconContext } from 'react-icons'
import { FaUsers } from 'react-icons/fa'

const MembersIcon = () => {
  return (
    <IconContext.Provider value={{ color: "white", size: "5em" }}>
      <div>
        <FaUsers />
      </div>
    </IconContext.Provider>
  )
}

export default MembersIcon
