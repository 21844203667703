import React from 'react'

export const CloudParentItem = ({ data, index }) => {
  if (data === undefined || data.length === 0) {
    return null
  }
  return (
    <details>
      <summary>Cloud Access</summary>
      {
        data && data.map((obj, j) => {
          return (
            <li key={j}>
              {obj.name} ({obj.id})
            </li>
          )
        })
      }

    </details>
  )
}

export default CloudParentItem
