import React from "react"
import {Sorter} from "lib"
export const columnsData = [
{
    title: "Date",
    dataIndex: "date",
    sorter: Sorter.DATE,
    width: "8%",
    editable: true
},
{
    title: "Provider",
    dataIndex: "provider",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Company Code",
    dataIndex: "company_code",
    width: "7%",
    editable: true,
    sorter: Sorter.NUMBER,
    isSearchable: true
  },
  {
    title: "Currency",
    dataIndex: "currency",
    width: "5%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Reference",
    dataIndex: "reference",
    width: "7%",
    editable: true,
    sorter: Sorter.NUMBER,
    isSearchable: true
  },
  {
    title: "GL Account",
    dataIndex: "account",
    width: "7%",
    editable: true,
    sorter: Sorter.NUMBER,
    isSearchable: true
  },
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: "7%",
    editable: true,
    sorter: Sorter.NUMBER,
    isSearchable: false
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    sorter: Sorter.TEXT,
    width: "8%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "CC Network",
    dataIndex: "cost_center_networks['id']",
    sorter: Sorter.TEXT,
    width: "8%",
    render: (text, record) => {
      return <>{record["cost_center_network_identifier"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Activity",
    dataIndex: "activity",
    width: "6%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Line Item Txt",
    dataIndex: "line_item",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
];


export const addColumnsData = [
  {
      title: "Date",
      dataIndex: "date",
      width: "8%",
      editable: true
  },
  {
      title: "Provider",
      dataIndex: "provider",
      width: "7%",
      editable: true,
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      width: "7%",
      editable: true,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      width: "5%",
      editable: true,
    },
    {
      title: "Reference",
      dataIndex: "reference",
      width: "7%",
      editable: true,
    },
    {
      title: "GL Account",
      dataIndex: "account",
      width: "7%",
      editable: true,
    },
    {
      title: "Identifier",
      dataIndex: "identifier",
      width: "7%",
      editable: true,
    },
    {
      title: "Amount",
    dataIndex: "amount",
      width: "7%",
      editable: true,
    },
    {
      title: "Business Unit",
      dataIndex: "business_units['id']",
      editable: true,
      width: "8%",
      render: (text, record) => {
        return <>{record["business_unit_name"]}</>
      },
    },
    {
      title: "CC Network",
      dataIndex: "cost_center_networks['id']",
      sorter: Sorter.TEXT,
      width: "8%",
      render: (text, record) => {
        return <>{record["cost_center_network_identifier"]}</>
      },
      editable: true,
      isSearchable: true
    },
    {
      title: "Activity",
      dataIndex: "activity",
      width: "6%",
      editable: true,
    },
    {
      title: "Line Item Txt",
      dataIndex: "line_item",
      width: "7%",
      editable: true,
    },
  ];