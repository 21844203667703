import React from 'react'

const CloudSurveysListItem = ({ modelListItem, index, cloudParentItem }) => {

  const {      
    provider,
    project_name,
    identifier,
    distribution_list,
    account_owner,
    business_unit,
    department,
    cloud_environment,
    cost_center,
    still_in_use,
    can_be_removed,
    billing_contact,
    security_contact,
    operations_contact,
    project_summary, 
  } = modelListItem

  return (
    <tr key={index}>
      <td>{provider}</td>
      <td>{identifier}</td>
      <td style={{ backgroundColor: (project_name === cloudParentItem.name ? '' : '#ffcccb') }}>{project_name}</td>
      <td style={{ backgroundColor: (distribution_list === cloudParentItem.email ? '' : '#ffcccb') }}>{distribution_list}</td>
      <td style={{ backgroundColor: (account_owner === cloudParentItem.cloud_contact_email ? '' : '#ffcccb') }}>{account_owner}</td>
      <td>{business_unit}</td>
      <td style={{ backgroundColor: (department === cloudParentItem.department ? '' : '#ffcccb') }}>{department}</td>
      <td style={{ backgroundColor: (cloud_environment === cloudParentItem.environment ? '' : '#ffcccb') }}>{cloud_environment}</td>
      <td style={{ backgroundColor: (cost_center === cloudParentItem.cost_center ? '' : '#ffcccb') }}>{cost_center}</td>
      <td style={{ backgroundColor: (still_in_use === 'Yes' ? '' : '#ffcccb') }}>{still_in_use}</td>
      <td style={{ backgroundColor: (can_be_removed === 'No' ? '' : '#ffcccb') }}>{can_be_removed}</td>
      <td style={{ backgroundColor: (billing_contact === cloudParentItem.billing_contact ? '' : '#ffcccb') }}>{billing_contact}</td>
      <td style={{ backgroundColor: (security_contact === cloudParentItem.security_contact ? '' : '#ffcccb') }}>{security_contact}</td>
      <td style={{ backgroundColor: (operations_contact === cloudParentItem.operations_contact ? '' : '#ffcccb') }}>{operations_contact}</td>
      <td>{project_summary}</td>
    </tr>
  )
}

export default CloudSurveysListItem