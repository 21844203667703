import React from "react"
import {Sorter} from "lib"

// Note(Dennis): Set the editable to true as
// Because sort feature doesn't work if editable set to false
export const columnsData = [
    {
      title: "Status",
      dataIndex: "status",
      width: "6%",
      editable: true,
      sorter: Sorter.TEXT,
      isSearchable: true
  },
    {
        title: "Approval Id",
        dataIndex: "approval_id",
        width: "10%",
        editable: true,
        sorter: Sorter.TEXT,
        isSearchable: true
    },
    {
      title: "Approval Type",
      dataIndex: "approval_type",
      width: "6%",
      editable: false,
      isSearchable: true
  },
    {
      title: "Title",
      dataIndex: "title",
      width: "7%",
      editable: true,
      sorter: Sorter.TEXT,
      isSearchable: true
  },
  {
    title: "Assigned To",
    dataIndex: "assigned_to",
    width: "10%",
    editable: false,
    isSearchable: true,
    render: (text, record) => {
      console.log()
      return <>{Array.isArray(record["assigned_to"]) ? record["assigned_to"].join(" ; ") : ""}</>
    }
  },
  {
    title: "Requested By",
    dataIndex: "requested_by",
    width: "7%",
    isSearchable: true
  },
  {
    title: "Created on",
    sorter: Sorter.DATE,
    editable: true,
    dataIndex: "created_at",
    width: "6%",
  },
  {
    title: "Updated at",
    sorter: Sorter.DATE,
    editable: true,
    dataIndex: "updated_at",
    width: "6%",
  }
];