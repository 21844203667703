import React from "react"
import {Sorter} from "lib"

// Note(Dennis): Set the editable to true as
// Because sort feature doesn't work if editable set to false
export const columnsData = [
    {
        title: "Resource Id",
        dataIndex: "resource_id",
        width: "10%",
        editable: true,
        sorter: Sorter.TEXT,
        isSearchable: true
    },
    {
      title: "State",
      dataIndex: "state",
      width: "7%",
      editable: true,
      sorter: Sorter.TEXT,
      isSearchable: true
  },
{
    title: "Cloud",
    dataIndex: "provider_type",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
    },
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Name",
    dataIndex: "name",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Location",
    dataIndex: "location",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Resource type",
    dataIndex: "resource_type",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_units_id",
    sorter: Sorter.TEXT,
    width: "8%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Cost Center",
    dataIndex: "default_cost_center_id",
    sorter: Sorter.TEXT,
    width: "8%",
    render: (text, record) => {
      return <>{record["cost_center"]}</>
    },
    editable: true,
    isSearchable: true
  }
];