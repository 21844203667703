import React, { useEffect, useState, useCallback } from 'react'
import { api } from 'lib'
import Presenter from "./presenter";
import { useModelList } from 'CustomHooks'
import _ from "lodash";


const MissingReportTags = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [modelList] = useModelList('missing_report_tags', setIsLoading)
  const [missingReportTags, setMissingReportTags] = useState([])
 
  useEffect(() => {
    setMissingReportTags(modelList)
  }, [modelList])

  // API call logics 
  const updateConfigToDB = useCallback((
    id,
    prevPrintMessage,
    configData
  ) => {
    if (
      isLoading ||
      !modelList ||
      !prevPrintMessage ||
      !configData ||
      !id
    ) {
      return null;
    }

    const configToUpdateIdx =
      missingReportTags.findIndex(
        (missingReportTag) => missingReportTag.print_debug_message === prevPrintMessage
      );

    let currentMissingReportTags = missingReportTags;
    currentMissingReportTags[configToUpdateIdx] = configData;

    setMissingReportTags((prevTerms) => [...currentMissingReportTags])

    try {
      api.put("/missing_report_tags/" + id, {
                            terms_to_ignore: JSON.stringify(configData.terms_to_ignore), 
                            print_debug_message: configData.print_debug_message
                          })
      alert(`Successfully updated: ${prevPrintMessage}!`);
    } catch (err) {
      alert("There was a problem updating this config record. Please try again.")
      console.error("API Error: ", err)
    }
  }, [missingReportTags, isLoading, modelList]);

  const createConfigToDB = useCallback((
    newConfigData
  ) => {

    if (!missingReportTags || !newConfigData) {
      return null
    }

    if (
      _.find(missingReportTags, (missingReportTag) => {
        return missingReportTag.print_debug_message === newConfigData.print_debug_message;
      })
    ) {
      return false; // Config with the same print_debug_message exists
    }

    try {
      api.post("/missing_report_tags/", {
                            terms_to_ignore: JSON.stringify(newConfigData.terms_to_ignore), 
                            print_debug_message: newConfigData.print_debug_message
                          }).then((res) => {
                            newConfigData.id = res?.data?.data?.id
                            setMissingReportTags((prevTerms) => [...missingReportTags, newConfigData])
                          })
      alert(`Successfully added: ${newConfigData.print_debug_message}!`);
    } catch (err) {
      alert("There was a problem adding this config. Please try again.")
      console.error("API Error: ", err)
    }
    return true 

  }, [missingReportTags])

  const deleteConfigToDB = useCallback((
    id,
    printMessage
  ) => {

    if (!missingReportTags || !id) {
      return null;
    }

    var deletedConfigs = _.remove(
      missingReportTags,
      function (missingReportTag) {
        return missingReportTag.id !== id;
      }
    );
    
    try {
      api.delete("/missing_report_tags/" + id)
      alert(`Successfully removed config with id: ${id} and printMessage: ${printMessage}!`);
    } catch (err) {
      alert("There was a problem deleting this config record. Please try again.")
      console.error("API Error: ", err)
    }

    setMissingReportTags((prevTerms) => [...deletedConfigs])
  
  }, [missingReportTags]);

  // to do add empty logics below!
  return (  <Presenter
                missingReportTags={missingReportTags} 
                updateConfigToDB={updateConfigToDB} 
                createConfigToDB={createConfigToDB}
                deleteConfigToDB={deleteConfigToDB}
  /> )
}

export default MissingReportTags;
 
