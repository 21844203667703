import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib'
import { columnsData, addColumnsData } from "./columnsData";
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)

  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };


  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      
      const body = {
        email: row["email"],
        name: row["email"],
        team: row["team"],
        blacklist: row["blacklist"],
        departments_id: row.departments?.id,
        business_units_id: row.business_units?.id
      }
      addDatabaseRecord([...data], body, form)
    })
  }

  const handleDelete = (id) => {
    api.delete("/members/" + id)
      .then(resp => {
        alert("The member has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/members", body)
      .then(resp => {
        console.log("Added a member record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new member!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this member record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/members/" + id, body)
      .then(resp => {
        console.log("Updated a member record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this member record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        email: row["email"],
        name: row["name"],
        team: row["team"],
        blacklist: row["blacklist"],
        business_units_id: row.business_units?.id,
        departments_id: row.departments?.id,
      }
        editDatabaseRecord(newData, id, body)
    });
  }
  
  const cancel = () => {
    setEditingId("")
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "business_units['id']":
            return col.sorter(rowA['business_unit_name'], rowB['business_unit_name']);
          case "departments['id']":
            return col.sorter(rowA['department_name'], rowB['department_name']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "business_units['id']":
              return "select_business_unit";
            case "departments['id']":
              return "select_department";
            case "blacklist":
              return "select_blacklist"
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });


  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "business_units['id']":
              return "select_business_unit";
            case "departments['id']":
              return "select_department";
            case "blacklist":
              return "select_blacklist";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(member => {
      return {
        email: member.email,
        name: member.name,
        team: member.team,
        blacklist: member.blakclist,
        cloud_contact_email: member.cloud_contact_email,
        business_unit_name: member.business_unit_name
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'member_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
      <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            email: "",
            name: "",
            team: "",
            blacklist: "",
            business_units_id: "",
            departments_id: ""
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
      <Table
        title={() => 'Member Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}