import React, { useState } from 'react'
import { titleize } from '@bowtie/utils'
import * as Models from 'Models'
import { Switch, PrivateRoute } from 'Atoms'
import {
  useFilterApiSelectOptions,
  useModelList,
  useApiSelectOptions,
  useOnChangeForm,
  useOnSubmitForm
} from 'CustomHooks'

const CrudModel = (props) => {
  const { params } = props
  let mn = params.model.split("_").map(element => titleize(element)).join('')
  console.log(mn)
  const ActiveModel = Models[mn]
  const [isLoading, setIsLoading] = useState(true)
  const [filterDepartmentSelect, filterBusinessUnitSelect] = useFilterApiSelectOptions()
  const [modelList, onChangeFilter] = useModelList(params.model, setIsLoading, params.modelId ? params.modelId : null)
  const [departmentSelect, businessUnitSelect] = useApiSelectOptions()
  const [formData, onFormChange, onEditorFormChange, setInitialFormData] = useOnChangeForm()
  const onSubmit = useOnSubmitForm(formData, params.model)

  const newProps = {
    modelList,
    filterDepartmentSelect,
    filterBusinessUnitSelect,
    onChangeFilter,
    departmentSelect,
    businessUnitSelect,
    formData,
    onFormChange,
    onSubmit,
    onEditorFormChange,
    isLoading
  }

  const mergedProps = Object.assign(newProps, props)

  return (
    <div>
      <Switch>
        <PrivateRoute
          exact
          path="/auth/:action(view)/:model"
          props={mergedProps}
          component={ActiveModel.List}
        />
        <PrivateRoute
          path="/auth/:action(crud)/:model"
          props={mergedProps}
          component={ActiveModel.CrudTable}
        />
        <PrivateRoute
          path="/auth/:action(create)/:model(cost_centers|business_units|companies|departments|members|cloud_certifications|faqs|cloud_survey_responses|dcoe_members|github_organization_apps|cost_center_networks|project_ids)"
          props={mergedProps}
          component={ActiveModel.CreateForm}
        />
        <PrivateRoute
          path="/auth/:action(edit)/:model(cost_centers|business_units|companies|departments|members|faqs|github_organization_apps|cost_center_networks|project_ids)/:modelId"
          props={Object.assign(mergedProps, { setInitialFormData })}
          component={ActiveModel.EditForm}
        />
        <PrivateRoute
          path="/auth/:action(view)/:model/:modelId"
          props={mergedProps}
          component={ActiveModel.Single}
        />
        <PrivateRoute
          path="/auth/:action(create|edit)/:model/:modelId?"
          props={mergedProps}
          component={ActiveModel.Form}
        />
      </Switch>
    </div>
  )
}

export default CrudModel