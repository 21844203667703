import React, { useState } from 'react'
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import InputHeader from './InputHeader'
import {api} from 'lib'

const AzurePre = () => {

  const preApFormItems = [
    {
      name: "Invoice",
      id: "data",
      type: "text",
      placeholder: "G008642290",
      tooltip: "Enter the Invoice ID from Azure Portal"
    }
  ]
  
  const initialFormData = {
    data: null
  }

  const [formData, setFormData] = useState(initialFormData);

  const onChange = (e) => {
    let currentFormData = formData;
    currentFormData[e.target.id] = e.target.value;
    setFormData(currentFormData);
  }

  const onSubmit = () => {
    var data = {
      cloud_bill: formData
    };
    console.log(data);
    api.post("/cloud_bills/process_azure_invoice", data)
      .then(resp => {
        alert("Your new data has been submitted!")
      })
      .catch(err => {
        alert("There was a problem submitting this workbook. Please try again.")
        console.error("API Error: ", err)
      })
  }

  return (
    <div>
      <h3>Processing the Azure Invoice</h3>
      <p>This section will allow you to input an Invoice ID from Azure and you will be given the PRE AP
        CSV which will be required in the next step.</p>
      <br />
      <Form>
        {
          preApFormItems && preApFormItems.map((obj, i) => {
            if (obj.type === "text") {
              return (
                <FormGroup key={i}>
                  <Label for={obj.id}>
                    <InputHeader
                      id={obj.id}
                      inputText={obj.name}
                      tooltipText={obj.tooltip}
                    />
                  </Label>
                  <Input
                    type={obj.type}
                    name={obj.name}
                    id={obj.id}
                    onChange={onChange}
                    placeholder={obj.placeholder}
                  />
                </FormGroup>
              )
            } else {
              return null;
            }
          })
        }
        <Button onClick={onSubmit}>Submit</Button>
      </Form>
    </div>
  )
}

export default AzurePre