import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { columnsData } from "./columnsData";
import { getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {

    console.log(modelList)
    setData(modelList)
  }, [isLoading, modelList, data])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    })
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "business_units_id":
            return col.sorter(rowA['business_unit_name'], rowB['business_unit_name']);
          case "default_cost_center_id":
            return col.sorter(rowA['cost_center'], rowB['cost_center']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(virtualMachine => {
      return {
        resource_id: virtualMachine.resource_id,
        state: virtualMachine.state,
        provider_type: virtualMachine.provider_type,
        identifier: virtualMachine.identifier,
        name: virtualMachine.name,
        location: virtualMachine.location,
        resource_type: virtualMachine.resource_type,
        business_unit_name: virtualMachine.business_unit_name,
        cost_center: virtualMachine.cost_center
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'virtualmachines_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
      <Table
        title={() => 'Virtual Machines Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}