import * as Yup from "yup";
import _ from "lodash";

// form validation rules

const editModeSchema = {
  printMessage: Yup.string()
    .required("print message is required"),
  termsToIgnore: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(
          "Value of the terms of ignore is required"
        ),
      })
    )
    .min(1, "At least a term of ignore is required")
};

const editModeValidation =
  Yup.object().shape(editModeSchema);

const createModeValidation = Yup.object().shape({..._.cloneDeep(editModeSchema)});

export { editModeValidation, createModeValidation };
