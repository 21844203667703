import React from 'react'

const CloudParentsListItem = ({ modelListItem, index }) => {
  const {resource_id, state, cost_center, provider_type, identifier, name, location, resource_type, business_unit_name} = modelListItem
  
  return (
    <tr key={index}>
      <td>{resource_id}</td>
      <td>{state}</td>
      <td>{provider_type}</td>
      <td>{identifier}</td>
      <td>{name}</td>
      <td>{location}</td>
      <td>{resource_type}</td>
      <td>{business_unit_name}</td>
      <td>{cost_center}</td>
    </tr>
  )
}

export default CloudParentsListItem