import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import logoImage from './logo.png';

const UnauthHome = () => {
  const [animationState, setAnimationState] = useState('running');

  useEffect(() => {
    // Stop the animation after 3 seconds
    const timeoutId = setTimeout(() => {
      setAnimationState('paused');
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '60vh',
    color: '#007BFF',
  };

  const imageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    animation: `roll 1s linear infinite ${animationState}`, // Adjusted animation duration to 2s
  };

  const imageStyle = {
    width: '300px',
    height: '300px',
    borderRadius: '50%',
  };

  const headingsStyle = {
    marginBottom: '10px',
    padding: '85px',
    textAlign: 'center',
  };

  const buttonStyle = {
    marginTop: '20px',
    width: '100%',
    background: '#007BFF',
    color: '#FFFFFF',
    borderRadius: '4px',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    transition: 'background 0.6s ease',
  };

  const buttonHoverStyle = {
    background: '#0056b3',
  };

  return (
    <Container style={containerStyle}>
      <style>
        {`
          @keyframes roll {
            from {
              transform: rotateY(0deg);
            }
            to {
              transform: rotateY(360deg);
            }
          }
        `}
      </style>
      <Row>
        <Col xs="4" style={imageContainerStyle}>
          <img src={logoImage} style={imageStyle} alt='' />
        </Col>
        <Col xs="8" style={headingsStyle}>
          <h1 style={{fontSize: '3rem'}}>Welcome to the IGT CCOE Admin Portal</h1>
          <Button
            style={{ ...buttonStyle, ':hover': buttonHoverStyle }}
            href={process.env.REACT_APP_LOGIN_URL}
            className="btn-block"
          >
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default UnauthHome;
