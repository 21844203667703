/* global sessionStorage */

import EventEmitter from 'eventemitter2'

class Storage extends EventEmitter {
  set (key, value) {
    const flatValue = typeof value === 'object' ? JSON.stringify(value) : value

    sessionStorage.setItem(key, flatValue)

    this.emit('change', { key, value })
    this.emit(`${key}_changed`, value)
  }

  get (key) {
    const storedValue = sessionStorage.getItem(key)

    try {
      return JSON.parse(storedValue)
    } catch (e) {
      return storedValue
    }
  }

  remove (key) {
    sessionStorage.removeItem(key)

    this.emit('remove', key)
    this.emit(`${key}_removed`)
  }

  clear () {
    sessionStorage.clear()

    this.emit('clear')
  }
}

const storage = new Storage()

storage.setMaxListeners(15)

export default storage