import {Sorter} from "lib"
export const columnsData = [
    {
      title: "Company Code",
      dataIndex: "code",
      width: "10%",
      editable: true,
      sorter: Sorter.NUMBER,
      isSearchable: true
    },
    {
      title: "Location",
      dataIndex: "country",
      width: "7%",
      editable: true,
      sorter: Sorter.NUMBER,
      isSearchable: true
    },
    {
      title: "GL Type",
      dataIndex: "gl_type",
      width: "7%",
      editable: true,
      sorter: Sorter.TEXT,
      isSearchable: true
    },
    {
      title: "AWS GL",
      dataIndex: "aws_gl",
      width: "10%",
      editable: true,
      sorter: Sorter.NUMBER,
      isSearchable: true
    },
    {
      title: "GCL GL",
      dataIndex: "gcp_gl",
      width: "10%",
      editable: true,
      sorter: Sorter.NUMBER,
      isSearchable: true
    },
    {
      title: "Azure GL",
      dataIndex: "azure_gl",
      width: "10%",
      editable: true,
      sorter: Sorter.NUMBER,
      isSearchable: true
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      width: "15%",
      editable: false,
    }
  ];


export const addColumnsData = [
  {
    title: "Company Code",
    dataIndex: "code",
    width: "10%",
    editable: true,
  },
  {
    title: "Location",
    dataIndex: "country",
    width: "7%",
    editable: true,
  },
  {
    title: "GL Type",
    dataIndex: "gl_type",
    width: "7%",
    editable: true,
  },
  {
    title: "AWS GL",
    dataIndex: "aws_gl",
    width: "10%",
    editable: true,
  },
  {
    title: "GCL GL",
    dataIndex: "gcp_gl",
    width: "10%",
    editable: true,
  },
  {
    title: "Azure GL",
    dataIndex: "azure_gl",
    width: "10%",
    editable: true,
  },
  {
    title: "Created on",
    dataIndex: "created_at",
    width: "15%",
    editable: false,
  }
];