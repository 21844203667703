import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib';
import { columnsData, addColumnsData } from "./ColumnsData";
import { IgtLoader } from 'Atoms';
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow,EditableCell } from "common";
import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };

  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const body = {
        name: row["name"],
        abbreviation: row["abbreviation"]
      }
      addDatabaseRecord([...data], body, form)
    })
  }

  const handleDelete = (id) => {
    api.delete("/departments/" + id)
      .then(resp => {
        alert("The department has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/departments", body)
      .then(resp => {
        console.log("Added a new department record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new department!")
        form.resetFields()
      })
      .catch(err => {
        alert("There was a problem adding this department record. Please try again.")
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);

    api.put("/departments/" + id, body)
      .then(resp => {
        console.log("Updated a department record!")
        console.log(resp.status)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this department record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];

      const body = {
          name: row["name"],
          abbreviation: row["abbreviation"]
        }

        editDatabaseRecord(newData, id, body)
    });
  }

  const cancel = () => {
      setEditingId("")
  };

  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "5%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter:  (rowA, rowB) => col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]),
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });

  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(department => {
      return {
        name: department.name,
        abbreviation: department.abbreviation
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'departmment_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
        <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            code: "",
            country: "",
            aws_gl: "",
            gcp_gl: "",
            azure_gl: ""
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
        <Table
       pagination={{ position: 'both' }}  // pagination={false} 

         title={() => 'Department Table'}

          rowKey="id"
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          size='middle'
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}