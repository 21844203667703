import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { api } from 'lib'
import { columnsData, addColumnsData } from "./columnsData";
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
// import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };

  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const body = {
        provider: row["provider"],
        identifier: row["identifier"],
        project_name: row["project_name"],
        distribution_list: row["distribution_list"],
        account_owner: row["account_owner"],
        business_unit: row["business_unit"],
        department: row["department"],
        cloud_environment: row["cloud_environment"],
        cost_center: row["cost_center"],
        still_in_use: row["still_in_usec"],
        can_be_removed: row["can_be_removed"],
        billing_contact: row["billing_contact"],
        security_contact: row["security_contact"],
        operations_contact: row["operations_contact"],
        project_summary: row["project_summary"]
      }
      addDatabaseRecord([...data], body, form)
    })
  }

  const handleDelete = (id) => {
    api.delete("/cloud_survey_responses/" + id)
      .then(resp => {
        alert("The cost center has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/cloud_survey_responses", body)
      .then(resp => {
        console.log("Added a cloud survey response record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new cloud survey response!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this cloud survey response record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/cloud_survey_responses/" + id, body)
      .then(resp => {
        console.log("Updated a cloud survey response record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this cloud survey response record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        provider: row["provider"],
        identifier: row["identifier"],
        project_name: row["project_name"],
        distribution_list: row["distribution_list"],
        account_owner: row["account_owner"],
        business_unit: row["business_unit"],
        department: row["department"],
        cloud_environment: row["cloud_environment"],
        cost_center: row["cost_center"],
        still_in_use: row["still_in_usec"],
        can_be_removed: row["can_be_removed"],
        billing_contact: row["billing_contact"],
        security_contact: row["security_contact"],
        operations_contact: row["operations_contact"],
        project_summary: row["project_summary"]
      }
        editDatabaseRecord(newData, id, body)
    });
  }
  
  const cancel = () => {
    setEditingId("")
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });


  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "3%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  // const CSVDataToDownloadFormat = (data) => {
  //   return data.map(costCenter => {
  //     return {
  //       identifier: costCenter.identifier,
  //       company_code: costCenter.company_code,
  //       aws_gl: costCenter.aws_gl,
  //       gcp_gl: costCenter.gcp_gl,
  //       azure_gl: costCenter.azure_gl,
  //       cloud_contact_email: costCenter.cloud_contact_email,
  //       business_unit_name: costCenter.business_unit_name,
  //       active_at: costCenter.active_at,
  //       expired_at: costCenter.expired_at,
  //       replacement_cost_center: costCenter.replacement_cost_center,
  //       created_at: costCenter.created_at
  //     }
  //   })
  // }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      {/* <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'cloudSurveyResponse_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button> */}
      <Table
      scroll={{ x: "max-content" }}
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            provider: "",
            identifier: "",
            project_name: "",
            distribution_list: "",
            account_owner: "",
            business_unit: "",
            department: "",
            cloud_environment: "",
            cost_center:"",
            still_in_use: "",
            can_be_removed: "",
            billing_contact: "",
            security_contact: "",
            operations_contact: "",
            project_summary: ""
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
      <Table
      scroll={{ x: "max-content" }}
        title={() => 'Cloud Survey Response Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}