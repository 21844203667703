import React from 'react'

const GlossariesListItem = ({ modelListItem, index }) => {
  const { term, category, description } = modelListItem
  return (
    <tr key={index}>
      <td>{term}</td>
      <td dangerouslySetInnerHTML={{ __html: description }}></td>
      <td>{category}</td>
    </tr>
  )
}

export default GlossariesListItem