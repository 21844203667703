import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib'
import { columnsData, addColumnsData } from "./columnsData";
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };

  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const body = {
        identifier: row["identifier"],
        cloud_contact_id: row.cloud_contact?.id ,
        companies_id: row.companies?.id
      }
      addDatabaseRecord([...data], body, form)
    })
  }

  const handleDelete = (id) => {
    api.delete("/sap_cost_centers/" + id)
      .then(resp => {
        alert("The sap cost center has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/sap_cost_centers", body)
      .then(resp => {
        console.log("Added a new sap cost center record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new sap cost center!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this sap cost center record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/sap_cost_centers/" + id, body)
      .then(resp => {
        console.log("Updated a sap cost center record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this sap cost center record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        identifier: row["identifier"],
        cloud_contact_id: row.cloud_contact?.id,
        companies_id: row.companies?.id
      }
        editDatabaseRecord(newData, id, body)
    });
  }
  
  const cancel = () => {
    setEditingId("")
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "cloud_contact['id']":
            return col.sorter(rowA['cloud_contact_email'], rowB['cloud_contact_email']);
          case "companies['id']":
            return col.sorter(rowA['company_code'], rowB['company_code']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "cloud_contact['id']":
              return "select_cloud_contact";
            case "companies['id']":
              return "select_companies";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });


  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "cloud_contact['id']":
              return "select_cloud_contact";
            case "companies['id']":
                return "select_companies";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(sapCostCenter => {
      return {
        identifier: sapCostCenter.identifier,
        aws_gl: sapCostCenter.aws_gl,
        gcp_gl: sapCostCenter.gcp_gl,
        azure_gl: sapCostCenter.azure_gl,
        cloud_contact_email: sapCostCenter.cloud_contact_email,
        company_code: sapCostCenter.company_code
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'sapcostcenter_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
      <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            identifier: "",
            companies_id: "",
            cloud_contact_id: "",
            aws_gl: "",
            gcp_gl: "",
            azure_gl: ""
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
      <Table
        title={() => 'SAP Cost Center Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}