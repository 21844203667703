import React from 'react'
import {
  Button,
  Container,
  Row,
  Col
} from 'reactstrap'

const PageNotFound = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col className="center_headings" lg={{ size: 6, offset: 3 }}>
          <h1>Not Found - 404</h1>
          <h2>The page you were requesting was not found!</h2>
          <br />
          <Button className="btn-block" href="/">Return to Home</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default PageNotFound