import React from 'react'
import ReactJson from 'react-json-view'

export const PermissionSetItem = ({ id, data, index }) => {
  if (id == null || data == null)
    return null
  const modelListItem = (data.find(element => Number(element.id) === id) || {})
  if (modelListItem == null || Object.keys(modelListItem).length === 0)
    return (null)
  const { name, arn, description, duration, managed_policy, inline_policy } = modelListItem
  const inline = inline_policy.substring(1, inline_policy.length - 1).replace(/\\/g, '')
  return (
    <details>
      <summary>{name}</summary>
      <p><strong>Policy Arn:</strong> {arn}</p>
      <p><strong>Description:</strong> {description}</p>
      {duration ? <p><strong>Duration:</strong> {duration.substring(2)}</p> : ""}
      {renderManagedPolicy(managed_policy)}
      {renderInlinePolicy(inline)}
    </details>
  )
}

export default PermissionSetItem

function renderManagedPolicy(managed_policy) {
  if (managed_policy.length === 0)
    return null
  return <details>
    <summary>AWS Managed Policy</summary>

    {
      managed_policy && managed_policy.map((policy, j) => {
        const policyName = policy['name'];
        const policyArn = policy['arn'];
        let url = "https://console.aws.amazon.com/iam/home#policies/" + policyArn
        return (
          <li key={j}>
            <a rel="noopener noreferrer" href={url} target="_blank">{policyName}</a>  
          </li>
        )
      })
    }
  </details>
}

function renderInlinePolicy(inline) {
  if (inline.length < 5)
    return null
  return <details>
    <summary>Inline Policy</summary>
    <ReactJson src={JSON.parse(inline)} theme="summerfruit:inverted" />
  </details>
}
