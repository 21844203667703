import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'


const CloudSurveysCreateForm = ({ isLoading }) => {

  const history = useHistory();

  const initialFormData = {
    provider: "",
    project_name: "",
    identifier: "",
    distribution_list: "",
    account_owner: "",
    business_unit: "",
    department: "",
    cloud_environment: "",
    cost_center: "",
    still_in_use: "",
    can_be_removed: "",
    billing_contact: "",
    security_contact: "",
    operations_contact: "",
    project_summary: "",
  }

  const [formData, setFormData] = useState(initialFormData)
  const [cloudParentIds, setCloudParentIds] = useState([])

  useEffect(() => {
    api.get('/cloud_parents')
      .then(resp => {
        console.log(resp)
        createAccountIdArray(resp.data.data);
      })
      .catch(err => console.error("API Error: ", err))
  }, [])

  const createAccountIdArray = (parents) => {
    console.log(parents);
    const accountIds = parents.map(parent => {
      return {
        displayName: parent.identifier,
        value: parent.identifier + '_' + parent.id
      }
    });
    const cleanAccountIds = accountIds.filter(id => {
      if (id.displayName !== null) {
        return id;
      }
      return false;
    })
    setCloudParentIds(cleanAccountIds);
    return true;
  }
  
  const handleSubmit = () => {
    console.log(formData);
    api.post("/cloud_survey_responses", formData)
      .then(resp => {
        alert("Your new survey response has been added!")
        history.push('/auth/view/cloud_survey_responses')
      })
      .catch(err => {
        alert("There was a problem submitting this survey. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const onChange = (e) => {
    let currentFormData = formData
    if (e.target.id === 'identifier') {
      let [identifier, parent_id] = e.target.value.split('_');
      currentFormData['identifier'] = identifier;
      currentFormData['cloud_parent_id'] = parent_id;
    } else {
      currentFormData[e.target.id] = e.target.value
    }
    setFormData(currentFormData)
  }

  const formItems = [
    {
      name: "Provider",
      id: "provider",
      type: "text",
      placeholder: "AWS",
    },
    {
      name: "Account ID (Identifier)",
      id: "identifier",
      type: "select",
      placeholder: "1234567890",
      options: cloudParentIds,
    },
    {
      name: "Project Name",
      id: "project_name",
      type: "text",
      placeholder: "Test Project",
    },
    {
      name: "DL",
      id: "distribution_list",
      type: "text",
      placeholder: "DEPT.Project@IGT.com",
    },
    {
      name: "Account Owner",
      id: "account_owner",
      type: "text",
      placeholder: "first.last@igt.com",
    },
    {
      name: "Business Unit",
      id: "business_unit",
      type: "text",
      placeholder: "Lottery",
    },
    {
      name: "Department",
      id: "department",
      type: "text",
      placeholder: "iLottery",
    },
    {
      name: "Cloud Environment",
      id: "cloud_environment",
      type: "text",
      placeholder: "Production",
    },
    {
      name: "Cost Center/WBS",
      id: "cost_center",
      type: "text",
      placeholder: "4415",
    },
    {
      name: "Still In Use",
      id: "still_in_use",
      type: "text",
      placeholder: "Yes/No",
    },
    {
      name: "Can Be Removed",
      id: "can_be_removed",
      type: "text",
      placeholder: "Yes/No",
    },
    {
      name: "Billing Contact",
      id: "billing_contact",
      type: "text",
      placeholder: "first.last@igt.com",
    },
    {
      name: "Security Contact",
      id: "security_contact",
      type: "text",
      placeholder: "first.last@igt.com",
    },
    {
      name: "Operations Contact",
      id: "operations_contact",
      type: "text",
      placeholder: "first.last@igt.com",
    },
    {
      name: "Project Summary",
      id: "project_summary",
      type: "textarea",
      placeholder: "Info about the project",
    },
  ]

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Add Cloud Survey Response</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/cloud_survey_responses">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                    if (obj.type === "text") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.type}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                    } else if (obj.type === "select") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onChange}
                          >
                            {
                              obj.options.map((obj, i) => {
                                return (
                                  <option value={obj.value} key={i}>{obj.displayName}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "textarea") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.type}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                    } else {
                      return null
                    }
                  })
                }
                <br />

                <Button color="primary" onClick={handleSubmit}>Confirm</Button>
              </Form>
          }
        </Col>
      </Row>
    </Container>
  

  )
}

export default CloudSurveysCreateForm