import { useState } from "react";
import React from 'react';
import Presenter from "./presenter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInterfaceToCustomerData } from "../../utils/dbDataFormUtil";
import {
  editModeValidation,
  createModeValidation,
} from "./validationSchema";
import { useEffect } from "react";

function ManageCustomer({
  mode,
  handleShow,
  id,
  sfdc_ids,
  name,
  updateCustomerToDB,
  createCustomerToDB,
  deleteCustomerToDB
}) {

  const [nameUpdate, setNameUpdate] = useState(
    name ?? ""
  );

  
  const [
    isDuplicateNameErr,
    setIsDuplicateNameErr,
  ] = useState(false);

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(
      mode === "edit"
        ? editModeValidation
        : createModeValidation
    ),
    defaultValues:
      mode === "create"
        ? {}
        : {
            name: name,
            sfdc_ids: sfdc_ids
          },
    mode: "onChange",
  });

  // Watch and update the form values accordingly upon changes on the higher components
  useEffect(() => {
    setNameUpdate(name ?? "");
    setValue("sfdc_ids", sfdc_ids ?? []);
    console.log(sfdc_ids)
  }, [
    mode,
    id,
    sfdc_ids,
    name,
    setValue
  ]);

  const onSubmit = function (rawFormData, event) {
    event.preventDefault();
    console.log(rawFormData)
    if (isDuplicateNameErr)
      setIsDuplicateNameErr(false);

    if (mode === "edit" && updateCustomerToDB) {
      rawFormData["id"] = id;
      const customersDataForUpdate = rawFormData;
      
      // Add api call here
      updateCustomerToDB(
        id,
        nameUpdate,
        formInterfaceToCustomerData(
          customersDataForUpdate
        )
      )

      setNameUpdate(rawFormData.name)
    }

    if (mode === "create" && createCustomerToDB) {
      const customerDataForCreate = rawFormData;
      console.log(rawFormData)
      if (
        !createCustomerToDB(
          formInterfaceToCustomerData(
            customerDataForCreate
          )
        )
      ) {
        setIsDuplicateNameErr(true);
      } else {
        // name debug is unique!
        if (handleShow) handleShow();
      }
    }


  };

  return (
      <Presenter
      mode={mode}
      isDuplicateNameErr={isDuplicateNameErr}
      id={id}
      name={nameUpdate}
      onSubmit={onSubmit}
      getValues={getValues}
      handleSubmit={handleSubmit}
      deleteCustomerToDB={deleteCustomerToDB}
      errors={errors}
      control={control}
      register={register}
    />
  );
}

export default ManageCustomer;
