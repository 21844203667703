import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CertificationForm = () => {
  const today = new Date();
  const [memberOptions, setMemberOptions] = useState([])
  const [activeDate, setActiveDate] = useState(today);
  const [expiredDate, setExpiredDate] = useState(today);
  const history = useHistory()

  const providerItems = [
    {
      id: 1,
      name: "AWS"
    },
    {
      id: 2,
      name: "GCP"
    },
    {
      id: 3,
      name: "Azure"
    }
  ]

  const formItems = [
    {
      name: "Member Name",
      id: "member_id",
      type: "select",
      placeholder: "Please enter the member name..."
    },
    {
      name: "Certification Name",
      id: "name",
      type: "text",
      placeholder: "Please enter your cert here..."
    },
    {
      name: "Certified Cloud",
      id: "provider",
      type: "select",
      placeholder: "Please enter your cloud provider here..."
    },
    {
      name: "Issued Date",
      id: "issue_date",
      type: "date",
      placeholder: "Please enter the date you passed your test..."
    },
    {
      name: "Expiration Date",
      id: "expiration_date",
      type: "date",
      placeholder: "Please enter the expiration date..."
    }
  ]

  const initialFormData = {
    member_id: null,
    provider: 1,
    name: null,
    issue_date: activeDate,
    expiration_date: expiredDate
  }

  const [formData, setFormData] = useState(initialFormData)

  const onChange = (e) => {
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
    console.log(currentFormData)
  }

  const onSubmit = (e) => {
    api.post("/cloud_certifications", formData)
      .then(resp => {
        alert("Your new cloud certification has been added!")
        history.push('/auth/view/cloud_certifications')
      })
      .catch(err => {
        alert("There was a problem submitting this cloud certification. Please try again.")
        console.error("API Error: ", err)
      })
  }

  // this is triggered on page load
  useEffect(() => {
    api.get('/members')
      .then(resp => {
        setMemberOptions(resp.data.data)
      })
      .catch(err => console.error("API Error: ", err))
  }, [])

  return (
    <Container fluid><Row>
      <Col lg={{ size: 6, offset: 3 }}>
        <h1 className="text-center">Add New Certification</h1>
      </Col>
      <Col lg="3">
        <Button href="/auth/view/cloud_certifications">Go Back</Button>
      </Col>
    </Row>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        name={obj.name}
                        id={obj.id}
                        onChange={onChange}
                      >
                        {obj.id === "member_id" ?
                          memberOptions && memberOptions.map((obj, i) => {
                            return (
                              <option value={obj.id} key={i}>{obj.name}</option>
                            )
                          })
                          :
                          providerItems && providerItems.map((obj, i) => {
                            return (
                              <option value={obj.id} key={i}>{obj.name}</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  )
                } else if (obj.type === "date") {
                  const active = obj.id === "issue_date" ? true : false
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label><br />
                      <DatePicker
                          selected={active ? activeDate : expiredDate}
                          onChange={date => (active ? setActiveDate(date) : setExpiredDate(date))}
                          dateFormat="MMMM d, yyyy"
                      />
                    </FormGroup>
                  )
                } else {
                  return(null)
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CertificationForm