import React from 'react'
import { CheckMark, XMark } from 'Atoms'

const CloudProjectListItem = ({ modelListItem, index }) => {
  const { name, default_project, environment, it_org_owner, cloud_form, cloud_contact_email } = modelListItem
  return (
    <tr key={index}>
      <td>{name}</td>
      <td>{default_project ? <CheckMark /> : <XMark />}</td>
      <td>{environment}</td>
      <td>{it_org_owner}</td>
      <td>{cloud_form}</td>
      <td>{cloud_contact_email}</td>
    </tr>
  )
}

export default CloudProjectListItem