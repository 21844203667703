import React from 'react'
import {
  Input,
  FormGroup
} from 'reactstrap'

const MembersFilters = ({ filterDepartmentSelect, filterBusinessUnitSelect, onChangeFilter }) => {
  return (
    <tr>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Name'
            id='by_name'
            placeholder='Name'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Email'
            id='by_email'
            placeholder='Email'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Input
            name='Department'
            id='by_department'
            type="select"
            onChange={onChangeFilter}
          >
            {
              filterDepartmentSelect && filterDepartmentSelect.map((obj, i) => {
                return (
                  <option value={obj.name} key={i}>{obj.name}</option>
                )
              })
            }
          </Input>
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Input
            name='Business Unit'
            id='by_business_unit'
            type="select"
            onChange={onChangeFilter}
          >
            {
              filterBusinessUnitSelect && filterBusinessUnitSelect.map((obj, i) => {
                return (
                  <option value={obj.name} key={i}>{obj.name}</option>
                )
              })
            }
          </Input>
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Input
            name='Team'
            id='by_team'
            type="select"
            onChange={onChangeFilter}
          >
            <option value='All'>All</option>
            <option value='Core'>Core</option>
            <option value='Advisory'>Advisory</option>
            <option value='Community'>Community</option>
          </Input>
        </FormGroup>
      </td>
      <td></td>
      <td></td>
    </tr>
  )
}

export default MembersFilters