import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'

const DepartmentsCreateForm = ({ isLoading }) => {
  const history = useHistory()

  const initialFormData = {
    name: "",
    abbreviation: ""
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  const onChange = (e) => {
    let currentFormData = {...formData}
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
 
  }

  const onSubmit = () => {
    console.log(formData)

    api.post("/departments", formData)
      .then(resp => {
        alert("Your new deparment has been added!")
        console.log("Submitted")
        history.push('/auth/view/departments')
        window.location.reload();
      })
      .catch(err => {
        alert("There was a problem submitting this department. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Name",
      id: "name",
      type: "text",
      placeholder: "Enter a department name",
    },
    {
      name: "Abbreviation",
      id: "abbreviation",
      type: "text",
      placeholder: "Enter a department abbreviation",
    }
  ]

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Add New Department</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/departments">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={formData[obj.id]}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, onSubmit)}
              </Form>
          }
        </Col>
      </Row>
    </Container>
  )
}

function renderModal(modal, toggle, formData, onSubmit) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm New Department</ModalHeader>
    <ModalBody>
      Department name: {formData["name"]} <br />
      Abbreviation: {formData["abbreviation"]}  <br />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default DepartmentsCreateForm