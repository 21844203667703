import React from 'react'
import {PrivateRoute, Switch} from 'Atoms'
import {
  ActiveDirectoryGroupsAws,
  ActiveDirectoryGroupsAzure,
  ActiveDirectoryGroupsGcp,
  AuthHome,
  BillingAutomation,
  CreateAwsAccount,
  CreateAzureSubscription,
  CreateGcpProject,
  DashStats,
  AccountDetails,
  MembershipRequests,
  MissingReportTags,
  Customers,
  ModifySpincoSgs
} from 'Organisms'
import {CrudModel} from 'Ecosystems'
import {useParams} from 'react-router-dom'



const Auth = (props) => {
  const params = useParams()
  const newProps = {...props}
  newProps.params = params


  return (
    <div>
      <Switch>
        <PrivateRoute
          exact
          path="/auth/:action(home)"
          props={newProps}
          component={AuthHome}
        />
        <PrivateRoute
          path="/auth/:action(missing-tags-report)"
          props={newProps}
          component={MissingReportTags}
        />
                <PrivateRoute
          path="/auth/:action(customers)"
          props={newProps}
          component={Customers}
        />
        <PrivateRoute
          path="/auth/:action(create-aws-account)"
          props={newProps}
          component={CreateAwsAccount}
        />
        <PrivateRoute
          path="/auth/:action(create-gcp-project)"
          props={newProps}
          component={CreateGcpProject}
        />
        <PrivateRoute
          path="/auth/:action(create-azure-subscription)"
          props={newProps}
          component={CreateAzureSubscription}
        />
        <PrivateRoute
          path="/auth/:action(spinco-sg-modification)"
          props={newProps}
          component={ModifySpincoSgs}
        />
        <PrivateRoute
          path="/auth/:action(dash-stats)"
          props={newProps}
          component={DashStats}
        />
        <PrivateRoute
          path="/auth/:action(account-details)"
          props={newProps}
          component={AccountDetails}
        />
        <PrivateRoute
          path="/auth/:action(billing-automation)"
          props={newProps}
          component={BillingAutomation}
        />
        <PrivateRoute
          path="/auth/:action(membership-requests)"
          props={newProps}
          component={MembershipRequests}
        />
        <PrivateRoute
          path="/auth/:action(active-directory/groups-aws)"
          props={newProps}
          component={ActiveDirectoryGroupsAws}
        />
        <PrivateRoute
          path="/auth/:action(active-directory/groups-azure)"
          props={newProps}
          component={ActiveDirectoryGroupsAzure}
        />
        <PrivateRoute
          path="/auth/:action(active-directory/groups-gcp)"
          props={newProps}
          component={ActiveDirectoryGroupsGcp}
        />
        <PrivateRoute
          path="/auth/:action(crud|view|create|edit)/:model"
          props={newProps}
          component={CrudModel}
        />
        <PrivateRoute
          path="/auth/:action(crud|view|edit)/:model/:modelId"
          props={newProps}
          component={CrudModel}
        />
      </Switch>
    </div>
  )
}

export default Auth