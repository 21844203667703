import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'

const GithubOrganizationAppEditForm = ({ isLoading, params }) => {
  console.log(params)
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [app_id, setAppId] = useState(null);
  const [client_id, setClientId] = useState(null);
  const [private_key, setPrivateKey] = useState(null);
  const [team_selection, setTeamSelection] = useState(null);
  const [exclude_form, setExcludeForm] = useState(null);
  const [copilot_enabled, setCopilotEnabled] = useState(null);
  const [costCentersOptions, setCostCentersOptions] = useState([])
  const [costCenters, setCostCenters] = useState([])
  const [costCentersId, setCostCentersId] = useState(null);


  const initialFormData = {
    name: "",
    description: "",
    app_id: "",
    client_id: "",
    private_key: "",
    cost_centers_id: "",
    team_selection: "False", 
    exclude_form: "False",
    copilot_enabled: "False"
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const EMPTY_COST_CENTER_ID = "-9999"
  const EMPTY_COST_CENTER_VALUE = {identifier: "None", id: EMPTY_COST_CENTER_ID, type:"cost_center"}

  const id = params['modelId']

  useEffect(() => {
    let obj = {}
    let cost_centers_temp = ""
    api.get(`/github_organization_apps/${id}`)
      .then(resp => {
        const data = resp.data.data;
        console.log(data)
        obj['name'] = data['name']
        setName(data['name'])
        obj['description'] = data['description']
        setDescription(data['description'])
        obj['app_id'] = data['app_id']
        setAppId(data['app_id'])
        obj['client_id'] = data['client_id']
        setClientId(data['client_id'])
        obj['private_key'] = ''
        setPrivateKey('')
        let temp_team_selection = data['team_selection'] ? 'True' : 'False';
        obj['team_selection'] = temp_team_selection
        setTeamSelection(temp_team_selection)
        let temp_exclude_form = data['exclude_form'] ? 'True' : 'False';
        obj['exclude_form'] = temp_exclude_form
        setExcludeForm(temp_exclude_form)
        let temp_copilot_enabled = data['copilot_enabled'] ? 'True' : 'False';
        obj['copilot_enabled'] = temp_copilot_enabled
        setCopilotEnabled(temp_copilot_enabled)

        if (!data['cost_center']) {
          data['cost_center'] = "" 
          cost_centers_temp = "None"
          setCostCenters("None")
        } else {
          setCostCenters(data['cost_center']) 
          cost_centers_temp = data['cost_center']
        }
      })
      .catch(err => console.error("API Error: ", err))


      api.get('/cost_centers')
      .then(resp => {
        const data = resp.data.data;

        data.push(EMPTY_COST_CENTER_VALUE)
        setCostCentersOptions(data)

        if (cost_centers_temp === "None") { // If cost center hasn't yet been defined
          setCostCentersId(EMPTY_COST_CENTER_ID)
          obj['cost_centers_id'] = EMPTY_COST_CENTER_ID 
        } else {
          const cost_centers_id = data.find(obj => obj.identifier === cost_centers_temp).id  
          setCostCentersId(cost_centers_id)
          obj['cost_centers_id'] = cost_centers_id 
        }
      })
      .catch(err => console.error("API Error: ", err))


    setFormData(obj)
    // eslint-disable-next-line
  }, [])

  const onCostCentersChange = (e) => {
    let currentFormData = formData
    const cost_centers_id = e.target.value
    currentFormData['cost_centers_id'] = cost_centers_id

    costCentersOptions.forEach(cost_center => {
      if (cost_center.id === cost_centers_id)
        setCostCenters(cost_center.identifier)
    })
    setCostCentersId(cost_centers_id)
    setFormData(currentFormData)
  }

  const onChange = (e) => {
    if (e.target.id === 'name')
      setName(e.target.value)
    if (e.target.id === 'description')
      setDescription(e.target.value)
    if (e.target.id === 'app_id')
      setAppId(e.target.value)
    if (e.target.id === 'client_id')
      setClientId(e.target.value)
    if (e.target.id === 'private_key')
      setPrivateKey(e.target.value)
    if (e.target.id === 'team_selection')
      setTeamSelection(e.target.value)
    if (e.target.id === 'exclude_form')
      setExcludeForm(e.target.value)
    if (e.target.id === 'copilot_enabled')
      setCopilotEnabled(e.target.value)
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onSubmit = () => {
    let temp_data = formData
    if (temp_data['private_key'].length === 0)
      delete temp_data['private_key']
    temp_data["team_selection"] =  formData["team_selection"] === "False" ? false : true;
    temp_data["exclude_form"] =  formData["exclude_form"] === "False" ? false : true;
    temp_data["copilot_enabled"] =  formData["copilot_enabled"] === "False" ? false : true;
    const data = {github_organization_apps: temp_data}

    if (formData["cost_centers_id"] === EMPTY_COST_CENTER_ID) {
      alert("Edited Cost Center can't be None!");
      return
    }

    api.put("/github_organization_apps/" + id, data)
      .then(resp => {
        alert("Your GitHub App has been updated!");
        console.log("Submitted")
        window.location.replace("/auth/view/github_organization_apps");
      })
      .catch(err => {
        alert("There was a problem submitting this company. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Name",
      id: "name",
      type: "text",
      value: name
    },
    {
      name: "Description",
      id: "description",
      type: "text",
      value: description
    },
    {
      name: "App ID",
      id: "app_id",
      type: "text",
      value: app_id
    },
    {
      name: "Client ID",
      id: "client_id",
      type: "text",
      value: client_id
    },
    {
      name: "Private Key",
      id: "private_key",
      type: "textarea",
      placeholder: 'Replacement only, current value not visible for security',
      value: private_key
    },
    {
      name: "Allow Team Selection",
      id: "team_selection",
      type: "select",
      value: team_selection,
      options: [{id: 0, name: 'False', type: 'select_option'}, {id: 1, name: 'True', type: 'select_option'}],
    },
    {
      name: "Hide on Request Portal Form",
      id: "exclude_form",
      type: "select",
      value: exclude_form,
      options: [{id: 0, name: 'False', type: 'select_option'}, {id: 1, name: 'True', type: 'select_option'}],
    },
    {
      name: "Copilot Enabled",
      id: "copilot_enabled",
      type: "select",
      value: copilot_enabled,
      options: [{id: 0, name: 'False', type: 'select_option'}, {id: 1, name: 'True', type: 'select_option'}],
    },
    {
      name: "Cost Centers",
      id: "cost_centers_id",
      type: "select_cost_centers",
      value: costCentersId,
      options: costCentersOptions
    }
  ]

  
  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Edit Github Organization APP</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/github_organization_apps">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                    if (obj.type === "text") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            value={obj.value}
                            onChange={onChange}
                          />
                        </FormGroup>
                      ) 
                    } else if (obj.type === "select") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            value={obj.value}
                            onChange={onChange}
                          >
                            {
                              obj.options && obj.options !== null && obj.options.length > 0 && obj.options.map((obj, i) => {
                                return (
                                  <option value={obj.name} key={i}>{obj.name}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "textarea") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.type}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                    } else if (obj.type === "select_cost_centers") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onCostCentersChange}
                          >
                            {
                              costCentersOptions && costCentersOptions.map((obj, i) => {
                                if (!costCenters) return ( <IgtLoader isLoading={isLoading} />)
                                let select = obj.identifier === costCenters
                                return (  
                                  <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.identifier} </option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else {
                      return null
                    }
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, onSubmit, costCenters)}
              </Form>
          }
        </Col>
      </Row>
    </Container >
  )
}

function renderModal(modal, toggle, formData, onSubmit, costCenters) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm Company</ModalHeader>
    <ModalBody>
      Name: {formData["name"]} <br />
      Description: {formData["description"]} <br />
      App ID: {formData["app_id"]} <br />
      Client ID: {formData["client_id"]} <br />
      Private Key: {formData["private_key"]} <br/>
      Team Selection: {formData["team_selection"]} <br />
      Cost Center: { costCenters }<br />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default GithubOrganizationAppEditForm