import React from 'react'
import {
  Input,
  FormGroup
} from 'reactstrap'

const CostCenterFilters = ({ onChangeFilter }) => {
  return (
    <tr>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Date'
            id='by_date'
            placeholder='Date'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td></td>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Company Code'
            id='by_company_code'
            placeholder='Company Code'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td></td>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Reference'
            id='by_reference'
            placeholder='Invoice Num'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Account'
            id='by_account'
            placeholder='GL Account'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Identifier'
            id='by_identifier'
            placeholder='Identifier'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td></td>
      <td></td>
    </tr>
  )
}

export default CostCenterFilters