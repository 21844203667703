import { useState } from "react";
import React from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ManageConfiguration from "../manageConfiguration";
import './CreateConfiguration.css';

function CreateConfiguration({
    createConfigToDB,
}) {
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(!show);
  }

  const handleClose = () => setShow(false);

  return (
    <>
      <Button className="me-2" onClick={() => handleShow()}>
        Create a new config
      </Button>

      <Modal
       
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>Create a new config</Modal.Title>
          <Button variant="secondary" onClick={handleClose}>X</Button>

        </Modal.Header>
        <div >
          <Modal.Body>
            <ManageConfiguration
              mode="create"
              handleShow={handleShow}
              createConfigToDB={createConfigToDB}
            />

          </Modal.Body>
      </div>
      </Modal>
    </>
  );
}



export default CreateConfiguration;
