import { useState, useEffect } from 'react'
import { api } from 'lib'

const useFilterApiSelectOptions = () => {
  const [departmentSelect, setDepartmentSelect] = useState(null)
  const [businessUnitSelect, setBusinessUnitSelect] = useState(null)

  useEffect(() => {
    api.get('/select_options?by_field=department')
    .then(resp => {
      const departments = resp.data.data
      departments.unshift({ name: "All" })
      setDepartmentSelect(departments)
    })
    .catch(err => console.error("API Error: ", err))

    api.get('/select_options?by_field=business_unit')
    .then(resp => {
      const businessUnits = resp.data.data
      businessUnits.unshift({ name: "All" })
      setBusinessUnitSelect(businessUnits)
    })
    .catch(err => console.error("API Error: ", err))
  }, [])

  return [departmentSelect, businessUnitSelect]
}

export default useFilterApiSelectOptions