import React, { useState } from 'react';
import Switch from "react-switch";
import { api } from 'lib'

export default ({blacklist, id}) => {
  const [isBlacklist, setIsBlacklist] = useState(blacklist);

  const updateMemberBlacklistStatusById = () => {
 
    api.put("/members/" + id, {blacklist: !isBlacklist})
      .then(_ => {
        alert("Succesfully updated the blacklist!");
    
      })
      .catch(err => {
        alert("There was a problem when updating the member blacklist Please try again.")
        console.error("Blacklist err: ", err)
      })
  }

  return (
    <div>
      <Switch onChange={() => {
        updateMemberBlacklistStatusById()
        setIsBlacklist(!isBlacklist)
      }} checkedIcon={false} uncheckedHandleIcon={false} checked={isBlacklist} />
    </div>
  );
}