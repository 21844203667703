import React, { useState } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { useHistory } from 'react-router'

const ModifySpincoSgs = (props) => {
  const history = useHistory()
  const formItems = [
    {
      name: "Security Group Name",
      id: "securityGroupName",
      type: "text",
      placeholder: "Enter the security group you would like to modify."
    },
    {
      name: "User(s) Email (@igtplc.onmicrosoft.com)",
      id: "users",
      type: "text",
      placeholder: "Enter the user email(s) you would like to add/remove to the security group. Separate multiple emails with a semicolon."
    }
  ]

  const initialFormData = {
    securityGroupName: "",
    users: "",
  }

  const [formData, setFormData] = useState(initialFormData)

  const onChange = (e) => {
    let currentFormData = formData
    if (e.target.id === "regions") {
      currentFormData[e.target.id] = e.target.value.split(',')
    } else {
      currentFormData[e.target.id] = e.target.value
    }
    setFormData(currentFormData)
  }

  // APP_Azure_SSO_CCOE_CCOE_TestingSpinco3_TEST_Other
  // russ.souffrant@igtplc.onmicrosoft.com;dennis.juanito@igtplc.onmicrosoft.com
  const onSubmitAdd = () => {
    console.log("made it add")
    api.post("/spinco_sgs/add", formData)
      .then(resp => {
        alert("Users Successfully Added!")
        history.push('/auth/spinco-sg-modification')
      })
      .catch(err => {
        alert(`Error your request failed: ${err}`)
        console.error("API Error: ", err)
      })
  }

  const onSubmitRemove = () => {
    console.log("made it remove")
    api.post("/spinco_sgs/remove", formData)
      .then(resp => {
        alert("Users Successfully Removed!")
        history.push('/auth/spinco-sg-modification')
      })
      .catch(err => {
        alert(`Error your request failed: ${err}`)
        console.error("API Error: ", err)
      })
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmitAdd} color='success' style={{marginRight: '5px'}}>Add User(s)</Button>
            <Button onClick={onSubmitRemove} color='danger'>Remove User(s)</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ModifySpincoSgs