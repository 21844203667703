import React, { useState }  from 'react'
import {
  Card,
  Button,
  CardSubtitle,
  CardTitle,
  CardText,
  Col,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from 'reactstrap';
import { api } from 'lib'



const ServiceCatalogProductListItem = ({ modelListItem, index }) => {
  const { identifier, name, description } = modelListItem

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const onClick = (e) => {
    const postData = {
      identifier: modelListItem.identifier,
      name: modelListItem.name,
      description: modelListItem.description
    }
    api.post(`/service_catalog_products/update-product/${e.target.id}`, postData)
      .then(resp => {
        toggle()
        alert("Your request has been submitted!")
      })
      .catch(err => console.error("API Error: ", err))
  }

  return (
    <>
      <Col md="3" key={index} className="py-3">
        <Card body>
          <CardTitle style={{ fontWeight: 'bold' }}>Name: {name}</CardTitle>
          <CardSubtitle>Id: {identifier}</CardSubtitle>
          <CardText dangerouslySetInnerHTML={{ __html: description }}></CardText>
          <Button onClick={toggle}>Update</Button>
        </Card>
      </Col>
      <Modal isOpen={modal} toggle={toggle} style={{width: '30%'}}>
        <ModalHeader toggle={toggle}>Update {name}</ModalHeader>
        <ModalBody>
          By clicking "Update Product" below you will update all versions of <b>{name} ({identifier})</b> to the latest versions in every account it is deployed in. 
          Please make sure you wish to proceed before clicking below. 
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onClick} id={modelListItem.id}>
            Update Product
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
    
  )
}

export default ServiceCatalogProductListItem