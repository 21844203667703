import React, {useState} from 'react'
import {useHistory} from 'react-router'
import {api} from 'lib'
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import "react-datepicker/dist/react-datepicker.css";

const CreateContactForm = ({ isLoading }) => {

    const history = useHistory()
    const initialFormData = {
        email: ""
    }
    const [formData, setFormData] = useState(initialFormData)

    const onChange = (e) => {
        let currentFormData = formData
        currentFormData[e.target.id] = e.target.value
        setFormData(currentFormData)
        // console.log(currentFormData)
    }

    const onSubmit = () => {
        console.log("Submitted")
        console.log(formData)
        api.post("/cloud_contacts/by_email", {
            cloud_contact: formData
        })
        .then(resp => {
            alert("Your new contact has been added!")
            history.push('/auth/view/cloud_contacts')
        })
        .catch(err => {
            alert("There was a problem submitting this contact. Please try again.")
            console.error("API Error: ", err)
        })
    } 

    const formItems = [
        {
            name: "Email",
            id: "email",
            type: "text",
            placeholder: "Enter an IGT email",
        }
    ]

    return (
        <Container fluid>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    <h1 className="text-center">Add New Cloud Contact</h1>
                </Col>
            </Row>
            <Row>
                <Col lg={{ size: 6, offset: 3 }}>
                    {
                        <Form>
                            {
                                formItems && formItems.map((obj, i) => {
                                    if (obj.type === "text") {
                                        return (
                                            <FormGroup key={i}>
                                                <Label for={obj.id}>{obj.name}</Label>
                                                <Input
                                                    type={obj.id}
                                                    name={obj.name}
                                                    id={obj.id}
                                                    placeholder={obj.placeholder}
                                                    onChange={onChange}
                                                />
                                            </FormGroup>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                            <br />

                            <Button color="primary" onClick={onSubmit}>Confirm</Button>
                        </Form>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default CreateContactForm