import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

const Wysiwyg = ({ onChange, value, ...rest }) => {
  const plugins = [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
  ]
  const toolbar = 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
  return (
    <Editor
      apiKey="gq84yjl6ui9e0e3r8bem00e8ns375ahc1thf5v8cxsfq0epw"
      onEditorChange={onChange}
      value={value}
      plugins={plugins}
      toolbar={toolbar}
      {...rest}
    />
  )
}

export default Wysiwyg