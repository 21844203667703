import React from "react";
import {
    Form,
    Input,
    InputNumber,
    Select,
    DatePicker,
    TimePicker,
    Checkbox,
    Radio
} from "antd";
import { api } from 'lib'
import { EditableContext } from "../EditableFormRow";
import AddCostCenterModal from "./AddCostCenterModal"
import moment from "moment";
const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;
export default class EditableCell extends React.Component {
    dateFormat = "MM-DD-YYYY";
    timeFormat = "HH:mm";

    state = { newReplacementCCId: "", newAltCostCenterId: "", newNetworkId: "", newProjectId: "", networkCostCenter: "", cloudContactOptions: [], newCostCenterOptions: [], altCostCenterOptions: [], newNetworkOptions: [], newProjectOptions: [], networkCostCenterOptions: [], businessUnitOptions: [],  costCenterNetworksOptions: [], departmentOptions: [], memberOptions: [], companiesCodeOptions: [], githubOrganizationOptions: []}

    componentDidMount() {
        const {
            record,
        } = this.props;

        if (record && record["new_cost_center"]) {
            this.setState({
                newReplacementCCId: record["new_cost_center"]["id"]
            })
        }

        if (record && record["alt_cost_center"]) {
            this.setState({
                newAltCostCenterId: record["alt_cost_center"]["id"]
            })
        }

        if (record && record["new_network"]) {
            this.setState({
                newNetworkId: record["new_network"]["id"]
            })
        }

        if (record && record["cost_center_network"]) {
            this.setState({
                newNetworkId: record["cost_center_network"]["id"]
            })
        }

        if (record && record["new_project"]) {
            this.setState({
                newProjectId: record["new_project"]["id"]
            })
        }

        if (record && record["cost_center"] && record["type"] === 'cost_center_network') {
            this.setState({
                networkCostCenter: record["cost_center"]["id"]
            })
        }

        if (this.props.inputType === "select_cloud_contact") {
            api.get('/cloud_contacts').then((resp) => {
                this.setState({
                    cloudContactOptions: resp.data.data 
                        .sort((c1, c2) => c1.email.toLowerCase() > c2.email.toLowerCase() ? 1 : -1)
                        .map((c, index) => (<Option value={c.id} key={index}>{c.email}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))
        }

        if (this.props.inputType === "select_new_cost_center") {
            api.get('/cost_centers').then((resp) => {
                this.setState({
                    newCostCenterOptions: [...resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>)), <Option value={""} key={9999999}>None</Option>]
                })
            }
            
            ).catch(err => console.error("API Error: ", err))  
        }

        if (this.props.inputType === "select_alt_cost_center") {
            api.get('/cost_centers').then((resp) => {
                this.setState({
                    altCostCenterOptions: [...resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>)), <Option value={""} key={9999999}>None</Option>]
                })
            }
            
            ).catch(err => console.error("API Error: ", err))  
        }

        if (this.props.inputType === "select_new_network") {
            api.get('/cost_center_networks').then((resp) => {
                this.setState({
                    newNetworkOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))  
        } 

        if (this.props.inputType === "select_new_project") {
            api.get('/project_ids').then((resp) => {
                this.setState({
                    newProjectOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))  
        } 

        if (this.props.inputType === "select_new_network_cost_center") {
            api.get('/cost_centers').then((resp) => {
                this.setState({
                    networkCostCenterOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))  
        } 

        if (this.props.inputType === "select_business_unit") {
            api.get('/business_units').then((resp) => {
                this.setState({
                    businessUnitOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.name}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))  
        } 

        
        if (this.props.inputType === "select_cost_center_network") {
            api.get('/cost_center_networks').then((resp) => {
                this.setState({
                    costCenterNetworksOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))  
        } 

        if (this.props.inputType === "select_department") {
            api.get('/departments')
                .then((resp) => {
                    const departmentOptions = resp.data.data
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort department data alphabetically by name
                        .map((c, index) => (
                            <Option value={c.id} key={index}>{c.abbreviation} - {c.name}</Option>
                        ));
                    this.setState({ departmentOptions });
                })
                .catch(err => console.error("API Error: ", err));
        }

        if (this.props.inputType === "select_member") {
            api.get('/members').then((resp) => {
                this.setState({
                    memberOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.name}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))  
        } 
        
        if (this.props.inputType === "select_companies") {
            api.get("/companies").then((resp) => {
                this.setState({
                    companiesCodeOptions: resp.data.data
                        .map((c, index) => (<Option value={c.id} key={index}>{c.code} {c.country} - {c.gl_type}</Option>))
                })
            })
        }

        if (this.props.inputType === "select_github_organization") {
            api.get('/github_organization_apps').then((resp) => {
                this.setState({
                    githubOrganizationOptions: resp.data.data 
                        .sort((c1, c2) => c1.name.toLowerCase() > c2.name.toLowerCase() ? 1 : -1)
                        .map((c, index) => (<Option value={c.id} key={index}>{c.name}</Option>))
                })
            }

            ).catch(err => console.error("API Error: ", err))
        }
    }

    componentDidUpdate(prevProps) {
        // update new_cost_center value before or after switching to/from 'none' value
        if (this.props.record && this.props.record["new_cost_center"] && prevProps.record["new_cost_center"]) {
            if (this.props.record["new_cost_center"]["id"] !== prevProps.record["new_cost_center"]["id"]) {
                this.setState({newReplacementCCId: this.props.record["new_cost_center"]["id"]});
              }
        
            // value -> none
        } else if (this.props.record && prevProps.record["new_cost_center"] && !this.props.record["new_cost_center"]) {
            this.setState({newReplacementCCId: ""})
        }  else if (this.props.record && !prevProps.record["new_cost_center"] && this.props.record["new_cost_center"]) {
            // None -> Value 
            this.setState({newReplacementCCId: this.props.record["new_cost_center"]["id"]})
        } 

        // update alt_cost_center value before or after switching to/from 'none' value
        if (this.props.record && this.props.record["alt_cost_center"] && prevProps.record["alt_cost_center"]) {
            if (this.props.record["alt_cost_center"]["id"] !== prevProps.record["alt_cost_center"]["id"]) {
                this.setState({newAltCostCenterId: this.props.record["alt_cost_center"]["id"]});
              }
        
            // value -> none
        } else if (this.props.record && prevProps.record["alt_cost_center"] && !this.props.record["alt_cost_center"]) {
            this.setState({newAltCostCenterId: ""})
        }  else if (this.props.record && !prevProps.record["alt_cost_center"] && this.props.record["alt_cost_center"]) {
            // None -> Value 
            this.setState({newAltCostCenterId: this.props.record["alt_cost_center"]["id"]})
        } 
    }

    updateParentReplacementCostCenter = (newCostCenterId) => {
        api.get('/cost_centers').then((resp) => {
            this.setState({
                newCostCenterOptions: [<Option value={""} key={9999999}>None</Option>, ...resp.data.data
                .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>))]
            })
        }

        ).catch(err => console.error("API Error: ", err))  

        this.setState({ newReplacementCCId: newCostCenterId})
    }

    updateParentAltCostCenter = (newCostCenterId) => {
        api.get('/cost_centers').then((resp) => {
            this.setState({
                altCostCenterOptions: [<Option value={""} key={9999999}>None</Option>, ...resp.data.data
                .map((c, index) => (<Option value={c.id} key={index}>{c.identifier}</Option>))]
            })
        }

        ).catch(err => console.error("API Error: ", err))  

        this.setState({ newAltCostCenterId: newCostCenterId})
    }

    getInput = (record, dataIndex, title, getFieldDecorator) => {
        switch (this.props.inputType) {
            case "number":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Please Input ${title}!`
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(
                            <InputNumber formatter={value => value} parser={value => value} />
                        )}
                    </FormItem>
                );
            case "date":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex] ? moment(record[dataIndex]) : null
                        })(<DatePicker format={this.dateFormat} />)}
                    </FormItem>
                );
            case "time":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: moment(record[dataIndex], this.timeFormat)
                        })(<TimePicker format={this.timeFormat} />)}
                    </FormItem>
                );
            case "checkbox":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex] === "true",
                            valuePropName: "checked"
                        })(<Checkbox />)}
                    </FormItem>
                );
            case "radio":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex]
                        })(
                            <RadioGroup>
                                <Radio value={"a"}>a</Radio>
                                <Radio value={"b"}>b</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>
                ); 
            case "select_cloud_contact":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["cloud_contact"] ?  record["cloud_contact"]["id"] : ""
                        })(
                            <Select  allowClear
                            showSearch
                            filterOption={(inputValue, option) => {
                                return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
 
                            }
                            } style={{ width: 200 }}>
                                {[...this.state.cloudContactOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_new_cost_center":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: this.state.newReplacementCCId
                        })(
                            <Select style={{ width: 150 }} showSearch filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0                            }                                
                              }>
                                {[...this.state.newCostCenterOptions]}
                            </Select>
                        )
                        }
                        <AddCostCenterModal updateParentCostCenter={this.updateParentReplacementCostCenter} />
                    </FormItem>
                )
            case "select_alt_cost_center":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: this.state.newAltCostCenterId
                        })(
                            <Select style={{ width: 150 }} showSearch filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0                            }                                
                                }>
                                {[...this.state.altCostCenterOptions]}
                            </Select>
                        )
                        }
                        <AddCostCenterModal updateParentCostCenter={this.updateParentAltCostCenter} />
                    </FormItem>
                )
            case "select_new_network":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: this.state.newNetworkId
                        })(
                            <Select style={{ width: 150 }} showSearch filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0                            }                                
                              }>
                                {[...this.state.newNetworkOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_new_project":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: this.state.newProjectId
                        })(
                            <Select style={{ width: 200 }} showSearch filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0                            }                                
                              }>
                                {[...this.state.newProjectOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_new_network_cost_center":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: this.state.networkCostCenter
                        })(
                            <Select style={{ width: 150 }} showSearch filterOption={(input, option) => {
                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0                            }                                
                              }>
                                {[...this.state.networkCostCenterOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_business_unit":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["business_units"] ? record["business_units"]["id"] : ""
                        })(
                            <Select style={{ width: 110 }}>
                                {[...this.state.businessUnitOptions]}
                                
                            </Select>
                        )
                        }
                    </FormItem>
                )

            case "select_cost_center_network":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["cost_center_networks"] ? record["cost_center_networks"]["id"] : ""
                        })(
                            <Select style={{ width: 110 }}>
                                {[...this.state.costCenterNetworksOptions]}
                                
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_department":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["departments"] ?  record["departments"]["id"] : ""
                        })(
                            <Select
                                allowClear
                                showSearch
                                filterOption={(inputValue, option) => {
                                    return option.props.children.join().toLowerCase().includes(inputValue.toLowerCase())
                                }}
                                style={{ width: 190 }}
                            >
                                {[...this.state.departmentOptions]}
                            </Select>
                        )}
                    </FormItem>

                )
            case "select_member":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["member"] ? record["member"]["id"] : ""
                        })(
                            <Select style={{ width: 170 }}>
                                {[...this.state.memberOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_companies":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["companies"] ? record["companies"]["id"] : ""
                        })(
                            <Select style={{ width: 220 }}>
                                {[...this.state.companiesCodeOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_provider":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["provider"] ? record["provider"] : ""
                        })(
                            <Select style={{ width: 170 }}>
                                <Option value={"aws"} key={1}>aws</Option>
                                <Option value={"azure"} key={2}>azure</Option>
                                <Option value={"gcp"} key={3}>gcp</Option>
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_blacklist":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["blacklist"] ? true: false
                        })(
                            <Select style={{ width: 170 }}>
                                <Option value={true} key={1}>True</Option>
                                <Option value={false} key={2}>False</Option>
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select_github_organization":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record["github_organization_app"] ?  record["github_organization_app"]["id"] : ""
                        })(
                            <Select  allowClear
                            showSearch
                            filterOption={(inputValue, option) => {
                                return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
    
                            }
                            } style={{ width: 200 }}>
                                {[...this.state.githubOrganizationOptions]}
                            </Select>
                        )
                        }
                    </FormItem>
                )
            case "select":
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            initialValue: record[dataIndex]
                        })(
                            <Select style={{ width: 150 }}>
                                {[...Array(11).keys()]
                                    .filter(x => x > 0)
                                    .map(c => `Product ${c}`)
                                    .map((p, index) => (
                                        <Option value={p} key={index}>
                                            {p}
                                        </Option>
                                    ))}
                            </Select>
                        )}
                    </FormItem>
                );

            default:
                return (
                    <FormItem style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: false,
                                    message: `Please Input ${title}!`
                                }
                            ],
                            initialValue: record[dataIndex]
                        })(<Input />)}
                    </FormItem>
                );
        }
    };

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            ...restProps
        } = this.props;
        return (
            <EditableContext.Consumer>
                {form => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing
                                ?
                                this.getInput(record, dataIndex, title, getFieldDecorator)
                                : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}