import { IgtLoader } from 'Atoms'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Container, Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup, 
  Input,
  InputGroupText
} from 'reactstrap'
import { api, storage } from 'lib'
import { Filters, ListItem } from '.'

const SapCostCentersList = ({ onChangeFilter, modelList, isLoading }) => {

  const [modal, setModal] = useState(false); 
  const toggleUploadModal = () => setModal(!modal);
  const user_email = storage.get('userEmail');
  const [email, setEmail] = useState(user_email);
  const [spreadsheet, setSpreadSheet] = useState(null);

  const retrieveAndSetSpreadsheet = (event) => {
    setSpreadSheet(event.target.files[0])
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = String(reader.result);
        let data = result.match(/,(.*)$/)[1];
        resolve(data);
      }
      reader.onerror = (error) => {
        resolve(false);
      };
    })
  }

  const onSubmit = () => {
    console.log(email);
    console.log(spreadsheet);
    getBase64(spreadsheet).then(base_file => {
      const data = {
        sap_cost_center: {
          email: email, 
          base_file: base_file
        }
      }
      api.post("/sap_cost_centers/upload", data)
      .then(resp => {
        console.log(resp);
        alert("The updated cost centers will be emailed to you!");
        setModal(false);
      })
      .catch(err => {
        console.error(err);
        alert('There was an issue uploading your file, please ensure that the data');
      })
    });
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">SAP Cost Centers Table</h1>
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Button onClick={toggleUploadModal} >Upload New Cost Centers</Button>
          {doUploadModal(modal, toggleUploadModal, onSubmit, setEmail, email, retrieveAndSetSpreadsheet, spreadsheet)}
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Table hover>
                <thead className='sticky-header'>
                  <tr>
                    <th>Identifier</th>
                    <th>Company Code</th>
                    <th>AWS GL</th>
                    <th>GCP GL</th>
                    <th>Azure GL</th>
                    <th>Owner</th>
                  </tr>
                </thead>
                <tbody>
                  
                <Filters
                  onChangeFilter={onChangeFilter}
                />
                  {
                    modelList && modelList.map((costCenter, i) => {
                      return (
                        <ListItem
                          modelListItem={costCenter}
                          key={i}
                          index={i}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default SapCostCentersList

function doUploadModal(modal, toggle, onSubmit, setEmail, email, retrieveAndSetSpreadsheet, spreadsheet) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Upload New SAP Cost Centers</ModalHeader>
    <ModalBody>
      Upload the new cost center spreadsheet below and add your email, we will send you a spreadsheet with the new cost centers when completed. <br/><br/>
      <span style={{color: 'red'}}>The Uploaded File must be a Zipped CSV (.csv.zip)</span><br/><br/>

      <InputGroup style={{padding: '10px'}}>
        <input type='file' accept=".zip" name='spreadsheet' id='spreadsheet' onChange={retrieveAndSetSpreadsheet} />
      </InputGroup>
      <InputGroup style={{padding: '10px'}}>
        <InputGroupText>Email</InputGroupText>
        <Input value={email} onChange={event => setEmail(event.target.value)} />
      </InputGroup>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Upload File</Button>{' '}
      <Button color="secondary" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}
