import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CostCentersCreateForm = ({ isLoading }) => {

  const history = useHistory()
  const [contactOptions, setContactOptions] = useState([])
  const [businessUnitsOptions, setBusinessUnitsOptions] = useState([])
  const [departmentsOptions, setDepartmentsOptions] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  const [activeDate, setActiveDate] = useState(new Date());
  const [expiredDate, setExpiredDate] = useState(null);
  const [costCenterData, setCostCenterData] = useState([]);
  const [showNewCostCenter, setShowNewCostCenter] = useState(false);
  const [showAltCostCenter, setShowAltCostCenter] = useState(false);
  const [cloudContact, setCloudContact] = useState(false); // used for confirmation screen - convert id to string
  const [businessUnits, setBusinessUnits] = useState(false); // used for confirmation screen - convert id to string
  const [departments, setDepartments] = useState(false); // used for confirmation screen - convert id to string
  const [companies, setCompanies] = useState(false); // used for confirmation screen - convert id to string
  const [costCenter, setCostCenter] = useState(false); // used for confirmation screen - convert id to string
  const [backupCostCenter, setBackupCostCenter] = useState(false) //used for confirmation screen only shown if set. 

  const initialFormData = {
    identifier: "",
    cloud_contact_id: "303", // Abreu Jose
    company_code: "01", // 01 company code
    active_at: new Date().toISOString(),
    expired_at: null,
    new_cost_center_id: null,
    business_units_id: "1", // CCOE,
    departments_id: "1", // Casino = CMS
    companies_id: "1" //  01 (Vegas)
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    api.get('/business_units')
      .then(resp => {
        setBusinessUnitsOptions(resp.data.data)
        setBusinessUnits(resp.data.data[0].name)
      })
      .catch(err => console.error("API Error: ", err))
    api.get('/departments')
      .then(resp => {
        setDepartmentsOptions(resp.data.data)
        setDepartments(resp.data.data[0].name)
      })
      .catch(err => console.error("API Error: ", err))
    api.get('/companies')
      .then(resp => {
        const sorted_data = resp.data.data.sort((a,b) => a.code - b.code)
        setCompaniesOptions(sorted_data)
        setCompanies(sorted_data[0].code)
    })
    .catch(err => console.error("API Error: ", err))
    api.get('/cloud_contacts')
      .then(resp => {
        setContactOptions(resp.data.data)
        setCloudContact(resp.data.data[0].email)
      })
      .catch(err => console.error("API Error: ", err))
    api.get('/cost_centers')
      .then(resp => {
        setCostCenterData(resp.data.data)
      })
      .catch(err => console.error("API Error: ", err))
  }, [])


  const onChange = (e) => {
    let currentFormData = {...formData}
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onCheckChange = (e) => {
    setShowNewCostCenter(!showNewCostCenter)
  }

  const onAltCheckChange = (e) => {
    setShowAltCostCenter(!showAltCostCenter)
  }

  const onCompanyCodeChange = (e) => {
    let currentFormData = formData
    const companies_id = e.target.value
    currentFormData['companies_id'] = companies_id

    companiesOptions.forEach(company => {
      if (company.id === companies_id)
        setCompanies(company.code)
    })
    setFormData(currentFormData)
  }

  const onContactChange = (e) => {
    let currentFormData = formData
    const contact_id = e.target.value
    currentFormData['cloud_contact_id'] = contact_id

    contactOptions.forEach(contact => {
      if (contact.id === contact_id)

        setCloudContact(contact.email)
    })

    setFormData(currentFormData)

  }
  
  const onBusinessUnitsChange = (e) => {
    let currentFormData = formData
    const business_units_id = e.target.value
    currentFormData['business_units_id'] = business_units_id

    businessUnitsOptions.forEach(business_unit => {
      if (business_unit.id === business_units_id)
        setBusinessUnits(business_unit.name)
    })
    setFormData(currentFormData)
  }

  const onDepartmentsChange = (e) => {
    let currentFormData = formData
    const departments_id = e.target.value
    currentFormData['departments_id'] = departments_id

    departmentsOptions.forEach(department => {
      if (department.id === departments_id)
        setDepartments(department.name)
    })
    setFormData(currentFormData)
  }


 

  const onNewCostCenterChange = (e) => {
    let currentFormData = formData
    const cost_center_id = e.target.value
    currentFormData['new_cost_center_id'] = cost_center_id

    costCenterData.forEach(cc => {
      if (cc.id === cost_center_id)
        setCostCenter(cc.identifier)
    })
    setFormData(currentFormData)

  }

  const onAltCostCenterChange = (e) => {
    let currentFormData = formData
    const cost_center_id = e.target.value
    currentFormData['alt_cost_center_id'] = cost_center_id

    costCenterData.forEach(cc => {
      if (cc.id === cost_center_id)
        setBackupCostCenter(cc.identifier)
    })
    setFormData(currentFormData)

  }

  const isCloudContactExists = async () => {
    try {
      let data = (await api.get('/cloud_contacts')).data.data
      const cloudContactFound = data.find(obj => obj.email === cloudContact)

      if (!cloudContactFound) {
        window.alert(`Cloud contact: ${cloudContact} doesn't exists! Please add it first before creating this cost center this form`)
        history.push('/auth/create/cloud_contacts')
        return false
      }
    } catch (err) {
      console.error("API Error: ", err)
      window.alert(`API Error!`)
      return false
    }
    return true
  }

  const onSubmit = async () => {
    if (expiredDate !== null)
      formData['expired_at'] = expiredDate.toISOString()

    //if the expired_at date picker is hidden - set it to null
    if (showNewCostCenter === false) {
      formData['new_cost_center_id'] = null
    }

    if (showAltCostCenter === false) {
      formData['alt_cost_center_id'] = null
    }

    formData['active_at'] = activeDate.toISOString()
    console.log(formData)

    if(!(await isCloudContactExists())) return
    const newFormData = {
      cost_center: formData
    }
    
    api.post("/cost_centers", newFormData)
      .then(resp => {
        alert("Your new cost center has been added!")
        console.log("Submitted")
        history.push('/auth/view/cost_centers')
        window.location.reload()
      })
      .catch(err => {
        alert("There was a problem submitting this cost center. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Cost Center",
      id: "identifier",
      type: "text",
      placeholder: "Cost Center or WBS",
    },
    {
      name: "Cloud Contact",
      id: "cloud_contact_id",
      type: "select_contact",
      options: contactOptions
    },
    {
      name: "Company Code",
      id: "companies_id",
      type: "select_company_code",
      options: companiesOptions
    },
    {
      name: "Business Unit",
      id: "business_units_id",
      type: "select_business_units",
      options: businessUnitsOptions
    },
    {
      name: "Department",
      id: "departments_id",
      type: "select_departments",
      options: departmentsOptions
    },
    {
      name: "Active At",
      id: "active_at",
      type: "date"
    },
    {
      name: "Expired At",
      id: "expired_at",
      type: "date"
    },
    {
      name: "Replacement Cost Center",
      id: "new_cost_center_id",
      type: "select_cost_center"
    },
    {
      name: "Backup Cost Center",
      id: "alt_cost_center_id",
      type: "select_alt_cost_center"
    }
  ]

  function getExpired() {
    if (expiredDate !== null) {
      formData['expired_at'] = expiredDate.toISOString()
      return 'Expired at: ' + new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(expiredDate)
    } else {
      return ''
    }
  }

  function getReplacementCostCenter() {
    //if the expired_at date picker is hidden - set it to null
    if (showNewCostCenter === false) {
      formData['new_cost_center_id'] = null
    } else {
      return 'Replacement: ' + costCenter
    }
  }

  function getAlternateCostCenter() {
    //if the expired_at date picker is hidden - set it to null
    if (showAltCostCenter === false) {
      formData['alt_cost_center_id'] = null
    } else {
      return 'Backup Cost Center: ' + backupCostCenter
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Add New Cost Center</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/cost_centers">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                    if (obj.type === "text") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={formData[obj.id]}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                    } else if (obj.type === "select") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onChange}
                          >
                            {
                              obj.options.map((obj, i) => {
                                return (
                                  <option value={obj.value} key={i}>{obj.displayName}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_company_code") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onCompanyCodeChange}
                          >
                            {
                              companiesOptions.map((obj, i) => {
                                return (
                                  <option value={obj.id} key={i}>{obj.code} ({obj.gl_type}{obj.country !== null ? ' - ' + obj.country : ''})</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_contact") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onContactChange}
                          >
                            {
                              contactOptions && contactOptions.map((obj, i) => {
                                return (
                                  <option value={obj.id} key={i}>{obj.name}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_business_units") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onBusinessUnitsChange}
                          >
                            {
                              businessUnitsOptions && businessUnitsOptions.map((obj, i) => {
                                return (  
                                  <option value={obj.id} key={i}>{obj.name} </option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_departments") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onDepartmentsChange}
                          >
                            {
                              departmentsOptions && departmentsOptions.map((obj, i) => {
                                return (  
                                  <option value={obj.id} key={i}>{obj.name}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_cost_center") {
                      const selectNewCostCenter = <FormGroup key={i}>
                        <p>Note: If the cost center is not in this list, you will have to go back and add it first</p>
                        <Label for={obj.id}>{obj.name}</Label>
                        <Input
                          name={obj.name}
                          id={obj.id}
                          type="select"
                          onChange={onNewCostCenterChange}
                        >
                          {costCenterData && costCenterData.map((obj, i) => {
                            return (
                              <option value={obj.id} key={i}>{obj.identifier}</option>
                            )
                          })}
                        </Input>
                      </FormGroup>
                      return (
                        showNewCostCenter && selectNewCostCenter
                      )
                    } else if (obj.type === "date") {
                      const active = obj.id === "active_at" ? true : false
                      const dateForm = <FormGroup key={i}>
                        <Label for={obj.id}>{obj.name}</Label><br />
                        <DatePicker
                          selected={active ? activeDate : expiredDate}
                          isClearable={active ? false : true}
                          onChange={date => (active ? setActiveDate(date) : setExpiredDate(date))}
                          dateFormat="MMMM d, yyyy"
                        />
                      </FormGroup>

                      const checkbox = <FormGroup check key={i + 100}>
                        <Input type="checkbox" name="check" id="addNewCostCenterCheck" onChange={onCheckChange} />
                        <Label for="addNewCostCenterCheck" check>Add Replacement Cost Center</Label>
                      </FormGroup>

                      const expiredWithCheckbox = [dateForm, checkbox];
                      if (!active)
                        return (expiredWithCheckbox)
                      return (dateForm)
                    } else if (obj.type === "select_alt_cost_center") {
                      return (
                        <>
                          <br/>
                          <FormGroup check key={i + 100}>
                            <Input type="checkbox" name="check" id="addAltCostCenterCheck" onChange={onAltCheckChange} />
                            <Label for="addAltCostCenterCheck" check>Add Backup Cost Center (WBS Only)</Label>
                          </FormGroup>
                          {showAltCostCenter && (
                            <FormGroup key={i}>
                              <p>Note: If the cost center is not in this list, you will have to go back and add it first</p>
                              <Label for={obj.id}>{obj.name}</Label>
                              <Input
                                name={obj.name}
                                id={obj.id}
                                type="select"
                                onChange={onAltCostCenterChange}
                              >
                                {costCenterData && costCenterData.map((obj, i) => {
                                  return (
                                    <option value={obj.id} key={i}>{obj.identifier}</option>
                                  )
                                })}
                              </Input>
                            </FormGroup>
                          )}
                        </>
                      )
                    } else {
                      return null
                    }
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, cloudContact, companies, businessUnits, departments, activeDate, getExpired, getReplacementCostCenter, getAlternateCostCenter, onSubmit)}
              </Form>
          }
        </Col>
      </Row>
    </Container>
  )
}

function renderModal(modal, toggle, formData, cloudContact, companies, businessUnits, departments, activeDate, getExpired, getReplacementCostCenter, getAlternateCostCenter, onSubmit) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm New Cost Center</ModalHeader>
    <ModalBody>
      Identifier: {formData["identifier"]} <br />
      Contact: {cloudContact} <br />
      Company Code: {companies} <br />
      Business Unit: {businessUnits} <br />
      Department: {departments} <br />
      Active at: {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(activeDate)} <br />
      {getExpired()} <br />
      {getReplacementCostCenter()} <br />
      {getAlternateCostCenter()} <br />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default CostCentersCreateForm