import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router'

const CostCentersEditForm = ({ isLoading, params }) => {
  const history = useHistory()
  const [contactOptions, setContactOptions] = useState([])
  const [businessUnitsOptions, setBusinessUnitsOptions] = useState([])
  const [departmentsOptions, setDepartmentsOptions] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  const [replacementCostCenterOptions, setReplacementCostCenterOptions] = useState([])
  const [costCenterData, setCostCenterData] = useState([]);
  const [showNewCostCenter, setShowNewCostCenter] = useState(false);
  const [showAltCostCenter, setShowAltCostCenter] = useState(false);
  const [backupCostCenter, setBackupCostCenter] = useState(false);
  const [cloudContact, setCloudContact] = useState(false); // used for confirmation screen - convert id to string
  const [businessUnits, setBusinessUnits] = useState(false); // used for confirmation screen - convert id to string
  const [departments, setDepartments] = useState(false); // used for confirmation screen - convert id to string
  const [companies, setCompanies] = useState(false); // used for confirmation screen - convert id to string

  const [identifier, setIdentifier] = useState(null);
  const [cloudContactId, setCloudContactId] = useState(null);
  const [businessUnitsId, setBusinessUnitsId] = useState(null);
  const [departmentsId, setDepartmentsId] = useState(null);
  const [companiesId, setCompaniesId] = useState(null);
  const [replacementCostCenterId, setReplacementCostCenterId] = useState(null);
  const [costCenterCompanyCodeId, setcostCenterCompanyCodeId] = useState(null);
  const [activeDate, setActiveDate] = useState(new Date());
  const [expiredDate, setExpiredDate] = useState(null);
  const [replacementCostCenter, setReplacementCostCenter] = useState(null);
  const initialFormData = {
    identifier: "",
    cloud_contact_id: "",
    business_units_id: "",
    departments_id: "",
    companies_id: "",
    active_at: "",
    expired_at: null,
    new_cost_center_id: null,
    alt_cost_center_id: null
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const EMPTY_BUSINESS_UNIT_ID = "-9999"
  const EMPTY_BUSINESS_UNIT_VALUE = {name: "None", id: EMPTY_BUSINESS_UNIT_ID, type:"business_unit"}

  const EMPTY_DEPARTMENT_ID = "-9999"
  const EMPTY_DEPARTMENT_VALUE = {name: "None", id: EMPTY_DEPARTMENT_ID, type:"department"}

  const EMPTY_REPLACEMENT_COST_CENTER_ID = "-9999"
  const EMPTY_REPLACEMENT_COST_CENTER_VALUE = {identifier: "None", id: EMPTY_REPLACEMENT_COST_CENTER_ID, type:"cost_center"}
  const id = params['modelId']

  useEffect(() => {
    let cloud_contact_email_temp = ""
    let business_units_temp = ""
    let departments_temp = ""
    let replacement_cost_center_temp = ""
    let companies_temp = ""
    let obj = {}
    api.get('/cost_centers')
      .then(resp => {
        const data = resp.data.data;
        setCostCenterData(data)
        const att = data.find(obj => obj.id === id)
        obj['identifier'] = att['identifier']
        setIdentifier(att['identifier'])
        setCloudContact(att['cloud_contact_email']) 
        setcostCenterCompanyCodeId(att["companies"]["id"])
        cloud_contact_email_temp = att['cloud_contact_email']

        if (!att['business_unit_name']) {
          att['business_unit_name'] = "" 
          business_units_temp = "None"
          setBusinessUnits("None")
        } else {
          setBusinessUnits(att['business_unit_name']) 
          business_units_temp = att['business_unit_name']
        }

        if (!att['department_name']) {
          att['department_name'] = "" 
          departments_temp = "None"
          setDepartments("None")
        } else {
          setDepartments(att['department_name']) 
          departments_temp = att['department_name']
        }

        if (!att['company_code']) {
          att['company_code'] = "" 
          companies_temp = "None"
          setCompanies("None")
        } else {
          setCompanies(att['company_code']) 
          companies_temp = att['company_code']
        }

        if ('active_at' in att) {
          const active = Date.parse(att['active_at'])
          obj['active_at'] = active
          setActiveDate(active)
        }
        
        if ('expired_at' in att) {
          const expired = Date.parse(att['expired_at'])
          obj['expired_at'] = expired
          setExpiredDate(expired)
        }

        if ('replacement_cost_center' in att) {
          // const newCC = att['replacement_cost_center']
          setShowNewCostCenter(true) // ?? move or not

          setReplacementCostCenter(att['replacement_cost_center'])
          replacement_cost_center_temp = att['replacement_cost_center']
          // const replacement_id = resp.data.find(obj => obj.identifier === newCC).id
          // obj['new_cost_center_id'] = replacement_id
          
        } else {
          att['replacement_cost_center'] = "" 
          replacement_cost_center_temp = "None"
          setReplacementCostCenter("None")
        }

        if ('alternate_cost_center' in att) {
          const altCC = att["alternate_cost_center"]
          setShowAltCostCenter(true)
          setBackupCostCenter(altCC)
          const alternate_id = resp.data.find(obj => obj.identifier === altCC).id
          obj['alt_cost_center_id'] = alternate_id
        }

      })
      .catch(err => console.error("API Error: ", err))

    api.get('/cloud_contacts')
      .then(resp => {
        const data = resp.data.data;
        setContactOptions(data)
        const cloud_contact_id = data.find(obj => obj.email === cloud_contact_email_temp).id
        setCloudContactId(cloud_contact_id)
        obj['cloud_contact_id'] = cloud_contact_id
      })
      .catch(err => console.error("API Error: ", err))

      api.get('/business_units')
      .then(resp => {
        const data = resp.data.data;

        data.push(EMPTY_BUSINESS_UNIT_VALUE)
        setBusinessUnitsOptions(data)

        if (business_units_temp === "None") { // If business unit hasn't yet been defined
          setBusinessUnitsId(EMPTY_BUSINESS_UNIT_ID)
          obj['business_units_id'] = EMPTY_BUSINESS_UNIT_ID 
        } else {
          const business_units_id = data.find(obj => obj.name === business_units_temp).id  
          setBusinessUnitsId(business_units_id)
          obj['business_units_id'] = business_units_id 
        }
      })
      .catch(err => console.error("API Error: ", err))

      api.get('/departments')
      .then(resp => {
        const data = resp.data.data;

        data.push(EMPTY_DEPARTMENT_VALUE)
        setDepartmentsOptions(data)

        if (departments_temp === "None") { // If department hasn't yet been defined
          setDepartmentsId(EMPTY_DEPARTMENT_ID)
          obj['departments_id'] = EMPTY_DEPARTMENT_ID 
        } else {
          const departments_id = data.find(obj => obj.name === departments_temp).id  
          setDepartmentsId(departments_id)
          obj['departments_id'] = departments_id 
        }
      })
      .catch(err => console.error("API Error: ", err))

      api.get('/cost_centers')
      .then(resp => {
        const data = resp.data.data;

        data.push(EMPTY_REPLACEMENT_COST_CENTER_VALUE)
        setReplacementCostCenterOptions(data)

        if (replacement_cost_center_temp === "None") { // If department hasn't yet been defined
          setReplacementCostCenterId(EMPTY_REPLACEMENT_COST_CENTER_ID)
          obj['new_cost_center_id'] = EMPTY_REPLACEMENT_COST_CENTER_ID 
        } else {
          const new_cost_center_id = data.find(obj => obj.identifier === replacement_cost_center_temp).id  
          setReplacementCostCenterId(new_cost_center_id)
          obj['new_cost_center_id'] = new_cost_center_id 
        }
      })
      .catch(err => console.error("API Error: ", err))

      api.get('/companies')
      .then(resp => {
        const data = resp.data.data;
        console.log(data);
        const sorted_data = data.sort((a,b) => a.code - b.code);
        setCompaniesOptions(sorted_data)

        const companies_id = data.find(obj => obj.code === companies_temp).id // This is undefined for unknown reason
        setCompaniesId(companies_id)
        obj['companies_id'] = companies_id 
      })
      .catch(err => console.error("API Error: ", err))

    setFormData(obj)
    // eslint-disable-next-line
  }, [])


  const onChange = (e) => {
    if (e.target.id === 'identifier')
      setIdentifier(e.target.value)
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onCheckChange = (e) => {
    setShowNewCostCenter(!showNewCostCenter)
  }

  const onAltCheckChange = (e) => {
    setShowAltCostCenter(!showAltCostCenter)
  }

  const onContactChange = (e) => {
    let currentFormData = formData
    const contact_id = e.target.value
    currentFormData['cloud_contact_id'] = contact_id

    contactOptions.forEach(contact => {
      if (contact.id === contact_id)
        setCloudContact(contact.email)
    })
    setCloudContactId(contact_id)
    setFormData(currentFormData)
  }

  const onBusinessUnitsChange = (e) => {
    let currentFormData = formData
    const business_units_id = e.target.value
    currentFormData['business_units_id'] = business_units_id

    businessUnitsOptions.forEach(business_unit => {
      if (business_unit.id === business_units_id)
        setBusinessUnits(business_unit.name)
    })
    setBusinessUnitsId(business_units_id)
    setFormData(currentFormData)
  }

  const onDepartmentsChange = (e) => {
    let currentFormData = formData
    const departments_id = e.target.value
    currentFormData['departments_id'] = departments_id

    departmentsOptions.forEach(department => {
      if (department.id === departments_id)
        setDepartments(department.name)
    })
    setDepartmentsId(departments_id)
    setFormData(currentFormData)
  }
  
  const onCompaniesChange = (e) => {
    let currentFormData = formData
    const companies_id = e.target.value
    currentFormData['companies_id'] = companies_id

    companiesOptions.forEach(company => {
      if (company.id === companies_id)
        setCompanies(company.code)
    })
    setCompaniesId(companies_id)
    setFormData(currentFormData)
  }

  const onNewCostCenterChange = (e) => {
    let currentFormData = formData
    const cost_center_id = e.target.value
    currentFormData['new_cost_center_id'] = cost_center_id

    replacementCostCenterOptions.forEach(cc => {
      if (cc.id === cost_center_id)
        setReplacementCostCenter(cc.identifier)
    })
    setReplacementCostCenterId(cost_center_id)
    setFormData(currentFormData)
  }

  const onAltCostCenterChange = (e) => {
    let currentFormData = formData
    const cost_center_id = e.target.value
    currentFormData['alt_cost_center_id'] = cost_center_id

    costCenterData.forEach(cc => {
      if (cc.id === cost_center_id)
        setBackupCostCenter(cc.identifier)
    })
    setFormData(currentFormData)
  }

  const isCloudContactExists = async () => {
    try {
      let data = (await api.get('/cloud_contacts')).data.data
      const cloudContactFound = data.find(obj => obj.email === cloudContact)
        
      if (!cloudContactFound) {
        window.alert(`Cloud contact: ${cloudContact} doesn't exists! Please add it first before editing this cost center this form`)
        history.push('/auth/create/cloud_contacts')
        return false
      }
    } catch (err) {
      console.error("API Error: ", err)
      window.alert(`API Error!`)
      return false
    }
    return true
  }

  const onSubmit = async () => {
    if (expiredDate !== null && !isNaN(expiredDate)) {
      console.log(expiredDate.constructor.name)
      formData['expired_at'] = new Date(expiredDate).toISOString()
    }

    //if the expired_at date picker is hidden - set it to null
    if (showNewCostCenter === false) {
      formData['new_cost_center_id'] = null
    }

    if (showAltCostCenter === false) {
      formData['alt_cost_center_id'] = null
    }

    if (formData["departments_id"] === EMPTY_DEPARTMENT_ID) {
      alert("Edited Department can't be None!");
      return
    }

    if (formData["business_units_id"] === EMPTY_BUSINESS_UNIT_ID) {
      alert("Edited Business Unit can't be None!");
      return
    }

    if (formData["new_cost_center_id"] === EMPTY_REPLACEMENT_COST_CENTER_ID) {
      formData['new_cost_center_id'] = null;
    }

    
    if (activeDate !== null && !isNaN(activeDate)) {
      formData['active_at'] = new Date(activeDate).toISOString()
    }

    console.log(formData)

    if(!(await isCloudContactExists())) return 

    api.put("/cost_centers/" + id, formData)
      .then(resp => {
        alert("Your cost center has been updated!");
        console.log(formData)
        console.log("Submitted")
        window.location.replace("/auth/view/cost_centers");
      })
      .catch(err => {
        alert("There was a problem submitting this cost center. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Cost Center",
      id: "identifier",
      value: identifier,
      type: "text",
      placeholder: "Cost Center or WBS",
    },
    {
      name: "Cloud Contact",
      id: "cloud_contact_id",
      type: "select_contact",
      value: cloudContactId,
      options: contactOptions
    },
    {
      name: "Business Units",
      id: "business_units_id",
      type: "select_business_units",
      value: businessUnitsId,
      options: businessUnitsOptions
    },
    {
      name: "Departments",
      id: "departments_id",
      type: "select_departments",
      value: departmentsId,
      options: departmentsOptions
    },
    {
      name: "Company Code",
      id: "companies_id",
      type: "select_companies",
      value: companiesId,
      options: companiesOptions
    },
    {
      name: "Active At",
      id: "active_at",
      type: "date"
    },
    {
      name: "Expired At",
      id: "expired_at",
      type: "date"
    },
    {
      name: "Replacement Cost Center",
      id: "new_cost_center_id",
      value: replacementCostCenterId,
      options: replacementCostCenterOptions,
      type: "select_cost_center"
    },
    {
      name: "Backup Cost Center",
      id: "alt_cost_center_id",
      type: "select_alt_cost_center"
    }
  ]
 
  function getExpired() {
    let valid = expiredDate && Object.prototype.toString.call(expiredDate) === "[object Date]" && !isNaN(expiredDate)
    if (expiredDate !== null && valid) {
      // formData['expired_at'] = expiredDate.toISOString()
      return 'Expired at: ' + new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(expiredDate)
    } else if (expiredDate !== null && !valid && !isNaN(expiredDate)) {
      return 'Expired at: ' + new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(expiredDate)
    } else {
      return ''
    }
  }

  function getActive() {
    let valid = activeDate && Object.prototype.toString.call(activeDate) === "[object Date]" && !isNaN(activeDate)
    if (activeDate !== null && valid) {
      return 'Active at: ' + new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(activeDate)
    } else if (expiredDate !== null && !valid && !isNaN(expiredDate)) {
      return 'Active at: ' + new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(activeDate)
    } else {
      return ''
    }
  }

  function getReplacementCostCenter() {
    //if the expired_at date picker is hidden - set it to null
    if (showNewCostCenter === false) {
      formData['new_cost_center_id'] = null
    } else {
      return 'Replacement: ' + replacementCostCenter
    }
  }

  function getAlternateCostCenter() {
    //if the expired_at date picker is hidden - set it to null
    if (showAltCostCenter === false) {
      formData['alt_cost_center_id'] = null
    } else {
      return 'Backup Cost Center: ' + backupCostCenter
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Edit Cost Center</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/cost_centers">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                    if (obj.type === "text") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={obj.value}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                    } else if (obj.type === "select") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onChange}
                          >
                            {
                              obj.options.map((obj, i) => {
                                return (
                                  <option value={obj.value} key={i}>{obj.displayName}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_contact") {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            name={obj.name}
                            id={obj.id}
                            type="select"
                            onChange={onContactChange}
                          >
                            {
                              contactOptions && contactOptions.map((obj, i) => {
                                if (!cloudContact) return ( <IgtLoader isLoading={isLoading} />)
                                let select = obj.email.toLowerCase() === cloudContact.toLowerCase()
                                return (
                                  <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.name} </option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      )
                    } else if (obj.type === "select_business_units") {
                        return (
                          <FormGroup key={i}>
                            <Label for={obj.id}>{obj.name}</Label>
                            <Input
                              name={obj.name}
                              id={obj.id}
                              type="select"
                              onChange={onBusinessUnitsChange}
                            >
                              {
                                businessUnitsOptions && businessUnitsOptions.map((obj, i) => {
                                  if (!businessUnits) return ( <IgtLoader isLoading={isLoading} />)
                                  let select = obj.name.toLowerCase() === businessUnits.toLowerCase()
                                  return (  
                                    <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.name} </option>
                                  )
                                })
                              }
                            </Input>
                          </FormGroup>
                        )
                      } else if (obj.type === "select_departments") {
                        return (
                          <FormGroup key={i}>
                            <Label for={obj.id}>{obj.name}</Label>
                            <Input
                              name={obj.name}
                              id={obj.id}
                              type="select"
                              onChange={onDepartmentsChange}
                            >
                              {
                                departmentsOptions && departmentsOptions.map((obj, i) => {
                                  if (!departments) return ( <IgtLoader isLoading={isLoading} />)
                                  let select = obj.name.toLowerCase() === departments.toLowerCase()
                                  return (  
                                    <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.name} </option>)
                                })
                              }
                            </Input>
                          </FormGroup>
                        )
                      } else if (obj.type === "select_companies") {
                        console.log(obj)
                        return (
                            <FormGroup key={i}>
                              <Label for={obj.id}>{obj.name}</Label>
                              <Input
                                name={obj.name}
                                id={obj.id}
                                type="select"
                                onChange={onCompaniesChange}
                              >
                                {
                                  companiesOptions && companiesOptions.map((obj, i) => {
                                    if (!companies) return ( <IgtLoader isLoading={isLoading} />)
                                    let select = obj.id === costCenterCompanyCodeId
                                    return (  
                                      <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.code} ({obj.gl_type}{obj.country !== null ? ' - ' + obj.country : ''}) </option>
                                    )
                                  })
                                }
                              </Input>
                            </FormGroup>
                          )                                      
                    } else if (obj.type === "select_cost_center") {
                      const selectNewCostCenter = <FormGroup key={i}>
                        <p>Note: If the cost center is not in this list, you will have to go back and add it first</p>
                        <Label for={obj.id}>{obj.name}</Label>
                        <Input
                          name={obj.name}
                          id={obj.id}
                          type="select"
                          onChange={onNewCostCenterChange}
                        >

                          {replacementCostCenterOptions && replacementCostCenterOptions.map((obj, i) => {
                            if (!replacementCostCenter) return ( <IgtLoader isLoading={isLoading} />)

                            let select = obj.identifier.toLowerCase() === replacementCostCenter.toLowerCase()

                            return (
                              <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.identifier}</option>
                            )
                          })}
                        </Input>
                      </FormGroup>
                      return (
                        showNewCostCenter && selectNewCostCenter
                      )
                    } else if (obj.type === "date") {
                      const active = obj.id === "active_at" ? true : false
                      const dateForm = <FormGroup key={i}>
                        <Label for={obj.id}>{obj.name}</Label><br />
                        <DatePicker
                          selected={active ? activeDate : expiredDate}
                          isClearable={active ? false : true}
                          onChange={date => (active ? setActiveDate(date) : setExpiredDate(date))}
                          dateFormat="MMMM d, yyyy"
                        />
                      </FormGroup>

                      const checkbox = <FormGroup check key={i + 100}>
                        <Input type="checkbox" name="check" id="addNewCostCenterCheck" checked={showNewCostCenter} onChange={onCheckChange} />
                        <Label for="addNewCostCenterCheck" check>Add Replacement Cost Center</Label>
                      </FormGroup>

                      const expiredWithCheckbox = [dateForm, checkbox];
                      if (!active)
                        return (expiredWithCheckbox)
                      return (dateForm)
                    } else if (obj.type === "select_alt_cost_center") {
                      return (
                        <>
                          <br/>
                          <FormGroup check key={i + 100}>
                            <Input type="checkbox" name="check" id="addAltCostCenterCheck" checked={showAltCostCenter} onChange={onAltCheckChange} />
                            <Label for="addAltCostCenterCheck" check>Add Backup Cost Center (WBS Only)</Label>
                          </FormGroup>
                          {showAltCostCenter && (
                            <FormGroup key={i}>
                              <p>Note: If the cost center is not in this list, you will have to go back and add it first</p>
                              <Label for={obj.id}>{obj.name}</Label>
                              <Input
                                name={obj.name}
                                id={obj.id}
                                type="select"
                                onChange={onAltCostCenterChange}
                              >
                                {costCenterData && costCenterData.map((obj, i) => {
                                  let select = obj.identifier === backupCostCenter
                                  return (
                                    <option selected={select === true ? 'selected' : ''} value={obj.id} key={i}>{obj.identifier}</option>
                                  )
                                })}
                              </Input>
                            </FormGroup>
                          )}
                        </>
                      )
                    } else {
                      return null
                    }
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, companies, businessUnits, departments, cloudContact, activeDate, getExpired, getActive, getReplacementCostCenter, getAlternateCostCenter, onSubmit)}
              </Form>
          }
        </Col>
      </Row>
    </Container >
  )
}

function renderModal(modal, toggle, formData, companies, businessUnits, departments, cloudContact, activeDate, getExpired, getActive, getReplacementCostCenter, getAlternateCostCenter, onSubmit) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm New Cost Center</ModalHeader>
    <ModalBody>
      Identifier: {formData["identifier"]} <br />
      Contact: {cloudContact} <br />
      Business Unit: {businessUnits} <br />
      Department: {departments} <br />
      Company Code: {companies} <br />
      {getActive()} <br /> 
      {getExpired()} <br />
      {getReplacementCostCenter()} <br />
      {getAlternateCostCenter()} <br />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default CostCentersEditForm