import { api } from 'lib'

const useOnSubmitForm = (formData, modelName) => {

  const filterDepartment = (body) => {
    if (body["department"] === "Other") {
      body["department"] = body["other_department"]
    }
    delete body.other_department
    return body
  }

  const onSubmit = () => {
    console.log("Submitted Data: ", formData)
    let body = formData
    let path = modelName
    if (modelName === "members") {
      body = filterDepartment(body)
      path = "/team_membership_tickets"
    }
    if (modelName.includes('dcoe')) {
      console.log('hmmm')
      path = path.replace('_', '/')
      if (modelName === "dcoe_members") {
        filterDepartment(body)
      }
    }
    api.post(path, formData)
    .then(resp => {
      console.log("API Response: ", resp)
      window.location.href = `/auth/view/${modelName}`
    })
    .catch(err => console.error("API Error: ", err))
  }

  return onSubmit
}

export default useOnSubmitForm