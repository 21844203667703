import React, { useState }from 'react'
import { storage, loginApi } from 'lib'
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  InputGroup,
  InputGroupText,
  Input
} from 'reactstrap'
import { ListItem } from '.'
import { IgtLoader } from 'Atoms'

const CloudCertificationsList = ({ modelList, isLoading }) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const user_email = storage.get('userEmail');
  const [email, setEmail] = useState(user_email)

  const onSubmit = (e) => {
    let data = {
      cloud_certification: {
        email: email
      }
    }
    console.log(data)
    loginApi().post('cloud_certifications/export', data)
      .then(resp => {
        alert("Your report will be emailed!");
      })
      .catch(err => {
        alert("There was a problem with this action. Please try again later: ", err)
        console.error("API Error: ", err)
      })
    toggleModal()
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <h1 className="text-center">Cloud Certifications Table</h1>
        </Col>
        <Col lg="3">
          <Button style={{margin: '5px'}} href="/auth/create/cloud_certifications">Add New Certification</Button>
          <Button style={{margin: '5px'}} onClick={toggleModal}>Export Certificates</Button>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Export Cloud Certifications</ModalHeader>
            <ModalBody>
              Enter your email to recieve a CSV of current cloud certifications. 
              <InputGroup>
                <InputGroupText>Email</InputGroupText>
                <Input value={email} onChange={event => setEmail(event.target.value)} />
              </InputGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={onSubmit}>Send</Button>{' '}
              <Button color="secondary" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
            <IgtLoader />
            :
            <Table hover>
              <thead className='sticky-header'>
                <tr>
                  <th>Member Name</th>
                  <th>Certification Name</th>
                  <th>Business Unit</th>
                  <th>Certified Cloud</th>
                  <th>Issue Date</th>
                  <th>Expiration Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  modelList && modelList.map((cert, i) => {
                    return (
                      <ListItem
                        modelListItem={cert}
                        key={i}
                        index={i}
                      />
                    )
                  })
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CloudCertificationsList