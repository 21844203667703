import React from 'react'
import { NavBar } from 'Molecules'

const Header = (props) => {
  const { isLoggedIn } = props
  return (
    <div className="header">
      <NavBar 
        isLoggedIn={isLoggedIn}
      />
    </div>
  )
}

export default Header