import React from 'react'
import moment from 'moment'


const ProjectIdsListItem = ({modelListItem, index}) => {
  const {
    identifier,
    cost_center_network_identifier,
    active_at,
    expired_at,
    replacement_project,
    created_at,
    end_date, 
    active,
    ppm_project_name, 
    sap_project_name
  } = modelListItem

  return (
    <tr key={index}>
      <td>{identifier}</td>
      <td>{cost_center_network_identifier}</td>
      <td>{sap_project_name !== null ? sap_project_name : ''}</td>
      <td>{ppm_project_name !== null ? ppm_project_name : ''}</td>
      <td>{active_at !== null ? moment(active_at).format('DD-MMM-yyyy') : ' '}</td>
      <td>{expired_at !== null ? moment(expired_at).format('DD-MMM-yyyy') : ' '}</td>
      <td>{replacement_project}</td>
      <td>{created_at !== null ? moment(created_at).format('DD-MMM-yyyy') : ' '}</td>
      <td>{end_date !== null ? moment(end_date).format('DD-MMM-yyyy') : ' '}</td>
      <td>{active === null ?  ' ' : active === true ? 'TRUE' : 'FALSE'}</td>
    </tr>
  )
}

export default ProjectIdsListItem