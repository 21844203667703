import { useState, useEffect } from 'react'
import { api } from 'lib'

const useModelList = (modelName, setIsLoading, modelId = null) => {
  const [filterData, setFilterData] = useState([])
  const [modelList, setModelList] = useState(null)

  const onChangeFilter = (e) => {
    let currentFilterData = {...filterData}
    currentFilterData[e.target.id] = e.target.value
    setFilterData(currentFilterData)
  }

  let query_array = []
  Object.entries(filterData).forEach(entry => {
    const [key, value] = entry
    if (value !== "" && value !== "All") {
      query_array.push(`${key}=${value}&`)
    }
  })

  let apiUrl = `${modelName}`
  if (modelId) {
    apiUrl = apiUrl + `/${modelId}`
  }
  if (query_array.length > 0) {
    apiUrl = apiUrl + `?${query_array.join('')}`
  }
  if (apiUrl.includes('dcoe_')) {
    apiUrl = apiUrl.replace('_', '/')
  }

  useEffect(() => {
    api.get(apiUrl)
    .then(resp => {
      setModelList(resp.data.data)
      setIsLoading(false)
    })
    .catch(err => console.error("API ERROR: ", err))
  }, [apiUrl, setIsLoading])

  return [modelList, onChangeFilter]
}

export default useModelList