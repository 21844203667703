import React from 'react'
import {
  Col,
  Container,
  Row,
  Table
} from 'reactstrap'
import { ListItem } from '.'
import { IgtLoader } from 'Atoms'

const ProjectIdsList = ({ onChangeFilter, modelList, isLoading }) => { 

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Project IDs Table</h1>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Table hover>
                <thead className='sticky-header'>
                  <tr>
                    <th>Identifier</th>
                    <th>Cost Center Network</th>
                    <th>SAP Project Name</th>
                    <th>PPM Project Name</th>
                    <th>Active</th>
                    <th>Expired</th>
                    <th>Replacement</th>
                    <th>Created</th>
                    <th>End Date</th>
                    <th>Active</th>
                  </tr>
                </thead>
                <tbody>
                  
                  {
                    modelList && modelList.map((projectId, i) => {
                      return (
                        <ListItem
                          modelListItem={projectId}
                          key={i}
                          index={i}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default ProjectIdsList
