import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FieldArray from "./fieldArray";
import { Popconfirm } from "antd";

export default ({
  mode,
  name,
  id,
  onSubmit,
  handleSubmit,
  getValues,
  errors,
  control,
  register,
  deleteCustomerToDB,
  isDuplicateNameErr
}) => (
    <div>
      <>
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <fieldset disabled={mode === "view"}>
                <Row>
                  <Col md={5}>
         
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Customer name
                      </Form.Label>

                      <Form.Control
                        {...register("name")}
                        type="text"
                        isInvalid={errors.name}
                      />

                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: "flex" }}
                      >
                        {errors?.name?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  
                    <FieldArray
                      {...{
                        control,
                        register,
                      }}
                      fieldLabel="sfdc ids"
                      errors={errors}
                      fieldName="sfdc_ids"
                      addButtonText="Add a sfdc id"
                      property="value"
                    />
                  </Col>
  
                </Row>
                <Row>
                  <Col md={10}>
                    <div style={{ width: "50rem" }}>
                      {mode === "edit" ||
                      mode === "create" ? (
                        <Button
                          variant="primary"
                          type="submit"
                        >
                          {mode === "edit"
                            ? "Update Customer"
                            : "Create Customer"}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                    {mode === "create" &&
                    isDuplicateNameErr ? (
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: "flex" }}
                      >
                        Customer name: "{getValues("name")}{" "}"
                        exists
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col md={1}>
                    <div style={{ width: "10rem" }}>
                      {mode === "edit" ? (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => {
                            if (deleteCustomerToDB) {
                              deleteCustomerToDB(id, name);
                            }
                          }}
                        >
                        <Button variant="danger">Delete Customer</Button>
                        </Popconfirm>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </fieldset>
            </Form>
          </Card.Body>
        </Card>
      </>
    </div>
);