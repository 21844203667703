import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Table
} from 'reactstrap'
import { CheckMark, XMark, IgtLoader } from 'Atoms'
import moment from 'moment'

const MembershipRequests = (props) => {
  const [requests, setRequests] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api.get("/team_membership_tickets")
    .then(resp => {
      setRequests(resp)
      setIsLoading(false)
    })
    .catch(err => {
      console.error("Error: ", err)
    })
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          <h1 className='text-center'>Membership Requests</h1>
        </Col>
      </Row>
      <Row>
        <Col lg='12'>
          {
            isLoading ?
            <IgtLoader />
            :
            <Table hover>
              <thead>
                <tr>
                  <th>Requesting User Email</th>
                  <th>Department</th>
                  <th>Business Unit</th>
                  <th>Requested Date</th>
                  <th>Completed</th>
                </tr>
              </thead>
              <tbody>
                {
                  requests && requests.data.data.map((request, i) => {
                    return(
                      <tr key={i}>
                        <td>{request.requesting_principal_user_id}</td>
                        <td>{request.department}</td>
                        <td>{request.business_unit}</td>
                        <td>{moment(request.requested_date).format('MMMM Do YYYY, h:mm:ss a')}</td>
                        <td>{request.completed ? <CheckMark /> : <XMark />}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default MembershipRequests