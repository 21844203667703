import React from 'react'
import { IconContext } from 'react-icons'
import { FaEdit } from 'react-icons/fa'

const EditIcon = () => {
  return (
    <IconContext.Provider value={{size: "2em" }}>
      <div>
        <FaEdit />
      </div>
    </IconContext.Provider>
  )
}

export default EditIcon
