import React, { useState, useEffect } from "react"; 
import { Table, Button } from "antd"; 
import { columnsData, addColumnsData } from "./columnsData"; 
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow,EditableCell } from "common";
import { api } from 'lib';
import { IgtLoader } from 'Atoms' 
import { CSVLink } from "react-csv"; 
 

export const CrudTable = ({ isLoading, modelList }) => { 

  const [data, setData] = useState(null) 
  const [, setSearchText] = useState("") 
  const [, setSearchedColumn] = useState("") 
  const [editingId, setEditingId] = useState("")

  useEffect(() => { 

    console.log(modelList) 
    setData(modelList) 
  }, [isLoading, modelList]) 

  const isEditing = record => {
    return record.id === editingId;
  };
 
  const handleSearch = (selectedKeys, confirm, dataIndex) => { 
    confirm();
    setSearchText(selectedKeys[0]) 
    setSearchedColumn(dataIndex) 
  }; 

  const handleReset = (clearFilters) => { 
    clearFilters(); 
    setSearchText({ searchText: "" }) 
  }; 

  const edit = (id) => {
    setEditingId(id)
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];

      const body = {
          name: row["name"],
          cost_center: row["cost_center"],
          cloud_contact_id: row.cloud_contact?.id,
          github_organization_app_id: row.github_organization_app?.id,
          github_username: row["github_username"],
          easy_vista_ticket_number: row["easy_vista_ticket_number"],
          requestor: row["requestor"],
        }

        editDatabaseRecord(newData, id, body)
    });
  }

  const cancel = () => {
      setEditingId("")
  };

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/github_copilot_users", body)
      .then(resp => {
        console.log("Added a new github copilot user record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a github copilot user!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this github copilot user  record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);

    api.put("/github_copilot_users/" + id, body)
      .then(resp => {
        console.log("Updated a github copilot user record!")
        console.log(resp.status)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this github copilot user record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      
      const body = {
        name: row["name"],
        cost_center: row["cost_center"],
        cloud_contact_id: row.cloud_contact?.id,
        github_organization_app_id: row.github_organization_app?.id,
        github_username: row["github_username"],
        easy_vista_ticket_number: row["easy_vista_ticket_number"],
        requestor: row["requestor"]
      }
      addDatabaseRecord([...data], body, form)
    })
  }


  const handleDelete = (id) => {
    api.delete("/github_copilot_users/" + id)
      .then(resp => {
        alert("The github copilot has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "5%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      sorter:  (rowA, rowB) => { 
        switch (col.dataIndex) { 
          case "cloud_contact['id']":
            return col.sorter(rowA['cloud_contact_email'], rowB['cloud_contact_email']);
          case "github_organization_app['id']":
            return col.sorter(rowA['github_organization'], rowB['github_organization']);
          default: 
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]); 
        } 
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "cloud_contact['id']":
              return "select_cloud_contact";
            case "github_organization_app['id']":
              return "select_github_organization";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  }); 

  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "cloud_contact['id']":
              return "select_cloud_contact";
            case "github_organization_app['id']":
              return "select_github_organization";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => { 
    return data.map(githubCopilotUsers => { 
      return { 
        cost_center: githubCopilotUsers.cost_center, 
        github_organization: githubCopilotUsers.github_organization, 
        cloud_contact: githubCopilotUsers.cloud_contact_email, 
        github_username: githubCopilotUsers.github_username, 
        easy_vista_ticket_number: githubCopilotUsers.easy_vista_ticket_number, 
        requestor: githubCopilotUsers.requestor 

      } 
    }) 
  } 
 
  return ( 
    <> 
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<> 
      <Button style={{ margin: '3px' }} > 
            {modelList && ( 
              <CSVLink  
                data={CSVDataToDownloadFormat(data)}  
                filename={'githubcopilotusers_' + new Date().toISOString() + '.csv'} 
              > 
                Download CSV 
              </CSVLink> 
            )} 

          </Button> 

      <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            cost_center: "",
            cloud_contact_id: "",
            github_organization_app_id: "",
            github_username: "",
            easy_vista_ticket_number: "",
            requestor: ""
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
      <Table 
        title={() => 'Github Copilot Users Table'}  
          pagination={{ position: 'both' }}  // pagination={false}  
          rowKey="id" 
          size='middle' 
          components={{ 
            body: { 
              row: EditableFormRow, 
              cell: EditableCell 
            } 
          }} 
          bordered 
          dataSource={data} 
          columns={defaultColumns} 
          rowClassName="editable-row" 
        /> 
      </> 
      )} 
    </> 
  ); 
} 