import React from 'react'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import { DashStats } from "../index";
import { CardItem } from "Atoms";

const AuthHome = () => {
  
  const initialCardItems = [
    {
        id: 'awsBill',
        cardTitle: 'Email AWS Zipped Bill',
        buttonUrl: '/cloud_bills/send_zipped_aws_bill',
        color: '#99009A', //purple
        type: 'text',
        buttonTitle: 'Send Report',
        buttonType: 'modal',
        modalType: 'bill'
    },
    {
        id: 'costCenters',
        cardTitle: 'Cost Center Inventory',
        buttonUrl: '/cost_centers/send_report',
        color: '#99009A', //purple
        type: 'text',
        buttonTitle: 'Send Report',
        buttonType: 'modal',
        modalType: 'inventory',
    },
    {
        id: 'createAccount',
        cardTitle: 'Email Database Backup File',
        buttonUrl: '/db_dump/dump_db',
        color: '#99009A', //purple
        type: 'text',
        buttonTitle: 'Send File',
        buttonType: 'modal',
        modalType: 'database'
    },
    {
      id: 'expiredCloudParentCostCenters',
      cardTitle: 'Notify cloud parent with expired cost centers',
      buttonUrl: '/cost_centers/send_expired_cost_centers_report',
      color: '#99009A', //purple
      type: 'text',
      buttonTitle: 'Send Email',
      buttonType: 'other',
      modalType: 'other'
  },
  {
    id: 'listOfTerminatedCloudAccounts',
    cardTitle: 'List of terminated cloud contact accounts',
    buttonUrl: '/cloud_contacts/send_email_report_of_terminated_cloud_parent_owners',
    color: '#99009A', //purple
    type: 'text',
    buttonTitle: 'Send Email',
    buttonType: 'modal',
    modalType: 'terminatedaccounts'
  },
  {
    id: 'terminatedEmailToCloudAccounts',
    cardTitle: 'Action Required emails to terminated cloud accounts',
    buttonUrl: '/cloud_parents/send_email_to_terminated_cloud_accounts',
    color: '#99009A', //purple
    type: 'text',
    buttonTitle: 'Send Email',
    buttonType: 'other',
    modalType: 'other'
  },
  {
    id: 'githubEnterpriseBilling',
    cardTitle: 'Send Github Enterprise Billing file',
    buttonUrl: '/github/send_copilot_xlsx_email',
    color: '#99009A', //purple
    type: 'text',
    buttonTitle: 'Send File',
    buttonType: 'modal',
    modalType: 'githubBilling'
  }
  ]

  function statWidgets(cardItems) {
    return <Row style={{ marginBottom: '15px' }}>
      {
        cardItems && cardItems.map((obj, i) => {
            if (obj.type === 'text') {
              return (
                    <Col md='4' key={i} style={{ marginBottom: '15px' }}>
                        <CardItem obj={obj} />
                    </Col>
                )
            } else {
                return null
            }
          })
        }
      </Row>;
    }


  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 8, offset: 2 }} className="center_headings">
          <h1>Welcome to the IGT Cloud Center of Excellence Admin Portal!</h1>
          <br/>
        </Col>
      </Row>
      <hr/>
      <Row lg={12}>
        <h2>Quick Utilities</h2>
      </Row>
      {statWidgets(initialCardItems)}
      <hr/>
      <Row>
        <h2>Statistics</h2>
        <Col lg={12}>
          <DashStats/>
        </Col>
      </Row>
    </Container>
  )
}

export default AuthHome