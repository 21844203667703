import React from 'react'
import {
  Input,
  FormGroup
} from 'reactstrap'

const CostCenterFilters = ({ onChangeFilter }) => {
  return (
    <tr>
      <td>
        <FormGroup>
          <Input
            type='text'
            name='Identifier'
            id='by_identifier'
            placeholder='Identifier'
            onChange={onChangeFilter}
          />
        </FormGroup>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        <FormGroup>
          <Input
            name='Date'
            id='created_date'
            type="select"
            onChange={onChangeFilter}
          >
            <option value='All'>All</option>
            <option value='30'>30</option>
            <option value='60'>60</option>
            <option value='90'>90</option>
          </Input>
        </FormGroup>
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  )
}

export default CostCenterFilters