import React, {useState} from 'react'
import moment from 'moment'
import {DeleteIcon, EditIcon} from 'Atoms'
import {Link} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import DeleteModal from "./DeleteModal";

const CompanyListItem = ({modelListItem, index}) => {
  const {
    id,
    code,
    country,
    aws_gl,
    gcp_gl,
    azure_gl,
    riOrReno,
    created_at,
    gl_type
  } = modelListItem
  const url = "/auth/edit/companies/" + id

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const toggleModal = () =>  {
    setModalOpen(!modalOpen)
  }

  function editAction() {
    return <Link to={url}><EditIcon/></Link>;
  }

  return (
    <tr key={index}>
      <td>{code}</td>
      <td>{country}</td>
      <td>{aws_gl}</td>
      <td>{gcp_gl}</td>
      <td>{azure_gl}</td>
      <td>{riOrReno}</td>
      <td>{gl_type}</td>
      <td>{created_at !== null ? moment(created_at).format('DD-MMM-yyyy') : ' '}</td>
      <td>{
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret>
            Actions
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>{editAction()}</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={() => toggleModal()}><DeleteIcon/></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      }</td>
      <DeleteModal
        modelListItem={modelListItem}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        modelId={id}
      />
    </tr>
  )
}

export default CompanyListItem