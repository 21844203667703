import React from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

const MembersCreateForm = ({ onFormChange, onSubmit, filterBusinessUnitSelect, filterDepartmentSelect }) => {
  const formItems = [
    {
      name: "Email",
      id: "requesting_principal_user_id",
      type: "text",
      placeholder: "User's Email...",
    },
    {
      name: "Department",
      id: "department",
      type: "select",
      options: filterDepartmentSelect ? filterDepartmentSelect : null,
    },
    {
      name: "If Other, Department",
      id: "other_department",
      type: "text",
      placeholder: "Department..."
    },
    {
      name: "Business Unit",
      id: "business_unit",
      type: "select",
      options: filterBusinessUnitSelect ? filterBusinessUnitSelect : null,
    }
  ]

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Add New Member</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <Form>
            {
              filterDepartmentSelect && filterBusinessUnitSelect && formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onFormChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>

                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        name={obj.name}
                        id={obj.id}
                        type="select"
                        onChange={onFormChange}
                      >
                        {
                          obj.options && obj.options !== null && obj.options.length > 0 && obj.options.map((obj, i) => {
                            return (
                              <option value={obj.name} key={i}>{obj.name}</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default MembersCreateForm