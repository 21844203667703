import React from 'react'
import moment from 'moment'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { api } from 'lib'

const DeleteModal = ({modelListItem, modalOpen, toggleModal, modelId}) => {
  const {
    identifier,
    cloud_contact_email,
    active_at,
    expired_at,
    replacement_cost_center,
    created_at,
  } = modelListItem;


  const onSubmit = () => {
    toggleModal();
    api.delete("/cost_centers/" + modelId)
      .then(resp => {
        alert("The cost center has been deleted!");
        window.location.replace("/auth/view/cost_centers");
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>Do you want to delete this cost center?</ModalHeader>
      <ModalBody>
        <p>You are going to permanently delete the cost center {identifier}</p>
        <p>Do you want to proceed?</p>
        <ul>
          <li>Cost Center: {identifier}</li>
          <li>Contact: {cloud_contact_email}</li>
          <li>Active at: {active_at !== null ? moment(active_at).format('DD-MMM-yyyy') : ' '}</li>
          <li>Created on: {created_at !== null ? moment(created_at).format('DD-MMM-yyyy') : ' '}</li>
          <li>Expired at: {expired_at !== null ? moment(expired_at).format('DD-MMM-yyyy') : ' '}</li>
          <li>Replacement: {replacement_cost_center}</li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onSubmit}>Delete it</Button>{' '}
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal