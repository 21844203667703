import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import ManageConfiguration from "./manageConfiguration";
import CreateConfiguration from "./createConfiguration";

export default ({
  missingReportTags,
  mode,
  handleModeChange,
  updateConfigToDB,
  createConfigToDB,
  deleteConfigToDB
}) => (
    <div >
      <div style={{ display: "flex", marginBottom: "15px"}}>
        {mode === "view" ? (
          <Button
            style={{marginRight: "5px"}}
            variant="secondary"
            onClick={() => handleModeChange("edit")}
          >
            Change to edit Mode
          </Button>
        ) : (
          <Button
            style={{marginRight: "5px"}}
            variant="secondary"
            onClick={() => handleModeChange("view")}
          >
            Change to view mode
          </Button>
        )}
        <div>
          <CreateConfiguration
              createConfigToDB={createConfigToDB}
          />
        </div>
      </div>

      <div>
        <Accordion>
          {missingReportTags &&
            missingReportTags.map((missingReportTag, i) => (
              <div key={i} style={{marginBottom: "5px"}}>
                {mode === "edit" ? (
                  <Accordion.Item eventKey={`${i}`}  >
                    <Accordion.Header>{missingReportTag.printMessage}</Accordion.Header>
                    <Accordion.Body>
                      <ManageConfiguration
                        printMessage={missingReportTag.printMessage}
                        mode="edit"
                        id={missingReportTag.id}
                        termsToIgnore={missingReportTag.termsToIgnore}
                        updateConfigToDB={updateConfigToDB}
                        deleteConfigToDB={deleteConfigToDB}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                ) : (
                  <Accordion.Item eventKey={`${i}`}  >
                    <Accordion.Header>{missingReportTag.printMessage}</Accordion.Header>
                    <Accordion.Body>
                    <ManageConfiguration
                        printMessage={missingReportTag.printMessage}
                        mode="view"
                        id={missingReportTag.id}
                        termsToIgnore={missingReportTag.termsToIgnore}
                        updateConfigToDB={updateConfigToDB}
                        deleteConfigToDB={deleteConfigToDB}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </div>
            ))}
        </Accordion>
      </div>
    </div>
);
