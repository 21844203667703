import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib'
import { columnsData } from "./columnsData";
import { getOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";


const ADD_EDITING_ID = -100

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [isAdd, setIsAdd] = useState(false)
  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };

  const handleAdd = () => {
    if (isAdd) {
      alert("Cancel your last add first!")
      return
    }
    const newData = {
      id: ADD_EDITING_ID,
      email: ""
    };

    setData(data ? [newData, ...data] : [newData])
    setIsAdd(true)
    setEditingId(ADD_EDITING_ID)
  }

  const handleDelete = (id) => {
    api.delete("/cloud_contacts/" + id)
      .then(resp => {
        alert("The cloud contact has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body) => {
    api.post("/cloud_contacts/by_email", body)
      .then(resp => {
        console.log("Added a new cloud contact record!")
        console.log(resp.status)
        console.log(resp.data)

        api.get("/cloud_contacts/" + resp.data.data.id).then((innerResp) => {
              newData.splice(0, 1, { ...innerResp.data.data }); // Add will update the first element!
              setData(newData)
              setEditingId("")
              setIsAdd(false)
            })
            .catch(err => {
              alert("There was a problem getting this cloud contact record after adding the record. Please try again.")
              console.error("API Error: ", err)
            })
      })
      .catch(err => {
        alert("There was a problem adding this cloud contact record. Please try again.")
        console.error("API Error: ", err)
      })

  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {cloud_contact: {
        email: row["email"]
      }}
      addDatabaseRecord(newData, body)   
    });
  }
  
  const cancel = () => {
    if (isAdd) {
      let removedLastAddedData = [...data].slice(1)
      setData(removedLastAddedData)
      setIsAdd(false)
      setEditingId("")
    } else {
      setEditingId("")
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "8%", true)
  ].map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      sorter:  (rowA, rowB) => col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]),
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(cloud_contact => {
      return {
        name: cloud_contact.name,
        email: cloud_contact.email,
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<><Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
        Add a row
      </Button>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'cloudcontact_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
        
        <Table
          pagination={{ position: 'both' }}  // pagination={false} 
       
         title={() => 'Cloud contact Table'}

          rowKey="id"
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          size='middle'
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}