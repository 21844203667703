import React from "react";
import EditCustomer from "./editCustomer";

export default ({
  customers,
  updateCustomerToDB,
  createCustomerToDB,
  deleteCustomerToDB
}) => {
  return (
    <div>
      <div>
        <h3>List of Customers</h3>
      </div>
      <EditCustomer
        customers={customers}
        updateCustomerToDB={updateCustomerToDB}
        createCustomerToDB={createCustomerToDB}
        deleteCustomerToDB={deleteCustomerToDB}
      />
    </div>
  );
};
 