import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Routes } from 'Routes'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'; 


ReactDOM.render(
  // <React.StrictMode>
    <Routes />,
  // {/* </React.StrictMode>, */}
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
