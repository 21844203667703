import React from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { Wysiwyg } from 'Atoms'

const GlossariesForm = ({ onFormChange, onEditorFormChange, onSubmit, formData }) => {
  const formItems = [
    {
      name: "Term",
      id: "term",
      type: "text",
      placeholder: "Please enter your term here..."
    },
    {
      name: "Description",
      id: "description",
      type: "wysiwyg",
      placeholder: "Please enter your description here..."
    },
    {
      name: "Category",
      id: "category",
      type: "text",
      placeholder: "Please enter your category here..."
    }
  ]

  return(
    <Container fluid>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onFormChange}
                      />
                    </FormGroup>
                  )
                } else {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Wysiwyg
                        id={obj.id}
                        value={formData[obj.id]}
                        onChange={onEditorFormChange}
                        init={{
                          height: 500,
                          paste_data_images: true
                        }}
                      />
                    </FormGroup>
                  )
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default GlossariesForm