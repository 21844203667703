import React from "react"

export const addColumnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
  },
  {
    title: "Company Code",
    dataIndex: "companies['id']",
    width: "7%",
    editable: true,
    render: (text, record) => {
      return <>{record["company_code"]}</>
    }
  },
  {
    title: "Owner",
    dataIndex: "cloud_contact['id']",
    width: "13%",
    editable: true,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    width: "6%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true
  },
  {
    title: "Department",
    dataIndex: "departments['id']",
    width: "13%",
    render: (text, record) => {
      return <>{record["department_name"]}</>
    },
    editable: true
  },


  {
    title: "Active on",
    dataIndex: "active_at",
    width: "8%",
    editable: true
  },
  {
    title: "Expired on",
    dataIndex: "expired_at",
    width: "8%",
    editable: true
  },
  {
    title: "Replacement Cost Center",
    dataIndex: "new_cost_center['id']",
    width: "6.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_cost_center"]}</>
    }
  },
  {
    title: "Backup CC",
    dataIndex: "alt_cost_center['id']",
    width: "7.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["alternate_cost_center"]}</>
    }
  },
  {
    title: "Created on",
    editable: false,
    dataIndex: "created_at",
    width: "6%",
  }
];
