import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { App } from 'Environments'
import { Switch, PublicRoute } from 'Atoms'
import { useIsLoggedIn } from 'CustomHooks'

const Routes = () => {
  const isLoggedIn = useIsLoggedIn()
  
  return (
    <BrowserRouter>
      <Switch>
          <PublicRoute
            path="/"
            props={{isLoggedIn}}
            component={App}
          />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes