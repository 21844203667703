import React, { useState } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Button
} from 'reactstrap'

const CloudBillsUploadForm = (props) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [base64String, setBase64String] = useState(null);


  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result)
      console.log(reader.result)
      setBase64String()
      var cloud_bill = {
        cloud_bill: {
          data: reader.result
        }
      }
      api.post("/cloud_bills/upload", cloud_bill)
      .then(resp => {
        alert("Your new data has been submitted!")
      })
      .catch(err => {
        alert("There was a problem submitting this workbook. Please try again.")
        console.error("API Error: ", err)
      })
    };
    reader.onerror = error => reject(error);
  });

  const submitForm = () => {
    let formData = selectedFile
    console.log(formData)
    ; (async () => {
      await toBase64(formData);
      console.log("Base64: " + base64String)
    })();

  };

  return (

    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Upload Cloud Billing</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/cloud_bills">Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <Form>
            <br />
            <div className="file-uploader">
              <Input
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </div>
            <br />
            <Button color="primary" onClick={submitForm}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CloudBillsUploadForm