import React from 'react'
import {
  Switch,
  PublicRoute
} from 'Atoms'
import {
  Login,
  Logout,
  UnauthHome,
  UnauthUpdateMember
} from 'Organisms'
import { Redirect } from 'react-router-dom'

const Unauth = (props) => {

  const { isLoggedIn } = props
  return (
    <div>
      <Switch>
        {
          isLoggedIn && <Redirect from="/unauth/home" to="/auth/home" />
        }
        <PublicRoute
          exact
          path="/unauth/home"
          component={UnauthHome}
        />
        <PublicRoute
          path="/unauth/login"
          component={Login}
        />
        <PublicRoute
          path="/unauth/logout"
          component={Logout}
        />
        <PublicRoute
          path="/unauth/update-member"
          props={props}
          component={UnauthUpdateMember}
        />
      </Switch>
    </div>
  )
}

export default Unauth