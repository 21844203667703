import React from 'react'
import { Button } from 'reactstrap'

const GitHubOrganizationAppsItem = ({ modelListItem, index }) => {
  const { id,  name, description, app_id, client_id, team_selection, cost_center, exclude_form, copilot_enabled } = modelListItem
  const team_selection_color = team_selection ? "green" : "red"
  const copilot_selection_color = copilot_enabled ? "green" : "red"
  const exlude_form_color = exclude_form ? "green" : "red"
  return (
    <tr key={index}>
      <td>{name}</td>
      <td>{description}</td>
      <td>{app_id}</td>
      <td>{client_id}</td>
      <td><span style={{color: team_selection_color}}>{team_selection ? "Yes" : "No"}</span></td>
      <td><span style={{color: exlude_form_color}}>{exclude_form ? "Yes" : "No"}</span></td>
      <td><span style={{color: copilot_selection_color}}>{copilot_enabled ? "Yes" : "No"}</span></td>
      <td>{cost_center}</td>
      <td><Button href={"/auth/edit/github_organization_apps/" + id}>Edit</Button></td>
    
    </tr>
  )
}

export default GitHubOrganizationAppsItem