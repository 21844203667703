import React from 'react'
import { Wysiwyg } from 'Atoms'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'

const FaqForm = ({ onFormChange, onEditorFormChange, onSubmit, formData }) => {
  const formItems = [
    {
      name: "Product Name",
      id: "name",
      type: "text",
      placeholder: "Please enter the product name..."
    },
    {
      name: "Product Id",
      id: "identifier",
      type: "text",
      placeholder: "Please enter the service catalog product id here..."
    },
    {
      name: "Product Description",
      id: "description",
      type: "wysiwyg",
      placeholder: "Please enter the description here..."
    }
  ]

  return(
    <Container fluid>
      
    <Row>
      <Col lg={{ size: 6, offset: 2}}>
        <h1 className="text-center">Create a Service Catalog Product</h1>
      </Col>
      <Col lg="3">
        <Button href="/auth/view/service_catalog_products">Go Back</Button>
      </Col>
    </Row>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onFormChange}
                      />
                    </FormGroup>
                  )
                } else {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Wysiwyg
                        id={obj.id}
                        value={formData[obj.id]}
                        onChange={onEditorFormChange}
                        init={{
                          height: 300,
                          paste_data_images: true
                        }}
                      />
                    </FormGroup>
                  )
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default FaqForm