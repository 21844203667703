// import React from "react"
import {Sorter} from "lib"
export const columnsData = [
  {
    title: "Cloud",
    dataIndex: "provider",
    width: "3%",
    editable: true,
    isSearchable: true,
    sorter: Sorter.TEXT
  },
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Project Name",
    dataIndex: "project_name",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Distribution List",
    dataIndex: "distribution_list",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Account Owner",
    dataIndex: "account_owner",
    width: "3%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_unit",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Department",
    dataIndex: "department",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Cloud Environment",
    dataIndex: "cloud_environment",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "CC/WBS",
    dataIndex: "cost_center",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "In Use?",
    dataIndex: "still_in_use",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Shut Down?",
    dataIndex: "can_be_removed",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Billing Contact",
    dataIndex: "billing_contact",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Security Contact",
    dataIndex: "security_contact",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Operations Contact",
    dataIndex: "operations_contact",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  },
  {
    title: "Project Summary",
    dataIndex: "project_summary",
    sorter: Sorter.TEXT,
    width: "3%",
    editable: true,
    isSearchable: true
  }
];

export const addColumnsData = [
    {
      title: "Cloud",
      dataIndex: "provider",
      width: "3%",
      editable: true
    },
    {
      title: "Identifier",
      dataIndex: "identifier",
      width: "3%",
      editable: true
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      width: "3%",
      editable: true
    },
    {
      title: "Distribution List",
      dataIndex: "distribution_list",
      width: "3%",
      editable: true
    },
    {
      title: "Account Owner",
      dataIndex: "account_owner",
      width: "3%",
      editable: true
    },
    {
      title: "Business Unit",
      dataIndex: "business_unit",
      editable: true,
      width: "3%"
    },
    {
      title: "Department",
      dataIndex: "department",
      editable: true,
      width: "3%"
    },
    {
      title: "Cloud Environment",
      dataIndex: "cloud_environment",
      editable: true,
      width: "3%"
    },
    {
      title: "CC/WBS",
      dataIndex: "cost_center",
      editable: true,
      width: "3%"
    },
    {
      title: "In Use?",
      dataIndex: "still_in_use",
      editable: true,
      width: "3%"
    },
    {
      title: "Shut Down?",
      dataIndex: "can_be_removed",
      editable: true,
      width: "3%"
    },
    {
      title: "Billing Contact",
      dataIndex: "billing_contact",
      editable: true,
      width: "3%"
    },
    {
      title: "Security Contact",
      dataIndex: "security_contact",
      editable: true,
      width: "3%"
    },
    {
      title: "Operations Contact",
      dataIndex: "operations_contact",
      editable: true,
      width: "3%"
    },
    {
      title: "Project Summary",
      dataIndex: "project_summary",
      editable: true,
      width: "3%"
    }
  ];