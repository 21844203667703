import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { useHistory } from 'react-router'

const CreateAzureSubscription = (props) => {
  const history = useHistory()
  const initialFormData = {
    subscription_name: "",
    group_id: "",
    subscription_type: "", 
    cost_center_tag: "",
    owner_tag: "",
    project_name_tag: "",
  }
  const [groupOptions, setGroupOptions] = useState()
  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => {
    api.get('/azure-groups')
      .then(resp => {

        let test = JSON.parse(JSON.stringify(resp.data.data))
    
        let tmp = [<option key={"other"}>-- Select a Management Group --</option>];
        for (let i = 0; i < test.length; i += 1) {
          let option_id = test[i].name;
          let option_name = (test[i].name === "IGT-corp" || test[i].name === "IGT-online") ? test[i].name + " (Production)" : test[i].name;
          tmp.push(<option key={i} id={test[i].name} value={option_id}>{option_name}</option>)
        }
        //special addition for Scotts group to be added as an option
        tmp.push(<option key={tmp.length} id={"mg-igt-plc"} value={"mg-igt-plc"}>{"IGT-PLC (iLottery)"}</option>)
        setGroupOptions(tmp)
      })
      .catch(err => console.error("API Error: ", err))
  }, [])

  const formItems = [
    {
      name: "Azure Subscription Name",
      id: "subscription_name",
      type: "text",
      placeholder: "LOT_ILT_Interface"
    },
    {
      name: "Management Group",
      id: "folder_id",
      type: "select",
    },
    {
      name: "Cost Center Tag",
      id: "cost_center_tag",
      type: "text",
      placeholder: "default-cost-center"
    },
    {
      name: "Owner Tag",
      id: "owner_tag",
      type: "text",
      placeholder: "first.last@igt.com"
    },
    {
      name: "Project Name Tag",
      id: "project_name_tag",
      type: "text",
      placeholder: "default-project-name"
    }
  ]

  const onChange = (e) => {
    let currentFormData = formData
    let new_value = e.target.value.replace(/\s/g, "-")
    currentFormData[e.target.id] = new_value
    setFormData(currentFormData)
  }

  const onFolderChange = (e) => {
    let currentFormData = formData;
    currentFormData['group_id'] = e.target.value;
    if (e.target.value === "IGT-online" || e.target.value === "IGT-corp") {
      currentFormData['subscription_type'] = 'Production';
    } else {
      currentFormData['subscription_type'] = 'DevTest';
    }
    setFormData(currentFormData)
  }

  const onSubmit = () => {
    console.log(formData);
    api.post("/azure-subscriptions/create", formData)
      .then(resp => {
        alert("Your request has been submitted!");
        history.push('/auth/create-azure-subscription');
        window.location.reload(false);
      })
      .catch(err => console.error("API Error: ", err));
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        onChange={onFolderChange}
                      >
                        {groupOptions}
                      </Input>
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CreateAzureSubscription