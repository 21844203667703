import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { api } from 'lib'

const GitHubOrganizationAppsCreateForm = () => {

  const history = useHistory()

  const initialFormData = {
    name: "",
    description: "",
    app_id: "",
    client_id: "",
    private_key: "",
    cost_centers_id: "1",
    team_selection: "False",
    exclude_form: "False",
    copilot_enabled: "False"
  }
  const [formData, setFormData] = useState(initialFormData)
  const [, setCostCenter] = useState(false); // used for confirmation screen - convert id to string
  const [costCentersOptions, setCostCentersOptions] = useState([])


  useEffect(() => {
    api.get('/cost_centers')
    .then(resp => {
      const sorted_data = resp.data.data.sort((a,b) => a.identifier - b.identifier)
      setCostCentersOptions(sorted_data)
      setCostCenter(sorted_data[0].identifier)
    })
    .catch(err => console.error("API Error: ", err))
  }, [])

  const onCostCentersChange = (e) => {
    let currentFormData = formData
    const cost_centers_id = e.target.value
    currentFormData['cost_centers_id'] = cost_centers_id

    costCentersOptions.forEach(cost_center => {
      if (cost_center.id === cost_centers_id)
        setCostCenter(cost_center.identifier)
    })
    setFormData(currentFormData)
  }

  const onSubmit = () => {
    let temp_data = formData
    temp_data["team_selection"] =  formData["team_selection"] === "False" ? false : true;
    temp_data["exclude_form"] =  formData["exclude_form"] === "False" ? false : true;
    temp_data["copilot_enabled"] =  formData["copilot_enabled"] === "False" ? false : true;
    const data = {github_organization_apps: temp_data}
    api.post("/github_organization_apps", data)
    .then(resp => {
      alert("Your new github org has been added!")
      console.log("Submitted")
      history.push('/auth/view/github_organization_apps')
      window.location.reload();
    })
    .catch(err => {
      alert("There was a problem submitting this company. Please try again.")
      console.error("API Error: ", err)
    })
  }

  const onFormChange = (e) => {
    let currentFormData = {...formData}
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const formItems = [
    {
      name: "Name",
      id: "name",
      type: "text",
      placeholder: "The name of the github organization",
    },
    {
      name: "Description",
      id: "description",
      type: "text",
      placeholder: "What is this used for and what is the name of the github application?",
    },
    {
      name: "App ID",
      id: "app_id",
      type: "text",
      placeholder: "The ID of the appliction as seen on GitHub",
    },
    {
      name: "Client ID",
      id: "client_id",
      type: "text",
      placeholder: "The Client ID of the appliction as seen on GitHub",
    },
    {
      name: "Private Key",
      id: "private_key",
      type: "textarea",
      placeholder: "The Private Key Downloaded from GitHub when creating the App",
    },
    {
      name: "Allow Team Selection",
      id: "team_selection",
      type: "select",
      options: [{id: 0, name: 'False', type: 'select_option'}, {id: 1, name: 'True', type: 'select_option'}],
    },
    {
      name: "Hide on Request Portal Form",
      id: "exclude_form",
      type: "select",
      options: [{id: 0, name: 'False', type: 'select_option'}, {id: 1, name: 'True', type: 'select_option'}],
    },
    {
      name: "Copilot Enabled",
      id: "copilot_enabled",
      type: "select",
      options: [{id: 0, name: 'False', type: 'select_option'}, {id: 1, name: 'True', type: 'select_option'}],
    },
    {
      name: "Cost Center",
      id: "cost_centers_id",
      type: "select_cost_centers",
      options: costCentersOptions
    }
  ]

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Add New Github Organization Application</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onFormChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        name={obj.name}
                        id={obj.id}
                        type="select"
                        onChange={onFormChange}
                      >
                        {
                          obj.options && obj.options !== null && obj.options.length > 0 && obj.options.map((obj, i) => {
                            return (
                              <option value={obj.name} key={i}>{obj.name}</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  )
                } else if (obj.type === 'textarea') {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onFormChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select_cost_centers") { 
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        name={obj.name}
                        id={obj.id}
                        type="select"
                        onChange={onCostCentersChange}
                      >
                        {
                          costCentersOptions && costCentersOptions.map((obj, i) => {
                            return (  
                              <option value={obj.id} key={i}>{obj.identifier} </option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default GitHubOrganizationAppsCreateForm
