import React from 'react'
import {
  Button,
  Container,
  Col,
  Row,
} from 'reactstrap';

import { ListItem } from '.'

const ServiceCatalogProductList = ({ modelList, isLoading }) => {

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <h1 className="text-center">CCoE Service Catalog Products</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/create/service_catalog_products">Create New Product</Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Row>
            {
              modelList && modelList.map((obj, i) => {
                return (
                  <ListItem
                    modelListItem={obj}
                    index={i}
                    key={i}
                  />
                )
              })
            }
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ServiceCatalogProductList