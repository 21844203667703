import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Label
} from 'reactstrap'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router'
import { api } from 'lib'

const UnauthUpdateMember = (props) => {
  const queryString = require('query-string');
  const [departmentSelect, setDepartmentSelect] = useState(null)
  const [businessUnitSelect, setBusinessUnitSelect] = useState(null)

  useEffect(() => {
    api.get('/select_options?by_field=department')
    .then(resp => {
      setDepartmentSelect(resp.data.data.map(i => i.attributes))
    })
    .catch(err => console.error("API Error: ", err))

    api.get('/select_options?by_field=business_unit')
    .then(resp => {
      setBusinessUnitSelect(resp.data.data.map(i => i.attributes))
    })
    .catch(err => console.error("API Error: ", err))
  }, [])

  const location = useLocation()
  const history = useHistory()

  const formItems = [
    {
      name: "Department",
      id: "department",
      type: "select",
      options: departmentSelect ? departmentSelect : null
    },
    {
      name: "If Other, Department",
      id: "other_department",
      type: "text",
      placeholder: "Department..."
    },
    {
      name: "Business Unit",
      id: "business_unit",
      type: "select",
      options: businessUnitSelect ? businessUnitSelect : null
    }
  ]

  const initialFormData = {
    department: "",
    other_department: "",
    business_unit: ""
  }

  const [formData, setFormData] = useState(initialFormData)

  const onChange = (props) = (e) => {
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)

  }

  const onSubmit = (props) = (e) => {
    let body = formData
    if (body["department"] === "Other") {
      body["department"] = body["other_department"]
    }
    delete body.other_department
    let base_url = process.env.REACT_APP_API_ROOT
    let queryParams = queryString.parse(location.search)
    let path = `/members/update-restricted/${queryParams.member_id}?credential=${queryParams.credential}`
    const url = base_url + path
    axios.post(url, body)
    .then(resp => {
      alert("Your request has been successfully submitted!")
      history.push("/redirect")
    })
    .catch(err => {
      console.error("API Error: ", err)
      alert("Something went wrong. Please reach out to the CCOE Team at CCOE.administration@igt.com")
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Update Your Member Details</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <Form>
            {
              departmentSelect && businessUnitSelect && formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>

                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        name={obj.name}
                        id={obj.id}
                        type="select"
                        onChange={onChange}
                      >
                        {
                          obj.options.map((obj, i) => {
                            return (
                              <option value={obj.name} key={i}>{obj.name}</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default UnauthUpdateMember
