  // Note: we need these two methods because our library (react-hook-form) can only accept array of objects while our backend return array of strings.
  export const customerDataToFormInterface = (
    customerData
  ) => {
    return customerData?.map(
      (customerData) => {
        return {
          id: customerData?.id,
          sfdc_ids: customerData?.sfdc_ids?.map((sfdc_id) => {
            return {
              value: sfdc_id,
            };
          }),
          name: customerData?.name
        };
      }
    );
  };
  
  export const formInterfaceToCustomerData = (
    customerData
  ) => {
    return {
      id: customerData.id,
      name: customerData.name,
      sfdc_ids:
      customerData?.sfdc_ids?.map(
          (sfdc_id) => sfdc_id.value
        ) ?? []
    };
  };
  