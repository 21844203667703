import React from 'react' 

const GithubCopilotUsersListItem = ({ modelListItem, index }) => { 
  const {  
    cost_center,  
    github_organization,  
    cloud_contact,  
    github_username,  
    easy_vista_ticket_number,  
    requestor  
  } = modelListItem 

  return ( 
    <tr key={index}> 
      <td>{cost_center}</td> 
      <td>{github_organization}</td> 
      <td>{cloud_contact}</td> 
      <td>{github_username}</td> 
      <td>{easy_vista_ticket_number}</td> 
      <td>{requestor}</td> 
    </tr> 
  ) 
} 

export default GithubCopilotUsersListItem 