import React, { useState } from 'react'
import awsLogo from './aws-card-icon.png'
import azureLogo from './azure-card-icon.png'
import gcpLogo from './gcp-card-icon.png'
import { useModelList } from 'CustomHooks'
import Select from 'react-select'
import {
    Container,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    CardText,
    Row,
    Table,
    Col,
} from 'reactstrap';
import "./AccountDetails.css";

const AccountDetails = (props) => {

    const options = [
        { value: 'CCOE', label: 'CCOE' },
        { value: 'CORP', label: 'Corporate' },
        { value: 'GAM', label: 'Gaming' },
        { value: 'LOT', label: 'Lottery' },
        { value: 'BET', label: 'Digital & Betting' },
        { value: 'CIO', label: 'CIO' }
    ]
    const [buOptions, setBuOptions] = useState(options)
    const [isLoading, setIsLoading] = useState(true)
    const [modelList] = useModelList('cloud_parents', setIsLoading)
    const [awsAccountsCount, gcpProjectsCount, azureSubscriptionCount, awsEnvCount, gcpEnvCount, azureEnvCount] = countAccountTypes(modelList, isLoading)
    
    function onBuChange(event) {
        setBuOptions(event)
    }

    function buFilter(cloud_parent){
        let isBu = false
        if(buOptions.length === 6 ){
            isBu = true
        }else{
            if(cloud_parent.business_unit){
                buOptions.forEach(option => {
                    if(option.value === cloud_parent.business_unit){
                        isBu = true
                    }
                })
            }else{
                isBu = false
            }
        }
        return isBu
    }
    function countAccountTypes(cloud_parents,isLoading) {
        let awsCount = 0
        let gcpCount = 0
        let azureCount= 0 

        let awsEnvCount = {
            production: 0, 
            test: 0,
            development: 0,
            sandbox: 0,
            security: 0,
            shared_services: 0,
        }
        let gcpEnvCount = {
            production: 0, 
            test: 0,
            development: 0,
            sandbox: 0,
        }
        let azureEnvCount={
            production: 0, 
            test: 0,
            development: 0,
            sandboxes: 0,
        }
        if (!isLoading){
            cloud_parents.forEach(parent=>{
                // console.log(parent)
                if (parent.terminated !== true && buFilter(parent)){
                    if (parent.provider_type === 'AwsCloudParent'){
                        awsCount += 1
    
                        if (parent.environment==='production' || parent.environment  === 'root' ){
                            awsEnvCount['production'] += 1
                        }
                        else if (parent.environment==='test'){
                            awsEnvCount['test'] += 1
                        }
                        else if (parent.environment==='development'){
                            awsEnvCount['development'] += 1
                        }
                        else if (parent.environment==='Security'){
                            awsEnvCount['security'] += 1
                        }
                        else if (parent.environment==='sandbox'){
                            awsEnvCount['sandbox'] += 1
                        }
                        else if (parent.environment==='shared-services'){
                            awsEnvCount['shared_services'] += 1
                        }
                    }
                    if (parent.provider_type === 'GcpCloudParent'){
                        gcpCount += 1
    
                        if (parent.environment.toLowerCase()==='production'|| parent.environment.toLowerCase()  === 'sites'|| parent.environment.toLowerCase() === 'env'){
                            gcpEnvCount['production'] += 1
                        }
                        else if (parent.environment.toLowerCase()==='test' || parent.environment.toLowerCase() === 'staging'){
                            gcpEnvCount['test'] += 1
                        }
                         else if (parent.environment.toLowerCase()==='development'){
                             gcpEnvCount['development'] += 1
                        }
                         else if (parent.environment.toLowerCase() ==='sandbox'|| parent.environment === undefined ){
                             gcpEnvCount['sandbox'] += 1
                         }
                        // else{
                        //     gcpEnvCount['missing'] += parent.environment
                        // }
                    }
                    if (parent.provider_type === 'AzureCloudParent'){
                        azureCount += 1

                        if (parent.environment==='production'|| parent.environment.toLowerCase() === 'online'|| parent.environment.toLowerCase()  === 'github'|| parent.environment.toLowerCase()  === 'igt'|| parent.environment.toLowerCase()  === 'landingzones'|| parent.environment.toLowerCase()  === 'platform'){
                            azureEnvCount['production'] += 1

                        }else if (parent.environment === 'test'|| parent.environment.toLowerCase() === 'decommissioned'){
                            azureEnvCount['test'] += 1
                        }
                        else if (parent.environment === 'development'|| parent.environment.toLowerCase() === 'none'){
                            azureEnvCount['development'] += 1
                        }
                        else if (parent.environment === 'sandboxes'|| parent.environment === undefined ){
                            azureEnvCount['sandboxes'] += 1
                        }
                        // else{
                        //     azureEnvCount['missing'] += parent.environment
                        // }
                    }
                }
            })
        }
        console.log(azureEnvCount)
        return [awsCount,gcpCount,azureCount, awsEnvCount, gcpEnvCount, azureEnvCount]
    }
    const cardItems = [
        {
            id: 'azureSubscription',
            image: <img src={azureLogo} style={{width:'240px', height:'140px', }} alt="azure"/>,
            cardStat: [azureSubscriptionCount],
            cardTitle: 'Azure Subscription',
            buttonUrl: '',
            color: '#0d51a1 ', //Dark Blue
            type: 'text'
        },
        {
            id: 'awsCloudAccounts',
            image: <img src={awsLogo} style={{width:'280px', height:'160px',marginLeft:'-20px' }} alt="aws"/>,
            cardStat: [awsAccountsCount],
            cardTitle: 'AWS Accounts',
            buttonUrl: '',
            color: '#1b9ddb ', //Baby Blue
            type: 'text'
        },
        {
            id: 'gcpProjects',
            image: <img src={gcpLogo} style={{width:'250px', height:'150px', marginTop:'10px'}} alt="GCP"/>,
            cardStat: [gcpProjectsCount],
            cardTitle: 'GCP Projects',
            buttonUrl: '', //orange
            color: '#ff6720',//Dark Orange
            type: 'text'
        },
    ]
    function statWidgets() {
        return <Row style={{marginBottom:'15px'}}>
            {
            cardItems && cardItems.map((obj, i) => {
                if (obj.type === 'text') {
                    return (
                        <Col md='4' key={i}>
                            <Card key={i} style={{height: "100%"}}>
                                <CardBody style={{ backgroundColor: obj.color }}>   
                                    <Row>
                                        <Col>
                                            <CardTitle >{obj.image}</CardTitle>
                                            
                                        </Col>
                                        <Col>
                                        <CardText style={{
                                                    textAlign: 'right',
                                                    fontSize: '500%',
                                                    textColor:'black',
                                                }}>{obj.cardStat}</CardText>
                                        </Col>
                                        <Row>
                                            <Col>
                                                <CardTitle style={{ 
                                                    textAlign: 'right',
                                                    color: '#190100',
                                                    fontWeight: "bold", 
                                                    fontSize:"20px"
                                                }}>{obj.cardTitle}</CardTitle>
                                            </Col>
                                        </Row>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    )
                } else {
                    return null
                }
            })
        }
    </Row>;
  }
  function capEnvName(name) {
    if(name === "shared_services"){
        return 'Shared Services'
    }else {
     return  name.charAt(0).toUpperCase() + name.slice(1);
    }
  }
    function providerData() {
        return <Row>
        <br/>
        <br/>
        <Col md='4'>
            <Card style={{ height: "34rem"}}>
                <CardBody>
                    <Row> 
                        <Table style={{fontSize:'23px', fontWeight: 'bold', marginBottom:'5px'}} size="small">
                            <thead>
                                <tr>
                                    <th scope="row">Type</th>
                                    <th scope="row">Count</th>
                                </tr>
                            </thead>
                            {
                            Object.keys(azureEnvCount).map((key, index) => {
                            console.log(key,azureEnvCount[key])
                                return (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#dadada' }}>
                                        <td>{capEnvName(key)}:</td>
                                        <td>{azureEnvCount[key]}</td>
                                    </tr>
                                    )
                                })
                            }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </Table>
                    </Row>
                    <CardFooter style={{
                        backgroundColor:'#0d51a1', 
                        marginBottom:"19px", 
                        padding:"20px",
                        fontWeight: "bold", 
                        fontSize:"18px", 
                        marginTop:'10px'}}>
                        <h6 style={{color:'black', fontSize:"20px"}} src={awsLogo}>Created By: CCOE, LOT, CIO</h6>
                    </CardFooter>
                </CardBody>
            </Card>
        </Col>
        <Col md='4'>
            <Card style={{  height: "34rem" }}>
                <CardBody>
                    <Row> 
                        <Table style={{fontSize:'23px', fontWeight: 'bold'}} striped>
                            <thead>
                                <tr>
                                    <th scope="row">Type</th>
                                    <th scope="row">Count</th>
                                </tr>
                            </thead> 
                            {
                            Object.keys(awsEnvCount).map((key, index) => {
                                    console.log(key,awsEnvCount[key])
                                    return (
                                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#dadada' }}>
                                            <td>{capEnvName(key)}:</td>
                                            <td>{awsEnvCount[key]}</td>
                                        </tr>
                                    )
                                })
                            } 
                            <br/>
                            <br/>
                        </Table>
                    </Row>
                    <CardFooter style={{
                        backgroundColor:'#1b9ddb ',
                        marginTop:'-49px', 
                        padding:"20px", 
                        fontWeight: "bold", 
                        fontSize:"18px"}}>
                        <h6 style={{color:'black', fontSize:"20px"}}>Created By: CCOE</h6>
                    </CardFooter>
                </CardBody>
            </Card>
        </Col>
        <Col md='4'>
            <Card style={{  height: "34rem" }}>
                <CardBody>
                    <Row> 
                        <Table style={{fontSize:'23px', fontWeight: 'bold'}}> 
                            <thead>
                                <tr>
                                    <th scope="row">Row</th>
                                    <th scope="row">Count</th>
                                </tr>
                            </thead>         
                            {
                            Object.keys(gcpEnvCount).map((key, index) => {
                                    console.log(key,gcpEnvCount[key])
                                    return (
                                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#dadada' }}>
                                            <td>{capEnvName(key)}:</td>
                                            <td>{gcpEnvCount[key]}</td>
                                        </tr>
                                    )
                                })
                            }
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </Table>
                    </Row>
                    <CardFooter style={{
                        backgroundColor:'#ff6720', 
                        marginBottom:"18px", 
                        padding:"20px",
                        fontWeight: "bold", 
                        fontSize:"18px"}}>
                        <h6 style={{color:'black', fontSize:"20px"}}>Created By: CCOE</h6>
                    </CardFooter>
                </CardBody>
            </Card>
        </Col>                        
    </Row>;
    }
    return (
        <Container fluid>
            <Col style={{ 
                width:"100%", 
                marginBottom:"10px", 
                padding:"20px",
                fontWeight: "bold", 
                fontSize:"18px"}}>
                <Select 
                    defaultValue={buOptions}
                    closeMenuOnSelect={false}
                    isMulti
                    name="Business Unit Filter"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onBuChange}
                />
            </Col>
            {statWidgets()}
            {providerData()}
        </Container>
    )
}
export default AccountDetails