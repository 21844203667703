import moment from 'moment';

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 * @dev a sort wrapper to handle null/undefined value in the table 
 */
 const sortWrapper = (a, b, sort) => {
    if(a && b) {
      return sort(a, b);
    } else if(a) {
      // That means b is null a, so a will come first.
      return -1;
    } else if(b) {
      // That means a is null b so b will come first.
      return 1;
    }
  // Both a and b has null value so there will be no order change.
  return 0;
 }

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
const numberSort = (a, b) => a - b 
 
/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
const textSort = (a, b) => a?.localeCompare(b)

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
const dateSort = (dateA, dateB) => moment(dateA).diff(moment(dateB));

export default {
    NUMBER: (a, b) => sortWrapper(a, b, numberSort),
    TEXT:  (a, b) => sortWrapper(a, b, textSort),
    DATE: (a, b) => sortWrapper(a, b, dateSort),
};
  