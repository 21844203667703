import React from "react"
import {Sorter} from "lib"
export const columnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Company Code",
    dataIndex: "companies['id']",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["company_code"]}</>
    },
    isSearchable: true
  },
  {
    title: "AWS GL",
    dataIndex: "aws_gl",
    width: "7%",
    editable: false,
    isSearchable: false
  },
  {
    title: "GCL GL",
    dataIndex: "gcp_gl",
    width: "7%",
    editable: false,
    isSearchable: false
  },
  {
    title: "Azure GL",
    dataIndex: "azure_gl",
    width: "7%",
    editable: false,
    isSearchable: false
  },
  {
    title: "Owner",
    dataIndex: "cloud_contact['id']",
    width: "13%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
    isSearchable: true
  }
];

export const addColumnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
  },
  {
    title: "Company Code",
    dataIndex: "companies['id']",
    width: "4%",
    editable: true,
    render: (text, record) => {
      return <>{record["company_code"]}</>
    }
  },
  {
    title: "Owner",
    dataIndex: "cloud_contact['id']",
    width: "13%",
    editable: true,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
  }
];
