import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { columnsData } from "./columnsData";
import { getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")
  const [departmentOnCloudParentsData, setDepartmentOnCloudParentsData] = useState([])

  useEffect(() => {
    const handleDepartmentOnCloudParents = () => {
      if (!data) return
      let updatedDepartmentOnCloudParents = []
      let uniqueDepartment = {}
      
      for (let idx = 0; idx < data.length ; idx++) {
        if (!uniqueDepartment.hasOwnProperty(data[idx].department)) {
          updatedDepartmentOnCloudParents.push({departmentName: data[idx].department, providerType: data[idx].provider_type})
          uniqueDepartment[data[idx].department] = true
        } 
        
      }
      
      setDepartmentOnCloudParentsData(updatedDepartmentOnCloudParents)
    }

    console.log(modelList)
    setData(modelList)
    handleDepartmentOnCloudParents()
  }, [isLoading, modelList, data])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    })
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "cloud_contact['id']":
            return col.sorter(rowA['cloud_contact_email'], rowB['cloud_contact_email']);
          case "business_units['id']":
            return col.sorter(rowA['business_unit_name'], rowB['business_unit_name']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(cloudParent => {
      return {
        provider_type: cloudParent.provider_type,
        identifier: cloudParent.identifier,
        name: cloudParent.name,
        email: cloudParent.email,
        cloud_contact_email: cloudParent.cloud_contact_email,
        business_unit_name: cloudParent.business_unit_name,
        department: cloudParent.department,
        environment: cloudParent.environment,
        cost_center: cloudParent.cost_center,
      }
    })
  }

  const CSVDataToDownloadFormatUniqueDepartment = (data) => {
    return data.map(cloudParent => {
      return {
        provider_type: cloudParent.providerType,
        department_name: cloudParent.departmentName,
      }
    })
  }
  const CSVDataToDownloadFormatDepartmentNonUnique = (data) => {
    return data.map(cloudParent => {
      return {
        identifier: cloudParent.identifier,
        name: cloudParent.name,
        provider_type: cloudParent.providerType,
        department_name: cloudParent.department,
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'cloudParents_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
          <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormatUniqueDepartment(departmentOnCloudParentsData)} 
                filename={'unique_deparment_on_cloudparents_' + new Date().toISOString() + '.csv'}
              >
                Get Unique Department and Provider CSV
              </CSVLink>
            )}
          </Button>
          <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormatDepartmentNonUnique(data.reduce((accumulator, cloudParent) => {
                  if (cloudParent.department) {
                    accumulator.push({
                      identifier: cloudParent.identifier,
                      name: cloudParent.name,
                      department: cloudParent.department,
                      providerType: cloudParent.provider_type
                    })
                  }
                  return accumulator
                }, []))} 
                filename={'Non_unique_deparment_on_cloudparents_' + new Date().toISOString() + '.csv'}
              >
                Get Department Identifier, and provider type CSV
              </CSVLink>
            )}
          </Button>
      <Table
        title={() => 'Cloud Parents Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}