import { useState } from 'react'

const useOnChangeForm = () => {
  const [formData, setFormData] = useState({})

  const onFormChange = (e) => {
    let currentFormData = {...formData}
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onEditorFormChange = (content, editor) => {
    let currentFormData = {...formData}
    currentFormData[editor.id] = content
    setFormData(currentFormData)
  }

  const setInitialFormData = (data) => {
    let currentFormData = {...formData}
    currentFormData = {...data}
    setFormData(currentFormData)
  }

  return [formData, onFormChange, onEditorFormChange, setInitialFormData]

}

export default useOnChangeForm