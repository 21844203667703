import { useState } from "react";
import React from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ManageCustomer from "../manageCustomer";
import './CreateCustomer.css';

function CreateCustomer({
    createCustomerToDB,
}) {
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(!show);
  }

  const handleClose = () => setShow(false);

  return (
    <>
      <Button className="me-2" onClick={() => handleShow()}>
        Create a new customer
      </Button>

      <Modal
       
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>Create a new customer</Modal.Title>
          <Button variant="secondary" onClick={handleClose}>X</Button>

        </Modal.Header>
        <div >
          <Modal.Body>
            <ManageCustomer
              mode="create"
              handleShow={handleShow}
              createCustomerToDB={createCustomerToDB}
            />

          </Modal.Body>
      </div>
      </Modal>
    </>
  );
}



export default CreateCustomer;
