import React from 'react'
import {
  CheckMark,
  XMark
} from 'Atoms'
import ToogleSwitch from "./ToogleSwitch"

const MembersListItem = ({ modelListItem, index }) => {
  const { id,  name, email, department, business_unit, team, teams_included, dl_included, blacklist } = modelListItem
  return (
    <tr key={index}>
      <td>{name}</td>
      <td>{email}</td>
      <td>{department}</td>
      <td>{business_unit}</td>
      <td>{team}</td>
      <td>{teams_included ? <CheckMark /> : <XMark />}</td>
      <td>{dl_included ? <CheckMark /> : <XMark />}</td>
      <td><ToogleSwitch blacklist={blacklist} id={id} /></td>
    
    </tr>
  )
}

export default MembersListItem