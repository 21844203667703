import React from 'react'
import { EditIcon } from 'Atoms'
import { Link } from 'react-router-dom';

const FaqsListItem = ({ modelListItem, index }) => {
  const { id, question, category, answer } = modelListItem
  const url = "/auth/edit/faqs/" + id
  return (
    <tr key={index}>
      <td>{question}</td>
      <td dangerouslySetInnerHTML={{ __html: answer }}></td>
      <td>{category}</td>
      <td><Link to={url}><EditIcon /></Link></td>
    </tr>
  )
}

export default FaqsListItem