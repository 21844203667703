import React, {useState} from 'react'
import {Button, Form, FormGroup, Input, Label, Row} from "reactstrap";
import InputHeader from './InputHeader'
import {api} from 'lib'

const PostTab = ({inputValues, setInputValues}) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [base64String, setBase64String] = useState(null);

    const onChangeFilter = (e) => {
        let currentFilterData = {...inputValues}
        currentFilterData[e.target.id] = e.target.id === "provider" ? e.target.value : e.target.checked
        setInputValues(currentFilterData)
        console.log(currentFilterData)
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
            console.log(reader.result);
            setBase64String(reader.result);
            var cloud_bill = {
                cloud_bill: {
                    data: reader.result,
                    input: inputValues
                }
            };
            console.log(cloud_bill);
            api.post("/cloud_bills/parse_pre_ap", cloud_bill)
                .then(resp => {
                    alert("Your new data has been submitted!")
                })
                .catch(err => {
                    alert("There was a problem submitting this workbook. Please try again.")
                    console.error("API Error: ", err)
                })
        };
        reader.onerror = error => reject(error);
    });

    const submitForm = () => {
        let formData = selectedFile;
        console.log(formData);
        ;(async () => {
            await toBase64(formData);
            console.log("Base64: " + base64String);
        })();

    };

    const postApFormItems = [
        // You must whitelist these IDs in the CloudBillsController in the API
        {
            name: "Send Nalo/Nagi Invoices",
            id: "send_invoices",
            type: "checkbox",
            tooltip: "Send NALO/NAGI files to the CCOE.Administration DL?"
        },
        {
            name: "Populate Cloud Bills table",
            id: "cloud_bills",
            type: "checkbox",
            tooltip: "Add all PRE AP rows to the Cloud Bills table?"
        },
        {
            name: "Update GCP Big Query",
            id: "big_query",
            type: "checkbox",
            tooltip: "Do you want to push the new rows up to GCP Data Studio/BQ?"
        },
        {
            name: "Send Email to Cost Center Owners",
            id: "email_owners",
            type: "checkbox",
            tooltip: "Do you want to send an email out to the cost center owners? The will all receive an email that contains the cost center and amount they will be billed."
        }
    ]


    return (
        <div>
            <br/>
            <h3>Generate Nalo/NAGI Files from PRE AP Output</h3>
            <br/>
            <Row>
                <FormGroup>
                    <Input
                        name='Provider'
                        id='provider'
                        type="select"
                        onChange={onChangeFilter}
                    >
                        <option>--- Select an option ---</option>
                        <option value='gcp'>GCP</option>
                        <option value='azure'>Azure</option>
                        <option value='aws' disabled>AWS</option>
                    </Input>
                </FormGroup>
            </Row>
            <Form>
                <br/>
                <div className="file-uploader">
                    <Input
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                </div>
                <br/>
                {
                    postApFormItems && postApFormItems.map((obj, i) => {
                        if (obj.type === "checkbox") {
                            return (
                                <FormGroup key={i} check>
                                    <Label for={obj.id} check>
                                        <Input id={obj.id}
                                               type="checkbox"
                                               onChange={onChangeFilter}
                                        />
                                        {' '}
                                        <InputHeader
                                            id={obj.id}
                                            inputText={obj.name}
                                            tooltipText={obj.tooltip}
                                        />
                                    </Label>
                                </FormGroup>
                            )
                        } else {
                            return null;
                        }
                    })
                }
                <Button color="primary" onClick={submitForm}>Submit</Button>
            </Form>
        </div>
    )
}

export default PostTab