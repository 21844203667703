import React, { useEffect, useState, useCallback } from 'react'
import { api } from 'lib'
import Presenter from "./presenter";
import { useModelList } from 'CustomHooks'
import _ from "lodash";


const Customers = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [modelList] = useModelList('customers', setIsLoading)
  const [customers, setCustomers] = useState([])
 
  useEffect(() => {
    setCustomers(modelList)
  }, [modelList])

  // API call logics 
  const updateCustomerToDB = useCallback((
    id,
    prevName,
    customerData
  ) => {
    if (
      isLoading ||
      !modelList ||
      !prevName ||
      !customerData ||
      !id
    ) {
      return null;
    }

    const customerToUpdateIdx =
      customers.findIndex(
        (customer) => customer.name === prevName
      );

    let currentCustomers = customers;
    currentCustomers[customerToUpdateIdx] = customerData;

    setCustomers((prevSfdc_ids) => [...currentCustomers])

    try {
      api.put("/customers/" + id, {
                            sfdc_ids: JSON.stringify(customerData.sfdc_ids), 
                            name: customerData.name
                          })
      alert(`Successfully updated customer: ${prevName}!`);
    } catch (err) {
      alert("There was a problem updating this customer record. Please try again.")
      console.error("API Error: ", err)
    }
  }, [customers, isLoading, modelList]);

  const createCustomerToDB = useCallback((
    newCustomerData
  ) => {

    if (!customers || !newCustomerData) {
      return null
    }

    if (
      _.find(customers, (customer) => {
        return customer.name === newCustomerData.name;
      })
    ) {
      return false; // Customer with the same customer name exists
    }

    try {
      api.post("/customers/", {
                            sfdc_ids: JSON.stringify(newCustomerData.sfdc_ids), 
                            name: newCustomerData.name
                          }).then((res) => {
                            newCustomerData.id = res?.data?.data?.id
                            setCustomers((prevSfdc_ids) => [...customers, newCustomerData])
                          })
      alert(`Successfully added customer: ${newCustomerData.name}!`);
    } catch (err) {
      alert("There was a problem adding this customer. Please try again.")
      console.error("API Error: ", err)
    }
    return true 

  }, [customers])

  const deleteCustomerToDB = useCallback((
    id,
    name
  ) => {

    if (!customers || !id) {
      return null;
    }

    var deletedCustomers = _.remove(
      customers,
      function (customer) {
        return customer.id !== id;
      }
    );
    
    try {
      api.delete("/customers/" + id)
      alert(`Successfully removed customer with id: ${id} and name: ${name}!`);
    } catch (err) {
      alert("There was a problem deleting this customer record. Please try again.")
      console.error("API Error: ", err)
    }

    setCustomers((prevSfdc_ids) => [...deletedCustomers])
  
  }, [customers]);

  console.log(customers)
  // to do add empty logics below!
  return (  <Presenter
                customers={customers} 
                updateCustomerToDB={updateCustomerToDB} 
                createCustomerToDB={createCustomerToDB}
                deleteCustomerToDB={deleteCustomerToDB}
  /> )
}

export default Customers;
 
