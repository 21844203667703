import React, { useState } from 'react'
import {
  Dropdown, 
  DropdownItem, 
  DropdownToggle, 
  DropdownMenu,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
} from 'reactstrap'

const NavBar = (props) => {
  const { isLoggedIn } = props

  const [ deploymentDropdownOpen, setDeploymentDropdownOpen ] = useState(false)
  const toggleDeployment = () => setDeploymentDropdownOpen(!deploymentDropdownOpen)

  const [ crudDropdownOpen, setCrudDropdownOpen ] = useState(false)
  const toggleCrud = () => setCrudDropdownOpen(!crudDropdownOpen)

  const [ memberDropdownOpen, setMemberDropdownOpen ] = useState(false)
  const toggleMember = () => setMemberDropdownOpen(!memberDropdownOpen)

  const [ serviceDropdownOpen, setServiceDropdownOpen ] = useState(false)
  const toggleService = () => setServiceDropdownOpen(!serviceDropdownOpen)

  const [ informationDropdownOpen, setInformationDropdownOpen ] = useState(false)
  const toggleInformation = () => setInformationDropdownOpen(!informationDropdownOpen)

  const [ dcoeDropdownOpen, setDcoeDropdownOpen ] = useState(false)
  const toggleDcoe = () => setDcoeDropdownOpen(!dcoeDropdownOpen)

  return(
    <Navbar color="light" light expand="md" style={{paddingLeft: "2rem"}}>
      <NavbarBrand href="/">CCOE Admin Portal</NavbarBrand>
      <Nav className="me-auto" navbar>
        {
          isLoggedIn ?
          <>
            <Dropdown nav isOpen={memberDropdownOpen} toggle={toggleMember}>
              <DropdownToggle caret nav>
                Members
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/auth/view/members">Members</DropdownItem>
                <DropdownItem href="/auth/view/cloud_certifications">Cloud Certifications</DropdownItem>
                <DropdownItem href="/auth/membership-requests">Membership Requests</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={deploymentDropdownOpen} toggle={toggleDeployment}>
              <DropdownToggle caret nav>
                Deployments
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/auth/view/cloud_contacts">Cloud Contacts</DropdownItem>
                <DropdownItem href="/auth/view/cost_centers">Cost Centers</DropdownItem>
                <DropdownItem href="/auth/view/sap_cost_centers">SAP Cost Centers</DropdownItem>
                <DropdownItem href="/auth/view/companies">Companies</DropdownItem>
                <DropdownItem href="/auth/view/cost_center_networks">Cost Center Networks</DropdownItem>
                <DropdownItem href="/auth/view/departments">Departments</DropdownItem>
                <DropdownItem href="/auth/view/business_units">Business Units</DropdownItem>
                <DropdownItem href="/auth/view/cloud_parents">Cloud Parents</DropdownItem>
                <DropdownItem href="/auth/view/virtual_machines">Virtual Machines</DropdownItem>
                <DropdownItem href="/auth/view/cloud_projects">Cloud Projects</DropdownItem>
                <DropdownItem href="/auth/view/cloud_bills">Cloud Billing</DropdownItem>
                <DropdownItem href="/auth/view/cloud_survey_responses">Cloud Survey Responses</DropdownItem>
                <DropdownItem href="/auth/view/github_organization_apps">GitHub Apps</DropdownItem>
                <DropdownItem href="/auth/view/project_ids">Project IDs</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={crudDropdownOpen} toggle={toggleCrud}>
              <DropdownToggle caret nav>
                CRUD
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/auth/crud/cloud_contacts">Cloud Contacts</DropdownItem>
                <DropdownItem href="/auth/crud/cost_centers">Cost Centers</DropdownItem>
                <DropdownItem href="/auth/crud/companies">Companies</DropdownItem>
                <DropdownItem href="/auth/crud/cost_center_networks">Cost Center Networks</DropdownItem>
                <DropdownItem href="/auth/crud/departments">Departments</DropdownItem>
                <DropdownItem href="/auth/crud/business_units">Business Units</DropdownItem>
                <DropdownItem href="/auth/crud/cloud_bills">Cloud Bills</DropdownItem>
                <DropdownItem href="/auth/crud/cloud_certifications">Cloud Certifications</DropdownItem>
                <DropdownItem href="/auth/crud/cloud_survey_responses">Cloud Survey Responses</DropdownItem>
                <DropdownItem href="/auth/crud/sap_cost_centers">SAP Cost Center</DropdownItem>
                <DropdownItem href="/auth/crud/cloud_parents">Cloud Parents</DropdownItem>
                <DropdownItem href="/auth/crud/members">Members</DropdownItem>
                <DropdownItem href="/auth/crud/virtual_machines">Virtual Machines</DropdownItem>
                <DropdownItem href="/auth/crud/github_copilot_users">Github Copilot Users</DropdownItem>
                <DropdownItem href="/auth/crud/project_ids">Project IDs</DropdownItem>
                <DropdownItem href="/auth/crud/microsoft_approvals">Microsoft Approvals</DropdownItem>
                <DropdownItem href="/auth/customers">Customers</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={serviceDropdownOpen} toggle={toggleService}>
              <DropdownToggle caret nav>
                Services
              </DropdownToggle>
              
              <DropdownMenu>
                <DropdownItem header>Cloud Factory</DropdownItem>
                <DropdownItem href="/auth/create-aws-account">Create AWS Account</DropdownItem>
                <DropdownItem href="/auth/create-azure-subscription">Create Azure Subscription</DropdownItem>
                <DropdownItem href="/auth/create-gcp-project">Create GCP Project</DropdownItem>
                <DropdownItem href="/auth/spinco-sg-modification">Modify Spinco SG's</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>Administrative</DropdownItem>
                <DropdownItem href="/auth/view/service_catalog_products">Service Catalog Manager</DropdownItem>
                <DropdownItem href="/auth/billing-automation">Billing Automation</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={informationDropdownOpen} toggle={toggleInformation}>
              <DropdownToggle caret nav>
                Information
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/auth/dash-stats">Statistics</DropdownItem>
                <DropdownItem href="/auth/view/faqs">Faqs</DropdownItem>
                <DropdownItem href="/auth/view/glossaries">Glossary</DropdownItem>
                <DropdownItem href="/auth/active-directory/groups-aws">Active Directory (AWS)</DropdownItem>
                <DropdownItem href="/auth/active-directory/groups-azure">Active Directory (Azure)</DropdownItem>
                <DropdownItem href="/auth/active-directory/groups-gcp">Active Directory (Gcp)</DropdownItem>
                <DropdownItem href="/auth/account-details">Cloud Account Posture</DropdownItem>
                <DropdownItem href="/auth/missing-tags-report">Missing Report Tags</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown nav isOpen={dcoeDropdownOpen} toggle={toggleDcoe}>
              <DropdownToggle caret nav>
              CODE
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem href="/auth/view/dcoe_members">CODE Members</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <NavItem>
              <NavLink href="/unauth/logout">Log Out</NavLink>
            </NavItem>
          </>
          :
          <>
            <NavItem>
              <NavLink href={process.env.REACT_APP_LOGIN_URL}>Login</NavLink>
            </NavItem>
          </>
        } 
      </Nav>
    </Navbar>
  )
}

export default NavBar