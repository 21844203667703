import React, { useState } from 'react'; 
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; 
import { api } from 'lib'; 

function TriggerGcp(props) { 

    const [modal, setModal] = useState(false); 
    const toggle = () => setModal(!modal); 

    const onSubmit = () => { 
        api.post("/cloud_bills/update_dashboard",{}) 
            .then((resp) => { 
                alert("Your new data has been submitted!"); 
                window.location.reload();
            }) 
            .catch((err) => { 
                alert("There was a problem submitting this workbook. Please try again."); 
                console.error("API Error: ", err); 
                
            }); 
    }; 
    const externalCloseBtn = ( 
        <button 
            type="button" 
            className="close" 
            style={{ position: 'absolute', top: '15px', marginTop: '15px', right: '15px' }}
            onClick={toggle} > 
            &times; 
        </button> 
    ); 
             
    return ( 
        <div> 
            <Button color="primary" onClick={toggle} style={{ top: '15px', marginTop: '15px' }}> 
            Load GCP Data 
            </Button> 
            <Modal isOpen={modal} toggle={toggle} external={externalCloseBtn}> 
                <ModalHeader>Are you sure you want to continue?</ModalHeader> 
                <ModalBody> 
                    <b style={{fontWeight:'bold', marginBottom:'10px'}}>Confirm or Cancel!</b>
                    <br /> To proceed, click 'Confirm.' If you have any reservations or would like to reconsider, click 'Cancel.' Your thoughtful approach is crucial in maintaining the integrity and stability of our system. . 
                </ModalBody> 
                <ModalFooter> 
                    <Button color="danger" onClick={onSubmit}> 
                        Confirm 
                    </Button>{' '} 
                    <Button color="secondary" onClick={toggle}> 
                         Cancel 
                    </Button> 
                </ModalFooter> 
            </Modal> 
        </div> 
    ); 
}

export default TriggerGcp; 