import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib'
import { columnsData, addColumnsData } from "./columnsData";
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";


export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };

  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const body = {
        identifier: row["identifier"],
        company_code: row["company_code"],
        account: row["account"],
        date: row.date?.toISOString(),
        business_units_id: row.business_units?.id,
        cost_center_networks_id: row.cost_center_networks?.id,
        provider: row["provider"],
        currency: row["currency"],
        reference: row["reference"],
        amount: row["amount"],
        activity: row["activity"],
        line_item: row["line_item"],
      };
      addDatabaseRecord([...data], body, form)

    })
  }

  const handleDelete = (id) => {
    api.delete("/cloud_bills/" + id)
      .then(resp => {
        alert("The cloud bill has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/cloud_bills/", body)
      .then(resp => {
        console.log("Added a cloud bill record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new cloud bill!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this cloud bill record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/cloud_bills/" + id, body)
      .then(resp => {
        console.log("Updated a cloud bill record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this cloud bill record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        identifier: row["identifier"],
        company_code: row["company_code"],
        account: row["account"],
        date: row.date?.toISOString(),
        business_units_id: row.business_units?.id,
        cost_center_networks_id: row.cost_center_networks?.id,
        provider: row["provider"],
        currency: row["currency"],
        reference: row["reference"],
        amount: row["amount"],
        activity: row["activity"],
        line_item: row["line_item"],
      }
      editDatabaseRecord(newData, id, body)  
    });
  }
  
  const cancel = () => {
    setEditingId("")
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "business_units['id']":
            return col.sorter(rowA['business_unit_name'], rowB['business_unit_name']);
            case "cost_center_networks['id']":
              return col.sorter(rowA['cost_center_network_identifier'], rowB['cost_center_network_identifier']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "date":
              return "date";
            case "business_units['id']":
              return "select_business_unit";
              case "cost_center_networks['id']":
                return "select_cost_center_network";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });

  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "date":
              return "date";
            case "business_units['id']":
              return "select_business_unit";
            case "cost_center_networks['id']":
              return "select_cost_center_network";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(cloudBill => {
      return {
        date: cloudBill.date,
        provider: cloudBill.provider,
        company_code: cloudBill.company_code,
        currency: cloudBill.currency,
        reference: cloudBill.reference,
        account: cloudBill.account,
        identifier: cloudBill.identifier,
        amount: cloudBill.amount,
        business_unit_name: cloudBill.business_unit_name,
        cost_center_network_identifier: cloudBill.cost_center_network_identifier,
        line_item: cloudBill.line_item, 
        activity: cloudBill.activity
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'cloudbill_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
      <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            identifier: "",
            business_units_id: "",
            cost_center_networks_id: "",
            company_code: "",
            date: "",
            provider: "",
            currency: "",
            reference: "",
            account: "",
            amount: "",
            line_item: "",
            activity: ""
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
        <Table
        title={() => 'Cloud Bill Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}