import React from 'react'
import { Route, Redirect } from 'react-router-dom'


const PrivateRoute = ({ props: passedProps, path, component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        path={path}
        render={(routerProps) => {
          if (passedProps.isLoggedIn !== null) {
            if (passedProps.isLoggedIn) {
              return (
                <Component {...routerProps} {...passedProps} />
              )
            } else {
              return (
                <Redirect to="/" />
              )
            }
          }
        }}
      />
    )
}

export default PrivateRoute