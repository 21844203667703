import * as Yup from "yup";
import _ from "lodash";

// form validation rules

const editModeSchema = {
  name: Yup.string()
    .required("Customer name is required"),
    sfdc_ids: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(
          "Value of the sfdc id is required"
        ),
      })
    )
    .min(1, "At least a sfdc id is required")
};

const editModeValidation =
  Yup.object().shape(editModeSchema);

const createModeValidation = Yup.object().shape({..._.cloneDeep(editModeSchema)});

export { editModeValidation, createModeValidation };
