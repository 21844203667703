import {Sorter} from "lib"

export const columnsData = [
{
    title: "Name",
    dataIndex: "name",
    width: "8%",
    editable: false,
    isSearchable: true
},
  {
    title: "Email",
    dataIndex: "email",
    width: "8%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  }
];
