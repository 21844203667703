import React from 'react'
import { IconContext } from 'react-icons'
import { FaTimes } from 'react-icons/fa'

const XMark = () => {
  return (
    <IconContext.Provider value={{ color: "red" }}>
      <div>
        <FaTimes />
      </div>
    </IconContext.Provider>
  )
}

export default XMark