import React from 'react'
import { IconContext } from 'react-icons'
import { FaCheck } from 'react-icons/fa'

const CheckMark = () => {
  return (
    <IconContext.Provider value={{ color: "green" }}>
      <div>
        <FaCheck />
      </div>
    </IconContext.Provider>
  )
}

export default CheckMark
