import React from 'react';

const SapCostCenterListItem = ({modelListItem, index}) => {
  const {
    identifier,
    company_code,
    aws_gl,
    gcp_gl,
    azure_gl,
    cloud_contact_email,
  } = modelListItem

  return (
    <tr key={index}>
      <td>{identifier}</td>
      <td>{company_code}</td>
      <td>{aws_gl}</td>
      <td>{gcp_gl}</td>
      <td>{azure_gl}</td>
      <td>{cloud_contact_email}</td>
    </tr>
  )
}

export default SapCostCenterListItem