import React from "react";
import Presenter from "./presenter";
import { customerDataToFormInterface } from "../utils/dbDataFormUtil";
class EditCustomer extends React.Component {
  state = {
    mode: "view"
  };

  handleModeChange = (newMode) => {
    this.setState({
      mode: newMode,
    });
  };
  render() {
    return (
      <Presenter
        mode={this.state.mode}
        handleModeChange={this.handleModeChange}
        customers={customerDataToFormInterface(
            this.props.customers ? this.props.customers : []
        )}
        updateCustomerToDB={this.props.updateCustomerToDB}
        createCustomerToDB={this.props.createCustomerToDB}
        deleteCustomerToDB={this.props.deleteCustomerToDB}
      />
    );
  }
}

export default EditCustomer;
 