import React from 'react'

const CloudContactListItem = ({ modelListItem, index }) => {
  const { terminated, name, email } = modelListItem
  return (
    <tr key={index}>
      <td>{terminated ? "yes" : "no"}</td>
      <td>{name}</td>
      <td>{email}</td>
    </tr>
  )
}

export default CloudContactListItem