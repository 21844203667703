import React from "react"
import {Sorter} from "lib"

// Note(Dennis): Set the editable to true as
// Because sort feature doesn't work if editable set to false
export const columnsData = [
{
    title: "Cloud",
    dataIndex: "provider_type",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
    },
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Name",
    dataIndex: "name",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Email",
    dataIndex: "email",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Owner",
    dataIndex: "cloud_contact['id']",
    width: "13%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
    isSearchable: true
  },
  {
    title: "Business Unit",
    dataIndex: "business_units['id']",
    sorter: Sorter.TEXT,
    width: "8%",
    render: (text, record) => {
      return <>{record["business_unit_name"]}</>
    },
    editable: true,
    isSearchable: true
  },
  {
    title: "Department",
    dataIndex: "department",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true
  },
  {
    title: "Environment",
    dataIndex: "environment",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true
  },
  {
    title: "Cost Center",
    dataIndex: "cost_center",
    sorter: Sorter.TEXT,
    width: "7.5%",
    editable: true,
    isSearchable: true
  }
];