import React from "react"
import {Sorter} from "lib"
export const columnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    isSearchable: true
  },
  {
    title: "Cost Center Network",
    dataIndex: "cost_center_networks['id']",
    width: "5.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["cost_center_network_identifier"]}</>
    }
  },
  {
    title: "SAP Project Name",
    dataIndex: "sap_project_name",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true
  },
  {
    title: "PPM Project Name",
    dataIndex: "ppm_project_name",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true
  },
  {
    title: "Active At",
    dataIndex: "active_at",
    sorter: Sorter.DATE,
    width: "8%",
    editable: true
  },
  {
    title: "Expired At",
    dataIndex: "expired_at",
    width: "8%",
    sorter: Sorter.DATE,
    editable: true
  },
  {
    title: "Created At",
    editable: false,
    dataIndex: "created_at",
    width: "6%",
  },
  {
    title: "Replacement Project ID",
    dataIndex: "new_project['id']",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_project"]}</>
    },
    isSearchable: true
  },
  {
    title: "End Date",
    editable: true,
    dataIndex: "end_date",
    sorter: Sorter.DATE,
    width: "6%",
  },
  {
    title: "Active?",
    editable: true,
    dataIndex: "is_active",
    sorter: Sorter.TEXT,
    width: "6%",
  },
];

export const addColumnsData = [
  {
    title: "Identifier",
    dataIndex: "identifier",
    width: "7%",
    editable: true,
  },
  {
    title: "Cost Center Network",
    dataIndex: "cost_center_networks['id']",
    width: "5.5%",
    editable: true,
    render: (text, record) => {
      return <>{record["cost_center_network_identifier"]}</>
    }
  },
  {
    title: "SAP Project Name",
    dataIndex: "sap_project_name",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true
  },
  {
    title: "PPM Project Name",
    dataIndex: "ppm_project_name",
    sorter: Sorter.TEXT,
    width: "8%",
    editable: true
  },
  {
    title: "Active At",
    dataIndex: "active_at",
    width: "8%",
    editable: true
  },
  {
    title: "Expired At",
    dataIndex: "expired_at",
    width: "8%",
    editable: true
  },
  {
    title: "Created At",
    editable: false,
    dataIndex: "created_at",
    width: "6%",
  },
  {
    title: "Replacement Project ID",
    dataIndex: "new_project['id']",
    width: "8%",
    editable: true,
    render: (text, record) => {
      return <>{record["replacement_project"]}</>
    }
  },
  {
    title: "End Date",
    editable: true,
    dataIndex: "end_date",
    sorter: Sorter.DATE,
    width: "6%",
  },
  {
    title: "Active?",
    editable: true,
    dataIndex: "is_active",
    sorter: Sorter.TEXT,
    width: "6%",
  },
];
