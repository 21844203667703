import Button from "react-bootstrap/Button";
import React from "react";
import Form from "react-bootstrap/Form";
import { useFieldArray } from "react-hook-form";

export default ({
  control,
  errors,
  register,
  fieldName,
  addButtonText,
  property,
  fieldLabel,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <Form.Group>
      <Form.Label>{fieldLabel}</Form.Label>
      {fields.map((item, index) => {
        return (
          <div key={index}>
            <div style={{ display: "flex" }}>
              <Form.Control
                style={{marginRight: "5px"}}
                {...register(
                  `${fieldName}[${index}].${property}`
                )}
                defaultValue={item[property]}
              />
               <Button variant="danger"  onClick={() => remove(index)}>X</Button>
            </div>
            <Form.Control.Feedback
              type="invalid"
              style={{
                display: "flex",
              }}
            >
              {
                errors?.[fieldName]?.[index]?.[property]
                  ?.message
              }
            </Form.Control.Feedback>
          </div>
        );
      })}
      <Form.Control.Feedback
        type="invalid"
        style={{
          display: "flex",
        }}
      >
        {errors?.[fieldName]?.message}
      </Form.Control.Feedback>

      <div>
        <Button
          type="button"
          variant="outline-primary"
          onClick={() => {
            const newElement = {};
            newElement[property] = "";
            append(newElement);
          }}
        >
          {addButtonText}
        </Button>
      </div>

      <hr />
    </Form.Group>
  );
};
