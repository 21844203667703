import { storage, loginApi } from 'lib'

class Auth {
  async setAuthHeaders (headers) {
    storage.set('authHeaders', headers)
    await this.isLoggedIn()

    return true
  }

  async validateHeaders() {
    const authHeaders = storage.get('authHeaders')

    if(authHeaders) {
      let response = loginApi().get("auth/validate_token", {
        headers: authHeaders,
        transformResponse: (data) => {
          return JSON.parse(data)
        }
      })
      .then(resp => {
        return resp
      })
      .catch(err => {
        console.error("Auth Error: ", err)
        return false
      })

      return response
    } else {
      return false
    }
  }

  async isLoggedIn() {
    let validation = await this.validateHeaders()

    let successful = validation.data ? validation.data.success : validation
   
    storage.set('isLoggedIn', successful)
    storage.set('userEmail', validation?.data?.data?.uid)

    return successful
  }

  getAuthHeaders () {
    try {
      return storage.get('authHeaders')
    } catch (e) {
      return {}
    }
  }

  logOut() {
    storage.remove('authHeaders')
    storage.set('isLoggedIn', false)
    storage.set('userEmail', '')
  }
}

const auth = new Auth()

export default auth