import React from 'react'
import { IconContext } from 'react-icons'
import { FaMicrosoft } from 'react-icons/fa'

const AzureIcon = () => {
    return (
      <IconContext.Provider value={{ color: "#008AD7", size: "3em" }}>
        <div>
          <FaMicrosoft />
        </div>
      </IconContext.Provider>
    )
}

export default AzureIcon