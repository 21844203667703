import React from 'react'
import {
  Container,
  Row,
  Col,
  Table,
  Button
} from 'reactstrap'
import { ListItem } from '.'
import { IgtLoader } from 'Atoms'

const FaqsList = ({ modelList, isLoading }) => {
  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <h1 className="text-center">FAQs Table</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/create/faqs">Add New FAQ</Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
            <IgtLoader />
            :
            <Table hover>
              <colgroup>
                <col span="1" style={{width: '35%'}} />
                <col span="1" style={{width: '55%'}} />
                <col span="1" style={{width: '10%'}} />
              </colgroup>
              <thead className='sticky-header'>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  modelList && modelList.map((member, i) => {
                    return (
                      <ListItem
                        modelListItem={member}
                        key={i}
                        index={i}
                      />
                    )
                  })
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default FaqsList