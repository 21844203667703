const axios = require('axios')

const getRequestHeaders = () => {
  let auth = JSON.parse(sessionStorage.getItem('authHeaders'));
  if (auth) {
    auth.Authorization = "Token token=dd60503fd5a2eac3d38e86237a402ee7" // restrict access token
  }
  return auth;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  headers: getRequestHeaders(),
  transformResponse: (data) => {
    return JSON.parse(data)
  }
})

export default api