import React from 'react'
import {
  Container,
  Row,
  Col,
  Table
} from 'reactstrap'
import { ListItem } from '.'
import { IgtLoader } from 'Atoms'

const CloudProjectsList = ({ modelList, filterDepartmentSelect, filterBusinessUnitSelect, onChangeFilter, isLoading }) => {
  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <h1 className="text-center">Cloud Projects Table</h1>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
            <IgtLoader isLoading={isLoading}/>
            :
            <Table hover>
              <thead className='sticky-header'>
                <tr>
                  <th>Name</th>
                  <th>Default Project</th>
                  <th>Environment</th>
                  <th>IT Org Owner</th>
                  <th>Cloud Form</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {
                  modelList && modelList.map((project, i) => {
                    return (
                      <ListItem
                        modelListItem={project}
                        key={i}
                        index={i}
                      />
                    )
                  })
                }
              </tbody>
            </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CloudProjectsList