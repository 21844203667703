import React, { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Table,
  Button
} from 'reactstrap'
import { api } from 'lib'
import { PermissionSetItem } from './PermissionSetItem'
import { CloudParentItem } from './CloudParentItem'
import { IgtLoader } from 'Atoms'

const ActiveDirectoryGroupsAzure = ({ isLoading }) => {

  const [formData, setFormData] = useState(null)
  const [permissionData, setPermissionData] = useState(null)
  const [dormantSSOGroups, setDormantSSOGroups] = useState(null)
  const [dormantGroupsShow, setDormantSSOGroupsShow] = useState(false)

  useEffect(() => {
    api.get('/ad/groups?azure')
      .then(resp => {
        const azure_sso_groups = resp.data.data.filter(obj => obj.name.toLowerCase().includes("app_azure_sso"));
        setFormData(azure_sso_groups.filter(group => group.ad_permission_set_id))
        setDormantSSOGroups(azure_sso_groups.filter(group => group.ad_permission_set_id === null))
      })
      .catch(err => console.error("API Error: ", err))
    api.get('/ad/permission_sets')
      .then(resp => {
        setPermissionData(resp.data.data)
      })
      .catch(err => console.error("API Error: ", err))
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Active Directory Groups (Azure)</h1>
        </Col>
      </Row>
      <Button style={{marginBottom: "13px"}} onClick={() => {
        const tempGroups = formData;
        if (!dormantGroupsShow) {
          setFormData(dormantSSOGroups)
          setDormantSSOGroups(tempGroups)
          setDormantSSOGroupsShow(true)
          return
        } 
        setFormData(dormantSSOGroups)
        setDormantSSOGroups(tempGroups)
        setDormantSSOGroupsShow(false)
        return
      }} >{dormantGroupsShow ?  "Show non-dormant Groups" : "Dormant Groups only"}</Button>
      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader />
              :
              <Table hover>
                <thead>
                  <tr>
                    <th className="w-25">Groups (Azure)</th>
                    <th className="w-25">Members / Owners</th>
                    <th className="w-25">Cloud Parents</th>
                    <th className="w-25">Permission Sets</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    formData && formData.map((obj, i) => {
                      let members = obj.members
                      let owners = obj.owners
                      let permission_set_id = obj['ad_permission_set_id']
                      return (
                        <tr key={i}>
                          <td>{obj.name}</td>
                          <td>
                            <details>
                              <summary>View Members</summary>
                              <ul>
                                {
                                  members && members.map((member, j) => {
                                    return (
                                      <li key={j}>{member.name}</li>
                                    )
                                  })
                                }
                              </ul>
                            </details>
                            <details>
                              <summary>View Owners</summary>
                              <ul>
                                {
                                  owners && owners.map((owner, j) => {
                                    return (
                                      <li key={j}>{owner.name}</li>
                                    )
                                  })
                                }
                              </ul>
                            </details>
                          </td>
                          <td>
                            <CloudParentItem
                              data={obj.cloud_parents}
                              index={i}
                            />
                          </td>
                          <td>
                            <PermissionSetItem
                              id={permission_set_id}
                              data={permissionData}
                              index={i}
                            />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default ActiveDirectoryGroupsAzure