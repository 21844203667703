import { Modal, Button, Table } from 'antd';
import React from "react";
import { api } from 'lib'
import { getAddOperationColumn, EditableFormRow, EditableCell } from "common";
import {addColumnsData} from "./AddCostCenterModalColumnData"


export default class AddCostCenterModal extends React.Component {
  state = {
    loading: false,
    visible: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };


  handleAdd = (form) => {
    form.validateFields((error, row) => {
      const body = {
        identifier: row["identifier"],
        cloud_contact_id: row.cloud_contact?.id,
        company_code: row["company_code"],
        companies_id: row.companies?.id,
        active_at: row.active_at?.toISOString(),
        expired_at: row.expired_at?.toISOString(),
        new_cost_center_id: row.new_cost_center?.id,
        departments_id: row.departments?.id,
        alt_cost_center_id: row.alt_cost_center?.id,
        business_units_id: row.business_units?.id
      }

      api.post("/cost_centers", body)
      .then(resp => {
        console.log("Added a new cost center record!")
        console.log(resp.status)
        
        this.props.updateParentCostCenter(resp.data.data.id)

        alert("Successfully added a new cost center!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this cost center record. Please try again.`)
        console.error("API Error: ", err)
      })

      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false, visible: false });
      }, 3000);

    })

  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {

    const defaultAddColumns = [
      ...addColumnsData,
      getAddOperationColumn(this.handleAdd, "4%")
    ].map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => {
          const checkInput = index => {
            switch (index) {
              case "created_at":
                return "date";
              case "active_at":
                return "date";
              case "expired_at":
                return "date";
              case "cloud_contact['id']":
                return "select_cloud_contact";
              case "new_cost_center['id']":
                return "select_new_cost_center";
              case "alt_cost_center['id']":
                return "select_alt_cost_center";
              case "business_units['id']":
                return "select_business_unit";
              case "departments['id']":
                return "select_department";
              case "companies['id']":
                return "select_companies";
              default:
                return "text";
            }
          };
          return {
            record,
            inputType: checkInput(col.dataIndex),
            dataIndex: col.dataIndex,
            title: col.title,
            editing: true 
          };
        }
      };
    });

    const { visible } = this.state;
    return (
      <div>
        <Button style={{  color: "#1890ff", background: "none", border: "none" }} onClick={this.showModal}>
          + New Cost Center
        </Button>
        <Modal
          width={"100%"}
          visible={visible}
          footer={null}
          title="Add a new cost center"
          onCancel={this.handleCancel}
        > 
         <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            identifier: "",
            companies_id: "",
            business_units_id: "",
            departments_id: "",
            cloud_contact_id: "",
            company_code: "",
            aws_gl: "",
            gcp_gl: "",
            azure_gl: "",
            active_at: "",
            expired_at: "",
            new_cost_center_id: "",
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
        </Modal>
      </div>
    );
  }
}
