import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { api } from 'lib'
import { columnsData } from "./columnsData";
import { getOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {

    console.log(modelList)
    setData(modelList)
  }, [isLoading, modelList, data])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  
  const isEditing = record => {
    return record.id === editingId;
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const handleDelete = (id) => {
    api.delete("/microsoft_approvals/" + id)
      .then(resp => {
        alert("The microsoft approval has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }


  const cancel = () => {
    setEditingId("")
  };

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        status: row["status"],
        approval_id: row["approval_id"],
        title: row["title"],
        created_at: row.created_at?.toISOString(),
        updated_at: row.updated_at?.toISOString()
      }
        editDatabaseRecord(newData, id, body)
    });
  }

  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/microsoft_approvals/" + id, body)
      .then(resp => {
        console.log("Updated a microsoft approval record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this microsoft approval record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            case "updated_at":
              return "date";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });


  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Table
        title={() => 'Microsoft Approvals Table'} 
          pagination={false}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}