import React from 'react'
import ReactJson from 'react-json-view'

export const PermissionSetItem = ({ id, data, index }) => {
  if (id == null || data == null)
    return null
  const modelListItem = (data.find(element => Number(element.id) === id) || {})
  if (modelListItem == null || Object.keys(modelListItem).length === 0)
    return (null)
  const { relay, inline_policy } = modelListItem
  const inline = inline_policy.substring(1, inline_policy.length - 1).replace(/\\/g, '')
  return (
    <details>
      <p><strong>Relay:</strong> {relay}</p>
      {renderInlinePolicy(inline)}
    </details>
  )
}

function renderInlinePolicy(inline) {
  if (inline.length < 5)
    return null
  return <details>
    <summary>Inline Policy</summary>
    <ReactJson src={JSON.parse(inline)} theme="summerfruit:inverted" />
  </details>
}
