import React, {useState} from 'react'
import {Tooltip} from "reactstrap";
import {FaQuestionCircle} from "react-icons/fa";

const InputHeader = ({inputText, tooltipText, id}) => {

  const [tooltip, setTooltip] = useState(false);

  const toggleTooltip = () => {
    setTooltip(!tooltip);
  }

  return (
    <div>
      <p><span id={id}>{inputText} <FaQuestionCircle/>:</span></p>
      <Tooltip placement="right" isOpen={tooltip} target={id} toggle={toggleTooltip}>
        {tooltipText}
      </Tooltip>
    </div>
  )
}

export default InputHeader