import React, {useEffect, useState} from 'react'
import {Button, Col, Container, Row, Table} from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import {api} from 'lib'
import {IgtLoader} from 'Atoms'
import {Filters, ListItem, Modal} from "../CloudBills";

const CloudBillingList = ({onChangeFilter, modelList, isLoading}) => {

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    api.get('/cloud_bills')
      .then(resp => {
        const data = resp.data.data;
        console.log(data)
        if (data.length > 0) {

        }
      })
      .catch(err => console.error("API Error: ", err))
  }, [])


  return (
    <Container fluid>
      <Row>
        <Col lg={{size: 6, offset: 3}}>
          <h1 className="text-center">Cloud Bills Historical Records</h1>
        </Col>
        <Col lg="3">
          <Button color="danger" onClick={toggleModal}>Export Data</Button>
          {' '}
          <Button href="/auth/create/cloud_bills">Upload</Button>
          <Modal
            modal={modal}
            toggleModal={toggleModal}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader isLoading={isLoading}/>
              :
              <Table hover>
                <thead className='sticky-header'>
                <tr>
                  <th>Date</th>
                  <th>Provider</th>
                  <th>Company Code</th>
                  <th>Currency</th>
                  <th>Reference</th>
                  <th>GL Account</th>
                  <th>Identifier</th>
                  <th>Amount</th>
                  <th>Business Unit</th>
                  <th>CC Network</th>
                  <th>Activity</th>
                  <th>Line Item Txt</th>
                </tr>
                </thead>
                <tbody>

                <Filters
                  onChangeFilter={onChangeFilter}
                />
                {
                  modelList && modelList.map((cloudBill, i) => {
                    return (
                      <ListItem
                        modelListItem={cloudBill}
                        key={i}
                        index={i}
                      />
                    )
                  })
                }
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Container>
  )

}

export default CloudBillingList