import React, {useState} from 'react'
import {DeleteIcon, EditIcon} from 'Atoms'
import {Link} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import DeleteModal from "./DeleteModal";

const DepartmentListItem = ({modelListItem, index}) => {
  const {
    id,
    name,
    abbreviation
  } = modelListItem
  const url = "/auth/edit/departments/" + id

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


  const toggleModal = () =>  {
    setModalOpen(!modalOpen)
  }

  function editAction() {
    return <Link to={url}><EditIcon/></Link>;
  }

  return (
    <tr key={index}>
      <td>{name}</td>
      <td>{abbreviation}</td>
      <td>{
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret>
            Actions
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>{editAction()}</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={() => toggleModal()}><DeleteIcon/></DropdownItem>
          </DropdownMenu>
        </Dropdown>
      }</td>
      <DeleteModal
        modelListItem={modelListItem}
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        modelId={id}
      />
    </tr>
  )
}

export default DepartmentListItem