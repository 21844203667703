import React, { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Table,
  Button
} from 'reactstrap'
import { api } from 'lib'
import { PermissionSetItem } from './PermissionSetItem'
import { CloudParentItem } from './CloudParentItem'
import { IgtLoader } from 'Atoms'

const ActiveDirectoryGroupsAws = ({ isLoading }) => {

  const [formData, setFormData] = useState(null)
  const [permissionData, setPermissionData] = useState(null)
  const [dormantSSOGroups, setDormantSSOGroups] = useState(null)
  const [dormantGroupsShow, setDormantSSOGroupsShow] = useState(false)
  const [pimGroups, setPimGroups] = useState(null);
  const [dormantPimGroups, setDormantPimGroups] = useState(null);
  const [pimGroupsShow, setPimGroupsShow] = useState(false);


  useEffect(() => {
    api.get('/ad/groups?aws')
      .then(resp => {
        const allGroups = resp.data.data;
      
        // Separate into active and dormant SSO groups
        setFormData(allGroups.filter(group => group.ad_permission_set_id));
        setDormantSSOGroups(allGroups.filter(group => group.ad_permission_set_id === null));
        
        // Separate into PIM groups
        const pim = allGroups.filter(group => group.name.startsWith('APP_AWS_PIM'));
        setPimGroups(pim.filter(group => group.ad_permission_set_id));
        setDormantPimGroups(pim.filter(group => group.ad_permission_set_id === null));
      })
      .catch(err => console.error("API Error: ", err))
    api.get('/ad/permission_sets')
      .then(resp => {
        setPermissionData(resp.data.data)
      })
      .catch(err => console.error("API Error: ", err))
  }, [])

  const displayedGroups = pimGroupsShow ? (dormantGroupsShow ? dormantPimGroups : pimGroups) : (dormantGroupsShow ? dormantSSOGroups : formData);


  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Active Directory Groups (Aws)</h1>
        </Col>
      </Row>
      <Button style={{marginBottom: "13px", marginRight: "4px"}} onClick={() => {
        const tempGroups = formData;
        if (!dormantGroupsShow) {
          setFormData(dormantSSOGroups)
          setDormantSSOGroups(tempGroups)
          setDormantSSOGroupsShow(true)
          return
        } 
        setFormData(dormantSSOGroups)
        setDormantSSOGroups(tempGroups)
        setDormantSSOGroupsShow(false)
        return
      }} >{dormantGroupsShow ?  "Show non-dormant Groups" : "Dormant Groups only"}</Button>
      <Button style={{ marginBottom: "13px" }} onClick={() => setPimGroupsShow(!pimGroupsShow)}>
           {pimGroupsShow ? "Show Non-PIM Groups" : "Show PIM Groups Only"}
      </Button>

      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader />
              :
              <Table hover>
                <thead>
                  <tr>
                    <th className="w-25">Groups</th>
                    <th className="w-25">Members / Owners</th>
                    <th className="w-25">Cloud Parents</th>
                    <th className="w-25">Permission Sets</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    displayedGroups  && displayedGroups.map((obj, i) => {
                      let members = obj.members
                      let owners = obj.owners
                      let permission_set_id = obj['ad_permission_set_id']
                      return (
                        <tr key={i}>
                          <td>{obj.name}</td>
                          <td>
                            <details>
                              <summary>View Members</summary>
                              <ul>
                                {
                                  members && members.map((member, j) => {
                                    return (
                                      <li key={j}>{member.name}</li>
                                    )
                                  })
                                }
                              </ul>
                            </details>
                            <details>
                              <summary>View Owners</summary>
                              <ul>
                                {
                                  owners && owners.map((owner, j) => {
                                    return (
                                      <li key={j}>{owner.name}</li>
                                    )
                                  })
                                }
                              </ul>
                            </details>
                          </td>
                          <td>
                            <CloudParentItem
                              data={obj.cloud_parents}
                              index={i}
                            />
                          </td>
                          <td>
                            <PermissionSetItem
                              id={permission_set_id}
                              data={permissionData}
                              index={i}
                            />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default ActiveDirectoryGroupsAws