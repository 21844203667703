import React from "react";
import EditConfiguration from "./editConfiguration";

export default ({
  missingReportTags,
  updateConfigToDB,
  createConfigToDB,
  deleteConfigToDB
}) => {
  return (
    <div>
      <div>
        <h3>Terms To Ignore</h3>
      </div>
      <EditConfiguration
        missingReportTags={missingReportTags}
        updateConfigToDB={updateConfigToDB}
        createConfigToDB={createConfigToDB}
        deleteConfigToDB={deleteConfigToDB}
      />
    </div>
  );
};
