import React from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { api } from 'lib'

const DeleteModal = ({modelListItem, modalOpen, toggleModal, modelId}) => {
  const {
    name,
    abbreviation,
  } = modelListItem


  const onSubmit = () => {
    toggleModal();
    api.delete("/business_units/" + modelId)
      .then(resp => {
        alert("The business unit has been deleted!");
        window.location.replace("/auth/view/business_units");
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>Do you want to delete this business unit?</ModalHeader>
      <ModalBody>
        <p>You are going to permanently delete this business unit</p>
        <p>Do you want to proceed?</p>
        <ul>
          <li>Business unit name: {name}</li>
          <li>Abbreviation: {abbreviation}</li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onSubmit}>Delete it</Button>{' '}
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal