import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Table, 
  Button
} from 'reactstrap'
import { ListItem } from '.'
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";
import { api } from 'lib';

const CloudSurveysList = ({ modelList, filterDepartmentSelect, filterBusinessUnitSelect, onChangeFilter, isLoading }) => {

  const [cloudParents, setCloudParents] = useState()

  useEffect(() => {
    api.get('/cloud_parents')
      .then(resp => {
        const test = resp.data.data.find(parent => parent.identifier === "604999198862")
        console.log(test);
        console.log(resp);
        setCloudParents(resp.data.data);
      })
      .catch(err => console.error("API Error: ", err))
  }, []);

  const headers = [
    {label: "Cloud", key: "provider"},
    {label: "Identifier", key: "identifier"},
    {label: "Project Name", key: "project_name"},
    {label: "DL", key: "distribution_list"},
    {label: "Account Owner", key: "account_owner"},
    {label: "Business Unit", key: "business_unit"},
    {label: "Department", key: "department"},
    {label: "Cloud Environment", key: "cloud_environment"},
    {label: "CC/WBS", key: "cost_center"},
    {label: "In Use?", key: "still_in_use"},
    {label: "Shut Down?", key: "can_be_removed"},
    {label: "Billing Contact", key: "billing_contact"},
    {label: "Security Contact", key: "security_contact"},
    {label: "Operations Contact", key: "operations_contact"},
    {label: "Project Summary", key: "project_summary"},
  ]

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3}}>
          <h1 className="text-center">Cloud Survey Responses</h1>
        </Col>
        <Col lg="3" style={{ display: 'flex', flexDirection: 'row' }}>
          <Button style={{ margin: '5px' }} href="/auth/create/cloud_survey_responses">Add New Survey Response</Button>{' '}
          <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={modelList} 
                headers={headers}
                style={{color: 'white'}}
                filename={'cloud_survey_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
         
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
            <IgtLoader isLoading={isLoading}/>
            :
            <div>
              <Table hover>
                <thead className='sticky-header'>
                  <tr>
                    {
                      headers.map((header) => {
                        return (
                          <th>{header.label}</th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    modelList && cloudParents && modelList.map((parent, i) => {
                      return (
                        <ListItem
                          modelListItem={parent}
                          cloudParentItem={cloudParents.find(cp => cp.identifier === parent.identifier)}
                          key={i}
                          index={i}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CloudSurveysList