import React, {useState} from 'react'
import {Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import classnames from 'classnames';
import PreTab from './PreTab'
import PostTab from './PostTab'
import TriggerGcp from './TriggerGcp';

import "react-datepicker/dist/react-datepicker.css";

const BillingAutomation = (props) => {

  const [activeTab, setActiveTab] = useState('1');
  const [inputValues, setInputValues] = useState({});

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{size: 6, offset: 3}}>
          <h1 className="text-center">Billing Automation</h1>
        </Col>
        <Col lg="3">
          <Button href="#" disabled>Upload</Button>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{size: 8, offset: 2}}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '1'})}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                Process an Invoice
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '2'})}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                Finalize Invoice
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === '3'})}
                onClick={() => {
                  toggleTab('3');
                }}
              >
                GCP Trigger
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <PreTab />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <PostTab
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <TriggerGcp/>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  )
}

export default BillingAutomation