import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'

const CompaniesEditForm = ({ isLoading, params }) => {
  const [companyCode, setCompanyCode] = useState(null);
  const [country, setCountry] = useState(null);
  const [awsGl, setAwsGl] = useState(null);
  const [gcpGl, setGcpGl] = useState(null);
  const [azureGl, setAzureGl] = useState(null);
  const [riOrReno, setRiOrReno] = useState(null);
  const [gl_type, setGlType] = useState(null);
  const initialFormData = {
    code: "",
    country: "",
    aws_gl: "",
    gcp_gl: "",
    azure_gl: "",
    riOrReno: "",
    gl_type: ""
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const id = params['modelId']

  useEffect(() => {
    let obj = {}
    api.get('/companies')
      .then(resp => {
        const data = resp.data.data;
        const att = data.find(obj => obj.id === id)
        obj['code'] = att['code']
        setCompanyCode(att['code'])
        obj['country'] = att['country']
        setCountry(att['country'])
        obj['aws_gl'] = att['aws_gl']
        setAwsGl(att['aws_gl'])
        obj['gcp_gl'] = att['gcp_gl']
        setGcpGl(att['gcp_gl'])
        obj['azure_gl'] = att['azure_gl']
        setAzureGl(att['azure_gl'])
        obj['riOrReno'] = att['riOrReno']
        setRiOrReno(att['riOrReno'])
        obj['gl_type'] = att['gl_type']
        setGlType(att['gl_type'])
      })
      .catch(err => console.error("API Error: ", err))

    setFormData(obj)
    // eslint-disable-next-line
  }, [])


  const onChange = (e) => {
    if (e.target.id === 'code')
      setCompanyCode(e.target.value)
    if (e.target.id === 'country')
      setCountry(e.target.value)
    if (e.target.id === 'aws_gl')
      setAwsGl(e.target.value)
    if (e.target.id === 'gcp_gl')
      setGcpGl(e.target.value)
    if (e.target.id === 'azure_gl')
      setAzureGl(e.target.value)
    if (e.target.id === 'riOrReno')
      setRiOrReno(e.target.value)
    if (e.target.id === 'gl_type')
      setGlType(e.target.value)
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onSubmit = () => {
    console.log(formData)

    api.put("/companies/" + id, formData)
      .then(resp => {
        alert("Your company has been updated!");
        console.log("Submitted")
        window.location.replace("/auth/view/companies");
      })
      .catch(err => {
        alert("There was a problem submitting this company. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Company Code",
      id: "code",
      value: companyCode,
      type: "text",
    },
    {
      name: "Location Name",
      id: "country",
      value: country,
      type: "text",
    },
    {
      name: "AWS GL",
      id: "aws_gl",
      type: "text",
      value: awsGl,
      placeholder: ""
    },
    {
      name: "GCP GL",
      id: "gcp_gl",
      type: "text",
      value: gcpGl,
      placeholder: ""
    },
    {
      name: "Azure GL",
      id: "azure_gl",
      type: "text",
      value: azureGl,
      placeholder: ""
    },
    {
      name: "GL Type",
      id: "gl_type",
      type: "text",
      value: gl_type,
      placeholder: ""
    },
    {
      name: "RI or Reno",
      id: "riOrReno",
      type: "text",
      value: riOrReno,
      placeholder: ""
    }
  ]

  
  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Edit Company</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/companies">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={obj.value}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, onSubmit)}
              </Form>
          }
        </Col>
      </Row>
    </Container >
  )
}

function renderModal(modal, toggle, formData, onSubmit) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm Company</ModalHeader>
    <ModalBody>
      Company Code: {formData["code"]} <br />
      Location Name: {formData["country"]} <br />
      AWS GL: {formData["aws_gl"]} <br />
      GCP GL: {formData["gcp_gl"]} <br />
      Azure GL: {formData["azure_gl"]} <br />
      GL Type: {formData["gl_type"]} <br />
      RI or Reno: {formData["riOrReno"]}
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default CompaniesEditForm