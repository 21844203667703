import React, { useState, useEffect } from 'react'
import { meta } from 'lib'
import { Chart } from 'react-google-charts';
import { MembersIcon, AccountsIcon, CostCenterIcon, LightbulbIcon } from 'Atoms'
import {
    Container,
    Card,
    Button,
    CardBody,
    CardFooter,
    CardLink,
    CardTitle,
    CardText,
    Row,
    Col,
} from 'reactstrap';
import { IgtLoader } from 'Atoms'

const DashStats = (props) => {
    const [costCenterCount, setCostCenterCount] = useState(null)
    const [memberCount, setMembersCount] = useState(null)
    const [projectCount, setProjectCount] = useState(null)
    const [deploymentsData, setDeploymentsData] = useState(null) //deployment environments
    const [providersData, setProvidersData] = useState(null) //cloud providers
    const [accountCount, setAccountCount] = useState(null)
    const [typesData, setTypesData] = useState(null) //cloud types

    useEffect(() => {
        meta.get('cloud_parents')
            .then(resp => {
                const meta = resp.data.meta;
                let ccs = meta['valid_cost_center_count'];
                setCostCenterCount(ccs)
                setAccountCount(meta['account_count'])
                setMembersCount(meta['member_count'])
                setProjectCount(meta['project_count'])
                setupPieCharts(meta, setDeploymentsData, setTypesData, setProvidersData)
            })
            .catch(err => console.error('API Error: ', err))

    }, [])

    const cardItems = [
        {
            id: 'teamMembers',
            icon: <MembersIcon />,
            cardStat: [memberCount],
            cardTitle: 'Team Members',
            buttonUrl: '/auth/view/members',
            color: '#4e73df', //blue
            type: 'text'
        },
        {
            id: 'cloudAccounts',
            icon: <AccountsIcon />,
            cardStat: [accountCount],
            cardTitle: 'Cloud Accounts',
            buttonUrl: '/auth/view/cloud_parents',
            color: '#fd7e14', //orange
            type: 'text'
        },
        {
            id: 'validCostCenter',
            icon: <CostCenterIcon />,
            cardStat: [costCenterCount],
            cardTitle: 'Cost Centers',
            buttonUrl: '/auth/view/cost_centers',
            color: '#1cc88a', //green
            type: 'text'
        },
        {
            id: 'projects',
            icon: <LightbulbIcon />,
            cardStat: [projectCount],
            cardTitle: 'Cloud Projects',
            buttonUrl: '/auth/view/cloud_projects', //red
            color: '#e74a3b',
            type: 'text'
        },

    ]

    function statWidgets() {
        return <Row style={{ marginBottom: '15px' }}>
            {
                cardItems && cardItems.map((obj, i) => {
                    if (obj.type === 'text') {
                        return (
                            <Col md='3' key={i}>
                                <Card key={i} >
                                    <CardBody style={{ backgroundColor: obj.color }}>
                                        <Row>
                                            <Col>
                                                <CardTitle>{obj.icon}</CardTitle>
                                            </Col>
                                            <Col>
                                                <CardText style={{
                                                    textAlign: 'right',
                                                    fontSize: '200%'
                                                }}>{obj.cardStat}</CardText>
                                                <CardTitle style={{ color: '#fff',  textAlign: 'right' }}>{obj.cardTitle}</CardTitle>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <CardLink href={obj.buttonUrl}><Button /* style={{ color: obj.color }} */>View Details</Button></CardLink>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    } else {
                        return null
                    }
                })
            }
        </Row>;
    }
    function pieCharts() {
        function deploymentChart() {
            return <Chart style={{ border: '1px solid #ccc' }}
                width={'100%'}
                height={'300px'}
                chartType='PieChart'
                loader={<IgtLoader isLoading='true' />}
                data={deploymentsData}
                options={{
                    title: 'Cloud Deployments',
                    pieSliceText: 'value',
                    is3D: true,
                }}
                rootProps={{ 'data-testid': '1' }}
            />;
        }

        function providersChart() {
            return <Chart style={{ border: '1px solid #ccc' }}
                width={'100%'}
                height={'300px'}
                chartType='PieChart'
                loader={<IgtLoader isLoading='true' />}
                data={providersData}
                options={{
                    title: 'Cloud Providers',
                    pieSliceText: 'value',
                    is3D: true,
                }}
                rootProps={{ 'data-testid': '1' }}
            />;
        }

        function projectTypesChart() {
            return <Chart style={{ border: '1px solid #ccc' }}
                width={'100%'}
                height={'300px'}
                chartType='PieChart'
                loader={<IgtLoader isLoading='true' />}
                data={typesData}
                options={{
                    title: 'Cloud Types',
                    pieSliceText: 'value',
                    is3D: true,
                }}
                rootProps={{ 'data-testid': '1' }}
            />;
        }

        return <Row>
            <Col md={{ size: 4 }}>
                {deploymentChart()}
            </Col>
            <Col md={{ size: 4  }}>
                {providersChart()}
            </Col>
            <Col md={{ size: 4 }}>
                {projectTypesChart()}
            </Col>
        </Row>;
    }

    return (
        <Container fluid>
            {statWidgets()}
            {pieCharts()}
        </Container>
    )
}

function setupPieCharts(meta, setDeploymentsData, setTypesData, setProvidersData) {
    cloudDeployments(meta['cloud_deployments_count'], setDeploymentsData);
    cloudTypes(meta['cloud_form_count'], setTypesData);
    cloudProviders(meta['cloud_provider_count'], setProvidersData);
}

function cloudDeployments(resp, setDeploymentsData) {
    const chartNames = Object.keys(resp);
    const chartValues = Object.values(resp);
    const chartData = [['Name', 'Count']];
    for (let i = 0; i < chartNames.length; i += 1) {
        const name = chartNames[i].toLowerCase();
        const value = chartValues[i];
        chartData.push([name.replace(name[0], name[0].toUpperCase()), value]);
    }
    setDeploymentsData(chartData);
}

function cloudProviders(resp, setProvidersData) {
    const chartNames = Object.keys(resp);
    const chartValues = Object.values(resp);
    const chartData = [['Name', 'Count']];
    for (let i = 0; i < chartNames.length; i += 1) {
        const name = chartNames[i];
        const value = chartValues[i];
        chartData.push([name.replace(name[0], name[0].toUpperCase()), value]);
    }
    setProvidersData(chartData);
}

function cloudTypes(resp, setTypesData) {
    const chartNames = Object.keys(resp);
    const chartValues = Object.values(resp);
    const chartData = [['Name', 'Count']];
    for (let i = 0; i < chartNames.length; i += 1) {
        chartData.push([chartNames[i], chartValues[i]]);
    }
    setTypesData(chartData);
}


export default DashStats