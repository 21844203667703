import { useState } from "react";
import React from 'react';
import Presenter from "./presenter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formInterfaceToConfigData } from "../../utils/dbDataFormUtil";
import {
  editModeValidation,
  createModeValidation,
} from "./validationSchema";
import { useEffect } from "react";

function ManageConfiguration({
  mode,
  handleShow,
  id,
  termsToIgnore,
  printMessage,
  updateConfigToDB,
  createConfigToDB,
  deleteConfigToDB
}) {

  const [printMessageUpdate, setPrintMessageUpdate] = useState(
    printMessage ?? ""
  );

  
  const [
    isDuplicatePrintMessageErr,
    setIsDuplicatePrintMessageErr,
  ] = useState(false);

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(
      mode === "edit"
        ? editModeValidation
        : createModeValidation
    ),
    defaultValues:
      mode === "create"
        ? {}
        : {
            printMessage: printMessage,
            termsToIgnore: termsToIgnore
          },
    mode: "onChange",
  });

  // Watch and update the form values accordingly upon changes on the higher components
  useEffect(() => {
    setPrintMessageUpdate(printMessage ?? "");
    setValue("termsToIgnore", termsToIgnore ?? []);
    console.log(termsToIgnore)
  }, [
    mode,
    id,
    termsToIgnore,
    printMessage,
    setValue
  ]);

  const onSubmit = function (rawFormData, event) {
    event.preventDefault();
    console.log(rawFormData)
    if (isDuplicatePrintMessageErr)
      setIsDuplicatePrintMessageErr(false);

    if (mode === "edit" && updateConfigToDB) {
      rawFormData["id"] = id;
      const missingReportTagsDataForUpdate = rawFormData;
      
      // Add api call here
      updateConfigToDB(
        id,
        printMessageUpdate,
        formInterfaceToConfigData(
          missingReportTagsDataForUpdate
        )
      )

      setPrintMessageUpdate(rawFormData.printMessage)
    }

    if (mode === "create" && createConfigToDB) {
      const configDataForCreate = rawFormData;
      console.log(rawFormData)
      if (
        !createConfigToDB(
          formInterfaceToConfigData(
            configDataForCreate
          )
        )
      ) {
        setIsDuplicatePrintMessageErr(true);
      } else {
        // print message debug is unique!
        if (handleShow) handleShow();
      }
    }


  };

  return (
       <Presenter
      mode={mode}
      isDuplicatePrintMessageErr={isDuplicatePrintMessageErr}
      id={id}
      printMessage={printMessageUpdate}
      onSubmit={onSubmit}
      getValues={getValues}
      handleSubmit={handleSubmit}
      deleteConfigToDB={deleteConfigToDB}
      errors={errors}
      control={control}
      register={register}
    />
  );
}

export default ManageConfiguration;
