import React from 'react' 
import { 
  Container, 
  Row, 
  Col, 
  Table 
} from 'reactstrap' 
import { ListItem } from '.' 
import { IgtLoader } from 'Atoms' 
 
const GithubCopliotList = ({ modelList, isLoading }) => { 
  return ( 
    <Container fluid> 
      <Row> 
        <Col lg={{ size: 6, offset: 3}}> 
          <h1 className="text-center">Github Copliot Table</h1> 
        </Col> 
      </Row> 
      <Row> 
        <Col lg="12"> 
          { 
            isLoading ? 
            <IgtLoader isLoading={isLoading}/> 
            : 
            <Table hover> 
              <thead className='sticky-header'> 
                <tr> 
                  <th>Cost Center</th> 
                  <th>Github Organization</th> 
                  <th>Email</th> 
                  <th>Github Username</th> 
                  <th>Easy Vista Ticket Number</th> 
                  <th>Requestor</th> 
                </tr> 
              </thead> 
              <tbody> 
                { 
                  modelList && modelList.map((parent, i) => { 
                    return ( 
                      <ListItem 
                        modelListItem={parent} 
                        key={i} 
                        index={i} 
                      /> 
                    ) 
                  }) 
                } 
              </tbody> 
            </Table> 
          } 
        </Col> 
      </Row> 
    </Container> 
  ) 
} 

 
 

export default GithubCopliotList 

 