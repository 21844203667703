import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib'
import { columnsData, addColumnsData } from "./columnsData";
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [sapCostCenter, setSapCostCenter] = useState(null)
  const [cloudContacts, setCloudContacts] = useState(null)

  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {
    setData(modelList)
    api.get('/sap_cost_centers').then((resp) => {
      setSapCostCenter( resp.data.data )
    }).catch(err => console.error("API Error: ", err))
    api.get('/cloud_contacts').then((resp) => {
      setCloudContacts( resp.data.data )
    }).catch(err => console.error("API Error: ", err))

  }, [isLoading, modelList])

  const isEditing = record => {
    return record.id === editingId;
  };

  const identifierIsDuplicate = identifier => {
    return data.find(costCenter => costCenter.identifier === identifier) !== undefined
  }

  const getCloudContactSapCostCenterByIdentifier = (cc_identifier) => {
    if (!sapCostCenter) {
      return 
    }
    if (!cloudContacts) {
      return
    }
    const same_identifier_sap_cc_found = sapCostCenter.find(sap_cc => sap_cc.identifier === cc_identifier)
    
    if (same_identifier_sap_cc_found !== undefined) {
      let cloud_contact = cloudContacts.find(cloud_contact => cloud_contact.email === same_identifier_sap_cc_found.cloud_contact_email)
      return cloud_contact.id
    }  

    return null
  }
  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      if (identifierIsDuplicate(row["identifier"])) {
        alert("Duplicate identifier found. Please enter a unique identifier!")
        window.location.reload() 
        return 
      }

      const cloud_contact_id_found = getCloudContactSapCostCenterByIdentifier(row["identifier"])
      
      const body = {
        identifier: row["identifier"],
        cloud_contact_id: cloud_contact_id_found ? cloud_contact_id_found : row.cloud_contact?.id,
        company_code: row["company_code"],
        companies_id: row.companies?.id,
        active_at: row.active_at?.toISOString(),
        expired_at: row.expired_at?.toISOString(),
        new_cost_center_id: row.new_cost_center?.id,
        departments_id: row.departments?.id,
        alt_cost_center_id: row.alt_cost_center?.id,
        business_units_id: row.business_units?.id
      }
      addDatabaseRecord([...data], body, form)
    })
  }

  const handleDelete = (id) => {
    api.delete("/cost_centers/" + id)
      .then(resp => {
        alert("The cost center has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/cost_centers", body)
      .then(resp => {
        console.log("Added a new cost center record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new cost center!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this cost center record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/cost_centers/" + id, body)
      .then(resp => {
        console.log("Updated a cost center record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this cost center record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        identifier: row["identifier"],
        cloud_contact_id: row.cloud_contact?.id ,
        company_code: row["company_code"],
        companies_id: row.companies?.id,
        active_at: row.active_at?.toISOString(),
        expired_at: row.expired_at?.toISOString(),
        new_cost_center_id: row.new_cost_center?.id,
        business_units_id: row.business_units?.id,
        departments_id: row.departments?.id,
        alt_cost_center_id: row.alt_cost_center?.id
      }
        editDatabaseRecord(newData, id, body)
    });
  }
  
  const cancel = () => {
    setEditingId("")
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "cloud_contact['id']":
            return col.sorter(rowA['cloud_contact_email'], rowB['cloud_contact_email']);
          case "new_cost_center['id']":
            return col.sorter(rowA['replacement_cost_center'], rowB['replacement_cost_center']);
          case "alt_cost_center['id']":
            return col.sorter(rowA['alternative_cost_center'], rowB['alternative_cost_center']);
          case "business_units['id']":
            return col.sorter(rowA['business_unit_name'], rowB['business_unit_name']);
          case "departments['id']":
            return col.sorter(rowA['department_name'], rowB['department_name']);
          case "companies['id']":
            return col.sorter(rowA['company_code'], rowB['company_code']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            case "active_at":
              return "date";
            case "expired_at":
              return "date";
            case "cloud_contact['id']":
              return "select_cloud_contact";
            case "new_cost_center['id']":
              return "select_new_cost_center";
            case "alt_cost_center['id']":
              return "select_alt_cost_center";
            case "business_units['id']":
              return "select_business_unit";
            case "departments['id']":
              return "select_department";
            case "companies['id']":
              return "select_companies";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });


  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            case "active_at":
              return "date";
            case "expired_at":
              return "date";
            case "cloud_contact['id']":
              return "select_cloud_contact";
            case "new_cost_center['id']":
              return "select_new_cost_center";
            case "alt_cost_center['id']":
              return "select_alt_cost_center";
            case "business_units['id']":
              return "select_business_unit";
            case "departments['id']":
              return "select_department";
            case "companies['id']":
              return "select_companies";
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(costCenter => {
      return {
        identifier: costCenter.identifier,
        company_code: costCenter.company_code,
        aws_gl: costCenter.aws_gl,
        gcp_gl: costCenter.gcp_gl,
        azure_gl: costCenter.azure_gl,
        cloud_contact_email: costCenter.cloud_contact_email,
        business_unit_name: costCenter.business_unit_name,
        department_name: costCenter.department_name,
        active_at: costCenter.active_at,
        expired_at: costCenter.expired_at,
        replacement_cost_center: costCenter.replacement_cost_center,
        created_at: costCenter.created_at
      }
    })
  }

  const getCSVDataCostCenterWithTerminatedOwner = (data) => {
    return data.filter(costCenter => costCenter.owner_terminated === true)
        .map(costCenter => {
        return {
          identifier: costCenter.identifier,
          company_code: costCenter.company_code,
          aws_gl: costCenter.aws_gl,
          gcp_gl: costCenter.gcp_gl,
          azure_gl: costCenter.azure_gl,
          cloud_contact_email: costCenter.cloud_contact_email,
          business_unit_name: costCenter.business_unit_name,
          department_name: costCenter.department_name,
          active_at: costCenter.active_at,
          expired_at: costCenter.expired_at,
          replacement_cost_center: costCenter.replacement_cost_center,
          owner_terminated: costCenter.owner_terminated,
          created_at: costCenter.created_at
        }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'costcenter_' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
          <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={getCSVDataCostCenterWithTerminatedOwner(data)} 
                filename={'terminated_owner_costcenter_' + new Date().toISOString() + '.csv'}
              >
                Download Cost Center with terminated owner CSV
              </CSVLink>
            )}
          </Button>
      <Table
        title={() => 'Add a Row'} 
          pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={[{
            identifier: "",
            companies_id: "",
            business_units_id: "",
            departments_id: "",
            cloud_contact_id: "",
            company_code: "",
            aws_gl: "",
            gcp_gl: "",
            azure_gl: "",
            active_at: "",
            expired_at: "",
            new_cost_center_id: "",
          }]
        }
          columns={defaultAddColumns}
          rowClassName="editable-row"
        />
      <Table
        title={() => 'Cost Center Table'} 
          pagination={{ position: 'both' }}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}