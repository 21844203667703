import React, { useEffect } from 'react'
import { auth } from 'lib'
import { useLocation, useHistory } from 'react-router'
import queryString from 'query-string'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

const Login = () => {
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
      let newAuthHeaders = queryString.parse(location.search)
      let setAuthHeaders = new Promise(async (resolve, reject) => {
        let success = await auth.setAuthHeaders(newAuthHeaders)

        if (success) {
          resolve()
        } else {
          reject()
        }
      })
      setTimeout(() => {
        setAuthHeaders
        .then(resp => history.push('/'))
        .catch(err => console.error("Login: Something went wrong", err))
      }, 2000)
    }, [history, location])

  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          <h1 className="text-center">Logging you in...</h1>
        </Col>
      </Row>
    </Container>
  )
}

export default Login