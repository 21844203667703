import { useState, useEffect } from 'react'
import { api } from 'lib'

const useApiSelectOptions = () => {
  const [departmentSelect, setDepartmentSelect] = useState(null)
  const [businessUnitSelect, setBusinessUnitSelect] = useState(null)

  useEffect(() => {
    api.get('/select_options?by_field=department')
    .then(resp => {
      setDepartmentSelect(resp.data.data.map(i => i.attributes))
    })
    .catch(err => console.error("API Error: ", err))

    api.get('/select_options?by_field=business_unit')
    .then(resp => {
      setBusinessUnitSelect(resp.data.data.map(i => i.attributes))
    })
    .catch(err => console.error("API Error: ", err))
  }, [])

  return [departmentSelect, businessUnitSelect]
}

export default useApiSelectOptions