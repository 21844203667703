import React from "react";
import Presenter from "./presenter";
import { configDataToFormInterface } from "../utils/dbDataFormUtil";
class EditConfiguration extends React.Component {
  state = {
    mode: "view"
  };

  handleModeChange = (newMode) => {
    this.setState({
      mode: newMode,
    });
  };
  render() {
    console.log(this.props.updateConfigToDB)

    return (
      <Presenter
        mode={this.state.mode}
        handleModeChange={this.handleModeChange}
        missingReportTags={configDataToFormInterface(
            this.props.missingReportTags ? this.props.missingReportTags : []
        )}
        updateConfigToDB={this.props.updateConfigToDB}
        createConfigToDB={this.props.createConfigToDB}
        deleteConfigToDB={this.props.deleteConfigToDB}
      />
    );
  }
}

export default EditConfiguration;
