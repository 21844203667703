import React from 'react'
import { IconContext } from 'react-icons'
import { FaMoneyCheckAlt } from 'react-icons/fa'

const CostCenterIcon = () => {
  return (
    <IconContext.Provider value={{ color: "white", size: "5em" }}>
      <div>
        <FaMoneyCheckAlt />
      </div>
    </IconContext.Provider>
  )
}

export default CostCenterIcon
