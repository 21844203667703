const axios = require('axios')
const jsonapi = require('jsonapi-parse')


const getRequestHeaders = () => {
  let auth = JSON.parse(sessionStorage.getItem('authHeaders'));
  if (auth) {
    auth.Authorization = "Token token=dd60503fd5a2eac3d38e86237a402ee7" // restrict access token
  }
  return auth;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  headers:  getRequestHeaders(), // Note(dennis): header will be undefined in the /auth/home page. Used loginApi() to avoid it
  transformResponse: (data) => {
    return jsonapi.parse(data)
  }
}) 

// Note(Dennis): Used this function for not getting undefined value of header in /auth/home page when using the api lib
function loginApi() {
  const loginApi = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
    headers:  getRequestHeaders(),
    transformResponse: (data) => {
      return jsonapi.parse(data)
    }
  }) 

  return loginApi
}

export {api, loginApi}