import React from 'react'

const CloudBillListItem = ({modelListItem, index}) => {
  const {
    date,
    identifier,
    company_code,
    provider,
    currency,
    reference,
    account,
    amount,
    business_unit_name,
    cost_center_network_identifier,
    activity,
    line_item
  } = modelListItem

  return (
    <tr key={index}>
      <td>{date}</td>
      <td>{provider}</td>
      <td>{company_code}</td>
      <td>{currency}</td>
      <td>{reference}</td>
      <td>{account}</td>
      <td>{identifier}</td>
      <td>{amount}</td>
      <td>{business_unit_name}</td>
      <td>{cost_center_network_identifier}</td>
      <td>{activity}</td>
      <td>{line_item !== null ? line_item : ' '}</td>
    </tr>
  )
}

export default CloudBillListItem