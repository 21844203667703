import React, { useState } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { useHistory } from 'react-router'

const CreateAwsAccount = (props) => {
  const history = useHistory()
  const formItems = [
    {
      name: "Account Email",
      id: "accountEmail",
      type: "text",
      placeholder: "Enter the AWS account's email"
    },
    {
      name: "Account Name",
      id: "accountName",
      type: "text",
      placeholder: "Enter the AWS account's name (BU.Department)"
    },
    {
      name: "Organizational Unit",
      id: "ou",
      type: "select",
      options: [
        {
          displayName: "sandbox",
          value: "sandbox"
        },
        {
          displayName: "development",
          value: "development"
        },
        {
          displayName: "test",
          value: "test"
        },
        {
          displayName: "production",
          value: "production"
        }
      ]
    },
    {
      name: "Cost Center Tag",
      id: "costCenterTag",
      type: "text",
      placeholder: "Enter the account's default cost center"
    },
    {
      name: "Department Tag",
      id: "departmentTag",
      type: "text",
      placeholder: "Enter the department this account is for"
    },
    {
      name: "Project Name Tag",
      id: "projectNameTag",
      type: "text",
      placeholder: "Enter the account's default project name"
    },
    {
      name: "Business Unit Tag",
      id: "businessUnitTag",
      type: "text",
      placeholder: "Enter the account's BU (For Digital & Betting use: Digital)"
    },
    {
      name: "Owner Tag",
      id: "ownerTag",
      type: "text",
      placeholder: "Enter the account's default owner tag (USER EMAIL, NOT THE DL)"
    },
    {
      name: "Missing Tags Report",
      id: "missingTagsEmails",
      type: "text",
      placeholder: "Enter the emails for missing tags reports"
    },
    {
      name: "Billing Contact",
      id: "billingContact",
      type: "text",
      placeholder: "Enter the email for the accounts billing contact"
    },
    {
      name: "Security Contact",
      id: "securityContact",
      type: "text",
      placeholder: "Enter the email for the accounts security contact"
    },
    {
      name: "Operations Contact",
      id: "operationsContact",
      type: "text",
      placeholder: "Enter the email for the accounts Operations contact"
    },
    {
      name: "Required Regions (Comma Seperated: us-east-1,us-west-2,us-east-2)",
      id: "regions",
      type: "text",
      placeholder: "Enter The regions you require separated by a comma."
    },

  ]

  const initialFormData = {
    accountEmail: "",
    accountName: "",
    ou: "sandbox",
    costCenterTag: "",
    departmentTag: "",
    ownerTag: "",
    projectNameTag: "",
    businessUnitTag: "",
    missingTagsEmails: "",
    billingContact: "",
    operationsContact: "",
    securityContact: "",
    regions: []
  }

  const [formData, setFormData] = useState(initialFormData)

  const onChange = (e) => {
    let currentFormData = formData
    if (e.target.id === "regions") {
      currentFormData[e.target.id] = e.target.value.split(',')
    } else {
      currentFormData[e.target.id] = e.target.value
    }
    setFormData(currentFormData)
  }


  const onSubmit = () => {
    api.post("/account/create", formData)
      .then(resp => {
        alert("Your request has been submitted!")
        history.push('/auth/create-aws-account')
      })
      .catch(err => console.error("API Error: ", err))
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.id}
                        name={obj.name}
                        id={obj.id}
                        placeholder={obj.placeholder}
                        onChange={onChange}
                      />
                    </FormGroup>
                  )
                } else if (obj.type === "select") {
                  return (
                    <FormGroup key={i}>

                      <Label for="ou">Select an OU</Label>
                      <Input
                        name={obj.name}
                        id={obj.id}
                        type="select"
                        onChange={onChange}
                      >
                        {
                          obj.options.map((obj, i) => {
                            return (
                              <option value={obj.value} key={i}>{obj.displayName}</option>
                            )
                          })
                        }
                      </Input>
                    </FormGroup>
                  )
                } else {
                  return null
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default CreateAwsAccount