import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { api } from 'lib'
import { columnsData, addColumnsData } from "./columnsData";
import { getOperationColumn, getAddOperationColumn, getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'
import { CSVLink } from "react-csv";
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Spinner, Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)

  const [editingId, setEditingId] = useState("")
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")
  const [modal, setModal] = useState(false);
  const [uploadFile, setUploadFile] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState({});
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    setData(modelList)
  }, [isLoading, modelList])

  const parseBase64File = (file) => {
    return new Promise((resolve, reject) => {
      console.log(file);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      }
      reader.onerror = function (error) {
        console.error('Error: ', error);
        reject(error)
      }
    })
  }

  const uploadFileForParsing = (e) => {
    setIsUploading(true)
    console.log(e)
    let base64File = ''
    parseBase64File(uploadFile).then(result => {
      base64File = result
      const data = {
        cost_center_network: {
          file: base64File, 
        }
      }

      api.post("/cost_center_networks/upload", data)
      .then(resp => {
        console.log(resp); 
        setUploadResults(resp.data);
        setIsUploading(false);
        setUploadSuccessful(true);
      })
      .catch(err => { 
        console.error(err);
        alert("There was a problem uploading the file. Please try again. Error message: " + err.response.data['error'])
      })
    });
  }

  const uploadFileChange = (e) => {
    console.log(e.target.files[0]);
    setUploadFile(e.target.files[0]);
    setUploadFileName(e.target.value);
  }

  const isEditing = record => {
    return record.id === editingId;
  };

  const identifierIsDuplicate = identifier => {
    return data.find(network => network.identifier === identifier) !== undefined
  }

  const handleAdd = (form) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      if (identifierIsDuplicate(row["identifier"])) {
        alert("Duplicate identifier found. Please enter a unique identifier!")
        window.location.reload() 
        return 
      }
      
      const body = {
        identifier: row["identifier"],
        cost_center_id: row.cost_centers.id,
        new_network_id: row.new_network?.id,
        active_at: row.active_at?.toISOString(),
        expired_at: row.expired_at?.toISOString(),
      }

      addDatabaseRecord([...data], body, form)
    })
  }

  const handleDelete = (id) => {
    api.delete("/cost_center_networks/" + id)
      .then(resp => {
        alert("The network id has been deleted!");
        setData(data.filter((item) => item.id !== id))
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }

  const edit = (id) => {
    setEditingId(id)
  }

  const addDatabaseRecord  = (newData, body, form) => {
    api.post("/cost_center_networks", body)
      .then(resp => {
        console.log("Added a new Network ID record!")
        console.log(resp.status)
        newData.unshift({ ...resp.data.data })  // Add will update the first element!

        setData(newData)
        setEditingId("")
        alert("Successfully added a new Network ID!")
        form.resetFields()
      })
      .catch(err => {
        alert(`There was a problem adding this Network ID record. Please try again.`)
        console.error("API Error: ", err)
      })
  }
  
  const editDatabaseRecord = (newData, id, body) => {
    const index = newData.findIndex(item => id === item.id);
    // pick the proper record based on primary id and update the data

    api.put("/cost_center_networks/" + id, body)
      .then(resp => {
        console.log("Updated a network id record!")
        console.log(resp.status)
        console.log(resp.data.data)
        newData.splice(index, 1, {...resp.data.data});
        setData(newData)
        setEditingId("")
      })
      .catch(err => {
        alert("There was a problem updating this network id record. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const save = (form, id) => {
    console.log(form)
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...data];
      
      const body = {
        identifier: row["identifier"],
        cost_center_id: row.cost_centers.id,
        new_network_id: row.new_network?.id,
        active_at: row.active_at?.toISOString(),
        expired_at: row.expired_at?.toISOString(),
      }
      editDatabaseRecord(newData, id, body)
    });
  }
  
  const cancel = () => {
    setEditingId("")
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    }),
    getOperationColumn(editingId, isEditing, save, cancel, edit, handleDelete, "4%", false)
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "new_network['id']":
            return col.sorter(rowA['replacement_network'], rowB['replacement_network']);
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      },
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            case "active_at":
              return "date";
            case "expired_at":
              return "date";
            case "cost_centers['id']":
              return "select_new_network_cost_center";
            case "new_network['id']":
              return "select_new_network"
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });


  const defaultAddColumns = [
    ...addColumnsData,
    getAddOperationColumn(handleAdd, "4%")
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => {
        const checkInput = index => {
          switch (index) {
            case "created_at":
              return "date";
            case "active_at":
              return "date";
            case "expired_at":
              return "date";
            case "cost_centers['id']":
              return "select_new_network_cost_center";
            case "new_network['id']":
              return "select_new_network"
            default:
              return "text";
          }
        };
        return {
          record,
          inputType: checkInput(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: true 
        };
      }
    };
  });

  const CSVDataToDownloadFormat = (data) => {
    return data.map(network => {
      return {
        identifier: network.identifier,
        cost_center: network.cost_center_identifier,
        active_at: network.active_at,
        expired_at: network.expired_at,
        replacement_network: network.replacement_network,
        created_at: network.created_at
      }
    })
  }

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (
        <>
          <Button style={{ margin: '5px' }} >
            {modelList && (
              <CSVLink 
                data={CSVDataToDownloadFormat(data)} 
                filename={'network' + new Date().toISOString() + '.csv'}
              >
                Download CSV
              </CSVLink>
            )}
          </Button>
          <Button style={{ margin: '5px' }} onClick={toggle}>
            Upload New Network/Project IDs
          </Button>
          <Table
            title={() => 'Add a Row'} 
              pagination={false} 
              rowKey="id"
              size='middle'
              components={{
                body: {
                  row: EditableFormRow,
                  cell: EditableCell
                }
              }}
              bordered
              dataSource={[{
                identifier: "",
                cost_center_id: "",
                active_at: "",
                expired_at: "",
                new_network_id: "",
              }]
            }
              columns={defaultAddColumns}
              rowClassName="editable-row"
          />
          <Table
            title={() => 'Network ID Table'} 
              pagination={{ position: 'both' }}  // pagination={false} 
              rowKey="id"
              size='middle'
              components={{
                body: {
                  row: EditableFormRow,
                  cell: EditableCell
                }
              }}
              bordered
              dataSource={data}
              columns={defaultColumns}
              rowClassName="editable-row"
          />
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Modal title</ModalHeader>
            <ModalBody>
              Upload a CSV with new Network/Project IDs:
              <Form>
                <Input type="file" value={uploadFileName} onChange={uploadFileChange} />
              </Form>
              {uploadSuccessful && uploadResults && (
                <Card style={{width: '100%', margin: '10px', backgroundColor: '#D1FFBD'}}>
                  <CardBody>
                    <CardTitle>Upload Results:</CardTitle>
                    <CardSubtitle>Cost Centers Added</CardSubtitle>
                    {uploadResults.cost_centers_added.length === 0 && (
                      <CardText>No Cost Centers Added...</CardText>
                    )}
                    {uploadResults.cost_centers_added.length > 0 && (
                      <CardText>{uploadResults.cost_centers_added.map(cost_center => {
                        return cost_center
                      }).join(", ")}</CardText>
                    )}
                    <CardSubtitle>Networks Added</CardSubtitle>
                    {uploadResults.networks_added.length === 0 && (
                      <CardText>No Networks Added...</CardText>
                    )}
                    {uploadResults.networks_added.length > 0 && (
                      <CardText>{uploadResults.networks_added.map(network => {
                        return network
                      }).join(", ")}</CardText>
                    )}
                    <CardSubtitle>Project IDs Added</CardSubtitle>
                    {uploadResults.projects_added.length === 0 && (
                      <CardText>No Project IDs Added...</CardText>
                    )}
                    {uploadResults.projects_added.length > 0 && (
                      <CardText>{uploadResults.projects_added.map(project => {
                        return project
                      }).join(", ")}</CardText>
                    )}
                    <CardSubtitle>Networks Updated</CardSubtitle>
                    {uploadResults.networks_updated.length === 0 && (
                      <CardText>No Networks Updated...</CardText>
                    )}
                    {uploadResults.networks_added.length > 0 && (
                      <CardText>{uploadResults.networks_updated.map(network => {
                        return network
                      }).join(", ")}</CardText>
                    )}
                    <CardSubtitle>Project IDs Updated</CardSubtitle>
                    {uploadResults.projects_updated.length === 0 && (
                      <CardText>No Project IDs Updated...</CardText>
                    )}
                    {uploadResults.projects_updated.length > 0 && (
                      <CardText>{uploadResults.projects_updated.map(project => {
                        return project
                      }).join(", ")}</CardText>
                    )}
                  </CardBody>
                </Card>
              )}
            </ModalBody>
            <ModalFooter>
              {isUploading && <Spinner color="primary" />}
              {!isUploading && <Button color="primary" onClick={uploadFileForParsing}>
                Submit
              </Button>}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
}