import React from 'react'
import ReactJson from 'react-json-view';

export const PermissionSetItem = ({ id, data, index, projectId }) => {

  if (id == null || data == null)
    return null
  if (projectId) {
    return (
      <>
      <details>
      <summary>Cloud Access</summary>
      {projectId}
    </details>
        {GCPpermissionSetsList(id, data)}
        </>
    )
  } else { // Other than GCP Permission sets!
    const modelListItem = (data.find(element => Number(element.id) === id) || {})
    if (modelListItem == null || Object.keys(modelListItem).length === 0)
      return (null)
    const { inline_policy } = modelListItem
    const inline = inline_policy.substring(1, inline_policy.length - 1).replace(/\\/g, '')
    return (
      <details>
      <summary>Inline Policy</summary>
    {renderInlinePolicy(inline)} 
        
      </details>
    )
  }

}

function GCPpermissionSetsList(permissionSetId, permissionSetResults) {
  const permissionSets = [...permissionSetResults];
  const permissionSet = permissionSets.find(e => parseInt(e.id, 10) === permissionSetId) || {};

  if (permissionSet === null || Object.keys(permissionSet).length === 0) {
    return null;
  } else {
    return (
      <details>
        {GCPInlinePolicy(permissionSet.inline_policy)}
      </details>
    );
  }
}

function GCPInlinePolicy(inlinePolicy){
  if (!inlinePolicy) {
    return null;
  } else {
    return (
      <details>
        <summary>Inline Policy</summary>
        {inlinePolicy}
         <ReactJson src={JSON.parse(inlinePolicy)} theme="summerfruit:inverted" />

      </details>
    );
  }
}

function renderInlinePolicy(inline) {
  if (!inline)
    return null
    return <p>{inline}</p> 
}
