import React, { useState, useEffect } from 'react'
import { api } from 'lib'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button
} from 'reactstrap'
import { IgtLoader } from 'Atoms'

const BusinessUnitsEditForm = ({ isLoading, params }) => {
  const [name, setName] = useState(null);
  const [abbreviation, setAbbreviation] = useState(null);
  const initialFormData = {
    name: "",
    abbreviation: ""
  }
  const [formData, setFormData] = useState(initialFormData)
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const id = params['modelId']

  useEffect(() => {
    let obj = {}
    api.get('/business_units')
      .then(resp => {
        const data = resp.data.data;
        const att = data.find(obj => obj.id === id)
        obj['name'] = att['name']
        setName(att['name'])
        obj['abbreviation'] = att['abbreviation']
        setAbbreviation(att['abbreviation'])
      })
      .catch(err => console.error("API Error: ", err))

    setFormData(obj)
    // eslint-disable-next-line
  }, [])


  const onChange = (e) => {
    if (e.target.id === 'name')
      setName(e.target.value)
    if (e.target.id === 'abbreviation')
      setAbbreviation(e.target.value)
    let currentFormData = formData
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onSubmit = () => {
    console.log(formData)

    api.put("/business_units/" + id, formData)
      .then(resp => {
        alert("Your business unit has been updated!");
        console.log("Submitted")
        window.location.replace("/auth/view/business_units");
      })
      .catch(err => {
        alert("There was a problem submitting this business unit. Please try again.")
        console.error("API Error: ", err)
      })
  }

  const formItems = [
    {
      name: "Business unit Name",
      id: "name",
      value: name,
      type: "text",
    },
    {
      name: "Abbreviation",
      id: "abbreviation",
      value: abbreviation,
      type: "text",
    }
  ]

  
  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Edit business unit</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/business_units">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Form>
                {
                  formItems && formItems.map((obj, i) => {
                      return (
                        <FormGroup key={i}>
                          <Label for={obj.id}>{obj.name}</Label>
                          <Input
                            type={obj.id}
                            name={obj.name}
                            id={obj.id}
                            placeholder={obj.placeholder}
                            value={obj.value}
                            onChange={onChange}
                          />
                        </FormGroup>
                      )
                  })
                }
                <br />

                <Button color="primary" onClick={toggle}>Confirm</Button>
                {renderModal(modal, toggle, formData, onSubmit)}
              </Form>
          }
        </Col>
      </Row>
    </Container >
  )
}

function renderModal(modal, toggle, formData, onSubmit) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Confirm Business unit</ModalHeader>
    <ModalBody>
      Business units name: {formData["name"]} <br />
      business unit abbreviation: {formData["abbreviation"]} <br />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Submit</Button>{' '}
      <Button color="danger" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}

export default BusinessUnitsEditForm