import React from "react" 
import {Sorter} from "lib" 
// Note(Dennis): Set the editable to true as 
// Because sort feature doesn't work if editable set to false 

export const columnsData = [ 
  { 
    title: "Cost Center", 
    dataIndex: "cost_center", 
    sorter: Sorter.TEXT, 
    width: "7%", 
    editable: true, 
    isSearchable: true 
  }, 
  {
    title: "Github Organization",
    dataIndex: "github_organization_app['id']",
    width: "7%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["github_organization"]}</>
    },
    isSearchable: true
  },
  {
    title: "Email",
    dataIndex: "cloud_contact['id']",
    width: "8%",
    editable: true,
    sorter: Sorter.TEXT,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
    isSearchable: true
  },
  { 
    title: "Github Username", 
    dataIndex: "github_username", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  }, 
  { 
    title: "Easy Vista Ticket Number", 
    dataIndex: "easy_vista_ticket_number", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  }, 
  { 
    title: "Requestor", 
    dataIndex: "requestor", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  } 
]; 

export const addColumnsData = [ 
  { 
    title: "Cost Center", 
    dataIndex: "cost_center", 
    width: "7%", 
    editable: true, 
    isSearchable: true 
  }, 
  {
    title: "Github Organization",
    dataIndex: "github_organization_app['id']",
    width: "7%",
    editable: true,
    render: (text, record) => {
      return <>{record["github_organization"]}</>
    },
    isSearchable: true
  },
  {
    title: "Email",
    dataIndex: "cloud_contact['id']",
    width: "8%",
    editable: true,
    render: (text, record) => {
      return <>{record["cloud_contact_email"]}</>
    },
    isSearchable: true
  },
  { 
    title: "Github Username", 
    dataIndex: "github_username", 
    width: "7%", 
    editable: true, 
    isSearchable: true 
  }, 
  { 
    title: "Easy Vista Ticket Number", 
    dataIndex: "easy_vista_ticket_number", 
    width: "7%", 
    editable: true, 
    isSearchable: true 
  }, 
  { 
    title: "Requestor", 
    dataIndex: "requestor", 
    width: "7%", 
    editable: true, 
    isSearchable: true 
  } 
]; 