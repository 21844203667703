import React from 'react'
import moment from 'moment'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { api } from 'lib'

const DeleteModal = ({modelListItem, modalOpen, toggleModal, modelId}) => {
  const {
    code,
    country,
    aws_gl,
    gcp_gl,
    azure_gl,
    created_at, 
    gl_type
  } = modelListItem


  const onSubmit = () => {
    toggleModal();
    api.delete("/companies/" + modelId)
      .then(resp => {
        alert("The company has been deleted!");
        window.location.replace("/auth/view/companies");
      })
      .catch(err => {
        alert(err.response.data['error'])
        console.error("API Error: ", err)
      })
  }
  return (
    <Modal isOpen={modalOpen}>
      <ModalHeader>Do you want to delete this company?</ModalHeader>
      <ModalBody>
        <p>You are going to permanently delete this company</p>
        <p>Do you want to proceed?</p>
        <ul>
          <li>Company code: {code}</li>
          <li>Location: {country}</li>
          <li>AWS GL: {aws_gl}</li>
          <li>GCP GL: {gcp_gl}</li>
          <li>Azure GL: {azure_gl}</li>
          <li>GL Type: {gl_type}</li>
          <li>Created on: {created_at !== null ? moment(created_at).format('DD-MMM-yyyy') : ' '}</li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onSubmit}>Delete it</Button>{' '}
        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteModal