import React, { useEffect } from 'react'
import { Wysiwyg } from 'Atoms'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { api } from 'lib'
import { useRouteMatch } from 'react-router-dom'

const EditForm = ({ onFormChange, onEditorFormChange, onSubmit, formData, setInitialFormData }) => {
  const routeMatch = useRouteMatch()
  const formItems = [
    {
      name: "Question",
      id: "question",
      type: "text",
      placeholder: "Please enter your question here..."
    },
    {
      name: "Answer",
      id: "answer",
      type: "wysiwyg",
      placeholder: "Please enter your answer here..."
    },
    {
      name: "Category",
      id: "category",
      type: "text",
      placeholder: "Please enter your category here..."
    }
  ]

  useEffect(() => {
    api.get(`/faqs/${routeMatch.params.modelId}`)
      .then(resp => {
        // eslint-disable-next-line
        formData = {}
        formData.question = resp.data.data.question
        formData.answer = resp.data.data.answer
        formData.category = resp.data.data.category
        console.log("Form Data Update: ", formData)
        setInitialFormData(formData)
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Edit FAQ</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/view/faqs">Go Back</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 10, offset: 1 }}>
          <Form>
            {
              formItems && formItems.map((obj, i) => {
                if (obj.type === "text") {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Input
                        type={obj.type}
                        name={obj.name}
                        id={obj.id}
                        value={formData[obj.id]}
                        placeholder={obj.placeholder}
                        onChange={onFormChange}
                      />
                    </FormGroup>
                  )
                } else {
                  return (
                    <FormGroup key={i}>
                      <Label for={obj.id}>{obj.name}</Label>
                      <Wysiwyg
                        id={obj.id}
                        value={formData[obj.id]}
                        onChange={onEditorFormChange}
                        init={{
                          height: 500,
                          paste_data_images: true
                        }}
                      />
                    </FormGroup>
                  )
                }
              })
            }
            <Button onClick={onSubmit}>Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default EditForm